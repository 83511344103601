import '@aws-amplify/ui-react/styles.css';
import { ArrowRight, Help, Search, ThumbDown, ThumbUp } from '@mui/icons-material';
import { Button, Grid, Input, MenuItem, MenuList, Paper, Popover, Table, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import MUIDataTable from "mui-datatables";
import React, { Component } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DataTableStatusMapping from './DataTableStatusMapping';
import { approveAccessRequest, denyAccessRequest, getOktaUser, listAccessRequests, listTeamManagedRoles, listUserAccessRequests, submitAccessRequest } from "./Queries";

import AddIcon from '@mui/icons-material/Add';
import { RiseLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { oktaAuth } from '../Components/Auth';
import { PageLoader } from './Loader';
var self = {}


const teamsUpdatedEvent = new Event('cache-teams-updated')


function groupRoles(roleArray, roleProp = "RoleId") {
    var out = {}
    roleArray.forEach(role => {
        var cat = role[roleProp].split("-")[0]

        if (out[cat] == undefined) {
            out[cat] = []
        }

        out[cat].push(role)

    })

    var items = Object.keys(dict).map(function (key) {
        return [key, dict[key]];
    });

    // Sort the array based on the second element
    items.sort(function (first, second) {
        return second[1] - first[1];
    });

    return items
}
class MyRequests extends Component {
    constructor(props) {
        super(props);

        this._setState = this.setState
        this.setState = this.setStateWhenMounted.bind(this)


        this.oktaAuth = oktaAuth
        this.state = {
            ready: false,
            queueItems: [],
            accessRequests: [],
            showAddRoleButton: false,
            filterStatus: 'pending',
            self: {},
        }

        this.datatable = {

            columns: [
                { name: "RequestId", options: { filter: true, sort: true } },
                {
                    name: "Status",
                    options: {
                        filter: true,//
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <DataTableStatusMapping value={value} queueItems={this.state.queueItems} tableMeta={tableMeta} colId={0} />
                            )
                        },
                    },

                },
                { name: "Team", options: { filter: true, sort: true } },
                { name: "Requested role/app", options: { filter: true, sort: true } },
                {
                    name: "Reason",
                    options: {
                        filter: true,
                        sort: true,
                    }
                },
                {
                    name: "Date",
                    options: {
                        filter: true,
                        sort: true,
                    }
                }
            ],

            options: {
                filter: true,
                download: false,
                print: false,
                filterType: 'dropdown',
                responsive: 'standard',
                selectableRows: 'none',
                expandableRowsOnClick: true,
                expandableRows: true,

                renderExpandableRow: (rowData, rowMeta) => {
                    const colSpan = rowData.length + 1;
                    var accessRequestId = rowData[0]
                    var queueItems = this.state.accessRequests.find(o => {
                        return o.id == accessRequestId
                    }).ApprovalWorkflowQueue.items

                    if (queueItems == undefined) {
                        queueItems = []
                    }

                    return (

                        <TableRow sx={{ bgcolor: "#424242" }}>
                            <TableCell colSpan={colSpan}>

                                <Table sx={{ maxWidth: "50%", marginLeft: 10, marginBottom: 4 }}>
                                    <TableHead>
                                        <TableCell>
                                            Status
                                        </TableCell>
                                        <TableCell>
                                            Approver
                                        </TableCell>
                                    </TableHead>
                                    {
                                        queueItems.map(row => {
                                            return (
                                                <TableRow>
                                                    <TableCell>
                                                        <DataTableStatusMapping value={row.Status} />
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.TargetApproverIds}
                                                    </TableCell>

                                                </TableRow>
                                            )
                                        })
                                    }
                                </Table>
                            </TableCell>

                        </TableRow>



                    )
                },
                onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded, rowData) =>
                    console.debug(curExpanded, allExpanded, rowsExpanded),
            }

        }

        var promises = []

        getOktaUser().then(user => {
            this.setState({ user: user })
            return user
        }).then(user => {

            listUserAccessRequests(user.preferred_username, "*").then(data => {
                if (data) {
                    data = data.sort((a, b) => { return a.createdAt < b.createdAt })
                }
                this.setState({ accessRequests: data ? data : [] })
            }).then(o => {
                var tableData = this.state.accessRequests.map(row => {
                    var queueItem = row.ApprovalWorkflowQueue.items[0]
                    if (queueItem) {
                        var app = queueItem.TargetRoleId
                        var row = [row.id, row.Status, row.TeamId, app, row.Reason, row.createdAt]
                        return row
                    }
                   
                })
                
                this.setState({ tableData: tableData.filter(o => {return o !=undefined}), ready: true })
            })

        })



    }
    setStateWhenMounted(obj) {
        if (this._isMounted) {
            return this._setState(obj)
        }

    }
    componentDidMount() {
        this._isMounted = true

    }

    componentWillUnmount() {
        this._isMounted = false
        // Make sure to remove the DOM listener when the component is unmounted.
        window.removeEventListener("cache-teams-updated", this.handleTeamsUpdated);
    }

    render() {
        
        if (!this.state.ready) {
            return (
                <PageLoader ready={this.state.ready} />
            )
        }
        
        return (
            <Paper>

                <MUIDataTable
                    data={this.state.tableData}
                    columns={this.datatable.columns}
                    options={this.datatable.options}
                    xs={12} sm={12} md={12} lg={12} xl={12}
                />

            </Paper>
        )
    }

}
class RequestAccess extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedTeam: null,
            selectedRole: null,
            submitButtonColor: 'success',
            teams: [],
            tokens: {},
            ready: false,
            anchorEl: null,
            queueItems: [],
            user: {},
            showAddRoleButton: false,
            selectedTeamRoles: [],
            searchString: ""
        }


        this.clearAnchorEl = this.clearAnchorEl.bind(this)
        this.createAccessRequest = this.createAccessRequest.bind(this)
        this.selectTeam = this.selectTeam.bind(this)
        this.oktaAuth = this.props.oktaAuth
        this._setState = this.setState
        this.setState = this.setStateWhenMounted.bind(this)

        this.updateUser = this.updateUser.bind(this)




        this.updateUser()

    }

    async updateUser() {
        var promises = []

        promises.push(
            getOktaUser().then(user => {
                var teams = user.groups.filter(o => { return o.match(RegExp("^Team-[^-]+$")) }).sort((a, b) => { return a > b })

                console.debug("User: ", user)
                console.debug("Setting teams: ", teams)
                this.setState({ user: user, teams: teams })
                return user
            }).then(
                user => listUserAccessRequests(user.preferred_username, 'Pending')
            ).then(accessRequests => {
                this.setState({
                    pendingAccessRequests: accessRequests, showAddRoleButton: true
                })
            })

        )


        return Promise.all(promises).then(o => {
            this.setState({ ready: true })
        })
    }
    setStateWhenMounted(obj) {
        if (this._isMounted) {
            return this._setState(obj)
        }

    }
    componentDidMount() {
        this._isMounted = true

    }

    componentWillUnmount() {
        this._isMounted = false
        // Make sure to remove the DOM listener when the component is unmounted.
        window.removeEventListener("cache-teams-updated", this.handleTeamsUpdated);
    }


    handleTeamsUpdated = event => {
        if (!this._isMounted) return
        this.setState({ teams: JSON.parse(localStorage['4sp-cache-teams']) })
        this.setState({ stateresponder: event })
    }


    clearAnchorEl(event) {
        if (!this._isMounted) return
        this.setState({ anchorEl: null, submitButtonColor: 'success' })
    }

    async createAccessRequest() {
        if (!this._isMounted) return
        var err = ""
        var promise = submitAccessRequest(this.state.selectedTeam, this.state.selectedRole, undefined, this.state.reason).catch(data => {
            console.error(data)
            try {
                err = data.errors[0].message
            }
            catch {
                err = data

            }

            toast.error("Failed request due to: " + err)
            console.error(data)
            this.setState({ submitButtonColor: 'error' })
            throw (data.errors)

        }).then(data => {

            console.debug("Submited: ", data)

            this.setState({ selectedRole: null, selectedTeam: null })
            this.clearAnchorEl()
            this.updateUser()

        })
        toast.promise(
            promise,
            {
                pending: "Creating access request",
                success: "Access request submitted!",
                error: "Failed to create access request."
            }
        )
        return promise
    }

    async selectTeam(teamId) {
        this.setState({ loadingTeam: true, selectedTeamRoles: [], selectedTeam: teamId })
        listTeamManagedRoles(teamId).then(roles => {
            this.setState({ selectedTeamRoles: roles.filter(o => { return this.state.user.groups.includes(o) == false }), loadingTeam: false })
        })
    }

    render() {
        if (!this.state.ready) {
            return (
                <PageLoader ready={this.state.ready} />
            )
        }
        return (
            <Paper sx={{ pb: 4 }}>
                <Popover
                    id={'appRequestPopOver'}
                    open={this.state.anchorEl != null}
                    anchorEl={this.state.anchorEl}
                    onClose={this.clearAnchorEl}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}

                >

                    <TextField
                        autoFocus
                        onChange={(event) => { this.setState({ reason: event.target.value }) }}
                        onKeyDown={(event) => {
                            if (event.keyCode == 13 && event.shiftKey == false) {
                                event.preventDefault();
                                this.createAccessRequest()
                            }
                        }}
                        sx={{ minWidth: "300px" }}
                        label="Enter reason"
                        variant="filled"
                        value={this.state.reason}
                    />
                    <Button
                        disabled={
                            this.state.reason == "" || this.state.reason == undefined
                        }
                        onClick={
                            (event) => {
                                this.createAccessRequest()
                            }
                        }
                        sx={{ minHeight: '4em' }}
                        variant='contained'
                        color={this.state.submitButtonColor}
                    >
                        {
                            this.state.submitButtonColor == 'success' ?
                                'Submit request' :
                                'Error when submitting request'
                        }
                        <br />
                        {this.state.activeRole}
                        <br />
                        {this.state.activeTeam}
                    </Button>
                </Popover>
                <Grid container>
                    <Grid item>
                        <Typography sx={{ mx: 2, pt: 2, pl: 3 }} variant="h4" >
                            Request roles & Applications
                        </Typography>

                    </Grid>
                    <Grid sx={{ pt: 3 }} item>
                        <Tooltip title="Request roles and applications - Request from the team that should approve your request.">
                            <Help />
                        </Tooltip>
                    </Grid>
                </Grid>

                <Grid mx={2} mt={2} px={2} bgcolor={"#3d3d3d"} container>
                    <Grid xs={4} item>
                        <Typography variant='h5'>Team</Typography>
                    </Grid>
                    <Grid item>

                        <Typography variant='h5'>Roles & Applications</Typography>

                    </Grid>
                    <Grid ml={2} xs={5}>
                        <Input
                            placeholder="Search..."
                            value={this.state.searchString}
                            onChange={(event) => {

                                this.setState({ searchString: event.target.value })
                            }}
                        /><Search />


                    </Grid>

                </Grid>
                <Grid mb={3} mx={2} px={2} container>
                    <Grid xs={4} borderRight="1px" item>

                        {
                            this.state.teams.length > 0 ?
                                <MenuList key="teamList">
                                    {
                                        this.state.teams.map(team => {
                                            return (

                                                <MenuItem
                                                    selected={this.state.selectedTeam == team}
                                                    onClick={(event) => {
                                                        this.selectTeam(team)
                                                    }}
                                                    key={"teamList" + team + "Item"}
                                                >
                                                    {
                                                        this.state.selectedTeam == team ? <ArrowRight /> : null
                                                    }
                                                    {team}

                                                </MenuItem>
                                            )
                                        })
                                    }

                                </MenuList> :
                                <Typography variant="h6">You're not a member of any teams... Please ask a team admin to add you.</Typography>
                        }

                    </Grid>
                    <Grid xs={6} item>

                        {
                            this.state.selectedTeam ?

                                <Table key={"table-" + this.state.selectedTeam}>
                                    {

                                        this.state.selectedTeamRoles.sort((a, b) => (a.RoleId > b.RoleId) ? 1 : -1).filter(o => {
                                            if (this.state.searchString) {
                                                return (o.RoleId + " " + o.Description).toLowerCase().match(RegExp(this.state.searchString.toLowerCase()))
                                            } else { return true }
                                        }).map(o => {


                                            var name = `${o.RoleId}`

                                            var pendingRequests = false


                                            pendingRequests = this.state.pendingAccessRequests.find(a => { return a.ApprovalWorkflow.Target == o.RoleId })

                                            return (

                                                <TableRow>
                                                    <TableCell style={{ width: '30px' }}>
                                                        <Tooltip placement="left" title={`Request ${o.RoleType.toLowerCase()}`}>
                                                            {
                                                                this.state.showAddRoleButton ?
                                                                    <Button
                                                                        disabled={pendingRequests}
                                                                        onClick={(event) => {
                                                                            console.debug('clicked')
                                                                            this.setState({
                                                                                anchorEl: event.currentTarget,
                                                                                selectedRole: o.RoleId,
                                                                                selectedTeam: this.state.selectedTeam
                                                                            })
                                                                        }}
                                                                        color='success'
                                                                        variant='contained'

                                                                    >
                                                                        {
                                                                            pendingRequests ? 'Pending approval' : <AddIcon />
                                                                        }
                                                                    </Button> :
                                                                    <Button variant='contained' color='info' disabled>Loading...</Button>
                                                            }
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell >
                                                        <Typography variant='body1'>{name}</Typography>
                                                        <Typography variant='caption'>{o.Description}</Typography>
                                                    </TableCell>

                                                </TableRow>
                                            )
                                        })
                                    }

                                    {
                                        this.state.selectedTeamRoles.length == 0 ?
                                            <TableRow>
                                                <TableCell style={{ width: '30px' }}>

                                                </TableCell>
                                                <TableCell >
                                                    {
                                                        this.state.loadingTeam ?
                                                            <Grid
                                                                container
                                                                spacing={0}
                                                                direction="column"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                mt={2}
                                                            >

                                                                <Grid item xs={3}>
                                                                    <RiseLoader sx={{ my: 3, mx: 3 }} loading={true}>Loading team...</RiseLoader>
                                                                </Grid>

                                                            </Grid>

                                                            : <Typography variant='body1'>No applications to request</Typography>
                                                    }

                                                </TableCell>
                                            </TableRow> : null
                                    }
                                    {
                                        this.state.selectedTeamRoles.filter(o => {
                                            if (this.state.searchString) {
                                                return (o.RoleId + " " + o.Description).toLowerCase().match(RegExp(this.state.searchString.toLowerCase()))
                                            } else { return true }
                                        }).length == 0 && this.state.selectedTeamRoles.length != 0 ?
                                            <TableRow>
                                                <TableCell style={{ width: '30px' }}>

                                                </TableCell>
                                                <TableCell >
                                                    <Typography variant='body1'>No roles found matching search '{this.state.searchString.toLowerCase()}'</Typography>
                                                </TableCell>
                                            </TableRow> : null
                                    }
                                </Table> : null

                        }

                    </Grid>
                </Grid>


            </Paper >
        )
    }

}
class ApproveRequests extends Component {

    constructor(props) {
        super(props);
        this.updateAccessRequests = this.updateAccessRequests.bind(this)

        this.setAnchorEl = this.setAnchorEl.bind(this)
        this.setAnchorElAll = this.setAnchorElAll.bind(this)
        this.clearAnchorEl = this.clearAnchorEl.bind(this)
        this.clearAnchorElAll = this.clearAnchorElAll.bind(this)
        this.getTokens = this.getTokens.bind(this)


        this.state = {
            accessRequests: [],
            approvalQueueItems: [],
            tableData: [],
            searchFilter: "Pending",
            reason: "",
            selectedRows: [],
            ready: false,
        }

        this.updateAccessRequests()
        this.getTokens()
        this.datatable = {

            columns: [

                {
                    name: "Status",
                    options: {
                        filter: true,//
                        customBodyRender: (value, tableMeta, updateValue) => {
                            console.debug("TableMeta: ", tableMeta)
                            return (
                                <DataTableStatusMapping value={value} accessRequests={this.state.accessRequests} colId="5" tableMeta={tableMeta} />
                            )
                        },
                    },

                },
                { name: "Username", options: { filter: true, sort: true } },
                {
                    name: "Requested role/app", options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {

                            if (value.TargetDisplayName && value.TargetDisplayName != "") {
                                return (
                                    <Grid spacing={1} container>
                                        <Grid xs={12} item>
                                            {value.Target}
                                        </Grid>
                                        <Grid item>
                                            <Typography variant='caption'>
                                                {value.TargetDisplayName}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )
                            }
                            return (
                                <Grid spacing={1} container>
                                    <Grid xs={12} item>
                                        {value.Target}
                                    </Grid>
                                </Grid>
                            )

                        }
                    }
                },
                { name: "Request date", options: { filter: true, sort: true } },
                { name: "Reason", options: { filter: true, sort: true } },
                {
                    name: "Request id",
                    options: {
                        filter: true,
                        sort: true,
                    }
                }
            ],

            options: {
                filter: true,
                download: false,
                print: false,
                filterType: 'dropdown',
                responsive: 'standard',

                expandableRows: true,
                expandableRowsHeader: false,
                onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
                    this.setState({ selectedRows: rowsSelected })
                },
                expandableRowsOnClick: true,
                selectableRows: 'none',

                renderExpandableRow: (rowData, rowMeta) => {
                    const colSpan = rowData.length + 1;
                    var accessRequestId = rowData[5]
                    try {
                        var queueItems = this.state.accessRequests.find(o => { return o.id == accessRequestId }).ApprovalWorkflowQueue.items
                    }
                    catch {
                        queueItems = undefined
                    }


                    console.debug("Steps: ", queueItems)
                    if (queueItems == undefined) {
                        queueItems = []
                    }

                    const open = Boolean(this.state.anchorEl);
                    const id = open ? accessRequestId : undefined;

                    var modal
                    this.state.popoverMode === 'approve' ?
                        modal = [
                            (
                                <Button
                                    disabled={this.state.disableSend}
                                    variant='contained'
                                    sx={{ minHeight: '60px', bgcolor: "green" }}
                                    onClick={(event) => {
                                        this.approveRequests([this.state.activeQueueItem])
                                    }}
                                >
                                    Approve
                                </Button>)
                        ] :
                        modal = [
                            (
                                <Button
                                    disabled={this.state.disableSend}
                                    variant='contained'
                                    sx={{ minHeight: '60px', bgcolor: "red" }}
                                    onClick={
                                        (event) => this.denyRequests([this.state.activeQueueItem])
                                    }
                                >
                                    Deny
                                </Button>)
                        ]

                    console.debug(queueItems)
                    return (

                        <TableRow sx={{ bgcolor: "#424242" }}>
                            <TableCell colSpan={colSpan}>
                                <Popover
                                    id={accessRequestId}
                                    open={open}
                                    anchorEl={this.state.anchorEl}
                                    onClose={this.clearAnchorEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}

                                >
                                    <TextField
                                        autoFocus
                                        onChange={(event) => { this.setState({ reason: event.target.value }) }}
                                        onKeyDown={(event) => {
                                            if (event.keyCode == 13 && event.shiftKey == false) {
                                                event.preventDefault();
                                                this.state.popoverMode === 'approve' ?
                                                    this.approveRequests([this.state.activeQueueItem])
                                                    : this.denyRequests([this.state.activeQueueItem])
                                            }
                                        }}
                                        sx={{ minWidth: "300px" }}
                                        label="Enter reason"
                                        variant="filled"
                                        value={this.state.reason}
                                    />

                                    {modal[0]}



                                </Popover>
                                <Table key={accessRequestId + "-Table"} sx={{ marginLeft: 10, marginBottom: 4 }}>
                                    <TableHead>
                                        <TableCell>
                                            Status
                                        </TableCell>
                                        <TableCell>
                                            Approve as
                                        </TableCell>
                                        <TableCell>
                                            Action
                                        </TableCell>
                                    </TableHead>
                                    {
                                        queueItems.map(row => {
                                            var isAllowed = this.state.tokens.idToken.claims.groups.find(o => { return row.TargetApproverIds.includes(o) }) != undefined
                                            var previouslyApproved = row.Status != "Pending" ? row.ApprovedBy : undefined
                                            var canApprove = isAllowed && !previouslyApproved


                                            return (
                                                <TableRow>
                                                    <TableCell>
                                                        <DataTableStatusMapping value={row.Status} />
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.TargetApproverIds}
                                                    </TableCell>
                                                    <TableCell>
                                                        {
                                                            canApprove ? null :
                                                                !isAllowed && row.Status == 'Pending' ?
                                                                    <Typography variant='caption' >Not allowed top perform request</Typography>
                                                                    : null
                                                        }
                                                        {
                                                            canApprove ? null :
                                                                previouslyApproved && row.Status == 'Pending' ?
                                                                    <Typography variant='caption'>You have already approved a step in this request</Typography>
                                                                    : null
                                                        }
                                                        {
                                                            row.ApprovedBy ?
                                                                <Typography>Approved by {row.ApprovedBy}</Typography> :
                                                                <Grid container alignContent={'right'}>
                                                                    <Tooltip title="Approve request">
                                                                        <Grid item paddingRight={1}>
                                                                            <Button disabled={!canApprove} aria-describedby={row.id} onClick={(event) => {
                                                                                this.setAnchorEl(event, 'approve')
                                                                                this.setState({ activeQueueItem: row.id })
                                                                            }}
                                                                                variant="contained"
                                                                                sx={{ bgcolor: 'green' }}
                                                                            >
                                                                                <ThumbUp sx={{ height: "1.5em", width: "1.5em", color: '#a5d6a7', mr: 1 }} />
                                                                                Approve
                                                                            </Button>
                                                                        </Grid>
                                                                    </Tooltip>
                                                                    <Tooltip title="Deny request">
                                                                        <Grid item paddingRight={1}>
                                                                            <Button disabled={!canApprove} aria-describedby={row.id} onClick={(event) => {
                                                                                this.setAnchorEl(event, 'deny')
                                                                                this.setState({ activeQueueItem: row.id })
                                                                            }} variant="contained" sx={{ bgcolor: 'red' }} ><ThumbDown sx={{ height: "1.5em", width: "1.5em", color: '#ffab91', mr: 1 }} />Deny</Button>
                                                                        </Grid>
                                                                    </Tooltip>

                                                                </Grid>
                                                        }

                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </Table>
                            </TableCell>

                        </TableRow>



                    )
                },
                onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded, rowData) =>
                    console.debug(curExpanded, allExpanded, rowsExpanded),
            }

        }
    }

    setAnchorEl(event, mode) {
        this.setState({ anchorEl: event.currentTarget })

        this.setState({ popoverMode: mode })
    }
    clearAnchorEl(event) {
        this.setState({ anchorEl: null })
    }
    setAnchorElAll(event, mode) {
        this.setState({ anchorElAll: event.currentTarget })

        this.setState({ popoverMode: mode })
    }
    clearAnchorElAll(event) {
        this.setState({ anchorElAll: null })
    }
    updateAccessRequests() {
        return listAccessRequests("Pending").then(accessRequests => {
            if (accessRequests == undefined) {
                accessRequests = []
            }
            
            this.setState({ accessRequests: accessRequests, tableData: [] })
            accessRequests.forEach(accessRequest => {
            
                this.setState(previousState => ({
                    tableData: [
                        ...previousState.tableData,
                        [
                            accessRequest.Status,
                            accessRequest.Username,
                            accessRequest.ApprovalWorkflow,
                            accessRequest.createdAt,
                            accessRequest.reason,
                            accessRequest.id
                        ]
                    ]

                }));
            })

        }).then(o => {
            this.setState({ ready: true })
        }).catch((error) => {
            console.error(error)
        })

    }

    getTokens() {
        oktaAuth.tokenManager.getTokens().then(tokens => {
            this.setState({ tokens: tokens })
        })
    }


    async approveRequests(ids) {
        if (this.state.disableSend) {
            return
        }

        console.debug("Approved: ", ids)

        var promises = ids.map((id) => {
            this.setState({ disableSend: true })
            var promise = approveAccessRequest(id, this.state.reason).catch(err => {
                console.debug(err)
                this.setState({ disableSend: false })
                throw (err)
            }).then(o => {
                this.updateAccessRequests().then(o => {
                    this.setState({ disableSend: false })
                    this.setState(
                        { anchorElAll: null, anchorEl: null }
                    )
                })
            })
            toast.promise(
                promise,
                {
                    pending: "Approving request...",
                    success: "Request approved!",
                    error: "Failed to approve request"
                }
            )
            return promise
        })





    }
    async denyRequests(ids) {
        if (this.state.disableSend) {
            return
        }

        console.debug("Denied: ", ids)

        var promises = ids.map((id) => {
            this.setState({ disableSend: true })
            var promise = denyAccessRequest(id, this.state.reason).catch(err => {
                console.debug(err)
                this.setState({ disableSend: false })
                throw (err)
            }).then(o => {
                this.updateAccessRequests().then(o => {
                    this.setState({ disableSend: false })
                    this.setState(
                        { anchorElAll: null, anchorEl: null }
                    )
                })
            })
            toast.promise(
                promise,
                {
                    pending: "Denying request...",
                    success: "Request denied",
                    error: "Failed to deny request"
                }
            )
            return promise
        })

    }

    render() {

        if (this.state.tokens == undefined) {
            return (
                <PageLoader ready={this.state.tokens ? true : false} />
            )
        }
        if (!this.state.ready) {
            return (
                <PageLoader ready={this.state.ready} />
            )
        }
        if (this.state.tokens.idToken.claims.groups.find(o => {
            return this.state.tokens.idToken.claims.groups.find(o => { return o.match("^Team-[^-]+-(Administrator|Manager)$") })
        }) == undefined) {

            return (
                <Paper sx={{ minHeight: '800px', pl: 2, pb: 3, pt: 1 }}>
                    Not allowed to access this resource
                </Paper>
            )
        }
        return (
            <div>

                <Typography paddingBottom={4} variant="h2">Approve access requests</Typography>
                <Paper sx={{ minHeight: '800px', pl: 2, pb: 3, pt: 1 }}>

                    <MUIDataTable
                        data={this.state.tableData}
                        columns={this.datatable.columns}
                        options={this.datatable.options}
                        xs={12} sm={12} md={12} lg={12} xl={12}
                    />
                    <Popover
                        id={'popoverAll'}
                        open={Boolean(this.state.anchorElAll)}
                        anchorEl={this.state.anchorElAll}
                        onClose={this.clearAnchorElAll}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}

                    >
                        <TextField
                            autoFocus
                            onChange={(event) => { this.setState({ reason: event.target.value }) }}
                            onKeyDown={(event) => {
                                if (event.keyCode == 13 && event.shiftKey == false) {
                                    event.preventDefault();
                                    var rows = this.state.selectedRows.map(o => {
                                        return this.state.tableData[o][3]
                                    })
                                    this.state.popoverMode === 'approve' ?
                                        this.approveRequests(rows)
                                        : this.denyRequests(rows)
                                }
                            }}
                            sx={{ minWidth: "300px" }}
                            label="Enter reason"
                            variant="filled"
                            value={this.state.reason}
                        />

                        {
                            this.state.popoverMode === 'approve' ?
                                <Button
                                    variant='contained'
                                    disabled={this.state.disableSend}
                                    aria-describedby={'popoverAll'}
                                    sx={{ minHeight: '60px', bgcolor: "green" }}
                                    onClick={
                                        (event) => this.approveRequests(
                                            this.state.selectedRows.map(o => {
                                                return this.state.tableData[o]
                                            })
                                        )}>
                                    Approve
                                </Button> :
                                <Button
                                    variant='contained'
                                    disabled={this.state.disableSend}
                                    aria-describedby={'popoverAll'}
                                    sx={{ minHeight: '60px', bgcolor: "red" }}
                                    onClick={
                                        (event) => this.denyRequests(
                                            this.state.selectedRows.map(o => {
                                                return this.state.tableData[o]
                                            }))}>
                                    Deny
                                </Button>
                        }



                    </Popover>


                    {
                        this.enableSelectRow ?
                            <Grid container alignContent={'right'} hidden>
                                <Grid item width={'33%'}></Grid>
                                <Grid item width={'33%'}></Grid>
                                <Grid item width={'33%'} alignContent={'right'}>
                                    <Grid container alignContent={'right'}>
                                        <Tooltip title="Approve request">
                                            <Grid item paddingRight={1}>
                                                <Button
                                                    disabled={this.state.selectedRows.length === 0}
                                                    onClick={(event) => this.setAnchorElAll(event, 'approve')} variant="contained"
                                                    sx={{ bgcolor: 'green' }} >
                                                    <ThumbUp sx={{ height: "1.5em", width: "1.5em", color: '#a5d6a7', mr: 1 }} />
                                                    Approve selected
                                                </Button>
                                            </Grid>
                                        </Tooltip>
                                        <Tooltip title="Deny request">
                                            <Grid item paddingLeft={1}>
                                                <Button
                                                    disabled={this.state.selectedRows.length === 0}
                                                    onClick={(event) => this.setAnchorElAll(event, 'deny')}
                                                    variant="contained"
                                                    sx={{ bgcolor: 'red' }}>
                                                    <ThumbDown sx={{ height: "1.5em", width: "1.5em", color: '#ffab91' }} />
                                                    Deny selected
                                                </Button>
                                            </Grid>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                            : null
                    }


                </Paper >
            </div >
        )

    }
}



function withRouter(C) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <C
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}

ApproveRequests = withRouter(ApproveRequests)
RequestAccess = withRouter(RequestAccess)

export { ApproveRequests, MyRequests, RequestAccess };
