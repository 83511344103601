/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTeamConfig = /* GraphQL */ `
  mutation CreateTeamConfig(
    $input: CreateTeamConfigInput!
    $condition: ModelTeamConfigConditionInput
  ) {
    createTeamConfig(input: $input, condition: $condition) {
      id
      AccessRequestDenySelfApproval
      AuditReviewEnabled
      AuditReviewSchedule
      SecurityEventNotifications {
        Slack {
          Enabled
          WebhookUri
        }
        DatadogEvent {
          Enabled
          ApiKey
        }
        EmailOnlyOnSuspectedThreats
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTeamConfig = /* GraphQL */ `
  mutation UpdateTeamConfig(
    $input: UpdateTeamConfigInput!
    $condition: ModelTeamConfigConditionInput
  ) {
    updateTeamConfig(input: $input, condition: $condition) {
      id
      AccessRequestDenySelfApproval
      AuditReviewEnabled
      AuditReviewSchedule
      SecurityEventNotifications {
        Slack {
          Enabled
          WebhookUri
        }
        DatadogEvent {
          Enabled
          ApiKey
        }
        EmailOnlyOnSuspectedThreats
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTeamConfig = /* GraphQL */ `
  mutation DeleteTeamConfig(
    $input: DeleteTeamConfigInput!
    $condition: ModelTeamConfigConditionInput
  ) {
    deleteTeamConfig(input: $input, condition: $condition) {
      id
      AccessRequestDenySelfApproval
      AuditReviewEnabled
      AuditReviewSchedule
      SecurityEventNotifications {
        Slack {
          Enabled
          WebhookUri
        }
        DatadogEvent {
          Enabled
          ApiKey
        }
        EmailOnlyOnSuspectedThreats
      }
      createdAt
      updatedAt
    }
  }
`;
export const createApprovalWorkflow = /* GraphQL */ `
  mutation CreateApprovalWorkflow(
    $input: CreateApprovalWorkflowInput!
    $condition: ModelApprovalWorkflowConditionInput
  ) {
    createApprovalWorkflow(input: $input, condition: $condition) {
      Type
      Target
      TargetDisplayName
      Steps {
        AccessApprover
        ConfigData
      }
      ConfigData
      Teams
      CanWrite
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateApprovalWorkflow = /* GraphQL */ `
  mutation UpdateApprovalWorkflow(
    $input: UpdateApprovalWorkflowInput!
    $condition: ModelApprovalWorkflowConditionInput
  ) {
    updateApprovalWorkflow(input: $input, condition: $condition) {
      Type
      Target
      TargetDisplayName
      Steps {
        AccessApprover
        ConfigData
      }
      ConfigData
      Teams
      CanWrite
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteApprovalWorkflow = /* GraphQL */ `
  mutation DeleteApprovalWorkflow(
    $input: DeleteApprovalWorkflowInput!
    $condition: ModelApprovalWorkflowConditionInput
  ) {
    deleteApprovalWorkflow(input: $input, condition: $condition) {
      Type
      Target
      TargetDisplayName
      Steps {
        AccessApprover
        ConfigData
      }
      ConfigData
      Teams
      CanWrite
      id
      createdAt
      updatedAt
    }
  }
`;
export const createApprovalWorkflowQueueItem = /* GraphQL */ `
  mutation CreateApprovalWorkflowQueueItem(
    $input: CreateApprovalWorkflowQueueItemInput!
    $condition: ModelApprovalWorkflowQueueItemConditionInput
  ) {
    createApprovalWorkflowQueueItem(input: $input, condition: $condition) {
      AccessRequest {
        Status
        ApprovalWorkflow {
          Type
          Target
          TargetDisplayName
          ConfigData
          Teams
          CanWrite
          id
          createdAt
          updatedAt
        }
        Username
        Reason
        TeamId
        ApprovalWorkflowQueue {
          nextToken
        }
        Readers
        id
        createdAt
        updatedAt
        accessRequestApprovalWorkflowId
      }
      ApprovalWorkflowStepType {
        AccessApprover
        ConfigData
      }
      Status
      TargetApproverIds
      TargetUsernameId
      TargetRoleId
      Reason
      ApprovedBy
      ApproverIdentity
      ApprovalDate
      id
      createdAt
      updatedAt
      accessRequestApprovalWorkflowQueueId
    }
  }
`;
export const updateApprovalWorkflowQueueItem = /* GraphQL */ `
  mutation UpdateApprovalWorkflowQueueItem(
    $input: UpdateApprovalWorkflowQueueItemInput!
    $condition: ModelApprovalWorkflowQueueItemConditionInput
  ) {
    updateApprovalWorkflowQueueItem(input: $input, condition: $condition) {
      AccessRequest {
        Status
        ApprovalWorkflow {
          Type
          Target
          TargetDisplayName
          ConfigData
          Teams
          CanWrite
          id
          createdAt
          updatedAt
        }
        Username
        Reason
        TeamId
        ApprovalWorkflowQueue {
          nextToken
        }
        Readers
        id
        createdAt
        updatedAt
        accessRequestApprovalWorkflowId
      }
      ApprovalWorkflowStepType {
        AccessApprover
        ConfigData
      }
      Status
      TargetApproverIds
      TargetUsernameId
      TargetRoleId
      Reason
      ApprovedBy
      ApproverIdentity
      ApprovalDate
      id
      createdAt
      updatedAt
      accessRequestApprovalWorkflowQueueId
    }
  }
`;
export const deleteApprovalWorkflowQueueItem = /* GraphQL */ `
  mutation DeleteApprovalWorkflowQueueItem(
    $input: DeleteApprovalWorkflowQueueItemInput!
    $condition: ModelApprovalWorkflowQueueItemConditionInput
  ) {
    deleteApprovalWorkflowQueueItem(input: $input, condition: $condition) {
      AccessRequest {
        Status
        ApprovalWorkflow {
          Type
          Target
          TargetDisplayName
          ConfigData
          Teams
          CanWrite
          id
          createdAt
          updatedAt
        }
        Username
        Reason
        TeamId
        ApprovalWorkflowQueue {
          nextToken
        }
        Readers
        id
        createdAt
        updatedAt
        accessRequestApprovalWorkflowId
      }
      ApprovalWorkflowStepType {
        AccessApprover
        ConfigData
      }
      Status
      TargetApproverIds
      TargetUsernameId
      TargetRoleId
      Reason
      ApprovedBy
      ApproverIdentity
      ApprovalDate
      id
      createdAt
      updatedAt
      accessRequestApprovalWorkflowQueueId
    }
  }
`;
export const createAccessRequest = /* GraphQL */ `
  mutation CreateAccessRequest(
    $input: CreateAccessRequestInput!
    $condition: ModelAccessRequestConditionInput
  ) {
    createAccessRequest(input: $input, condition: $condition) {
      Status
      ApprovalWorkflow {
        Type
        Target
        TargetDisplayName
        Steps {
          AccessApprover
          ConfigData
        }
        ConfigData
        Teams
        CanWrite
        id
        createdAt
        updatedAt
      }
      Username
      Reason
      TeamId
      ApprovalWorkflowQueue {
        items {
          Status
          TargetApproverIds
          TargetUsernameId
          TargetRoleId
          Reason
          ApprovedBy
          ApproverIdentity
          ApprovalDate
          id
          createdAt
          updatedAt
          accessRequestApprovalWorkflowQueueId
        }
        nextToken
      }
      Readers
      id
      createdAt
      updatedAt
      accessRequestApprovalWorkflowId
    }
  }
`;
export const updateAccessRequest = /* GraphQL */ `
  mutation UpdateAccessRequest(
    $input: UpdateAccessRequestInput!
    $condition: ModelAccessRequestConditionInput
  ) {
    updateAccessRequest(input: $input, condition: $condition) {
      Status
      ApprovalWorkflow {
        Type
        Target
        TargetDisplayName
        Steps {
          AccessApprover
          ConfigData
        }
        ConfigData
        Teams
        CanWrite
        id
        createdAt
        updatedAt
      }
      Username
      Reason
      TeamId
      ApprovalWorkflowQueue {
        items {
          Status
          TargetApproverIds
          TargetUsernameId
          TargetRoleId
          Reason
          ApprovedBy
          ApproverIdentity
          ApprovalDate
          id
          createdAt
          updatedAt
          accessRequestApprovalWorkflowQueueId
        }
        nextToken
      }
      Readers
      id
      createdAt
      updatedAt
      accessRequestApprovalWorkflowId
    }
  }
`;
export const deleteAccessRequest = /* GraphQL */ `
  mutation DeleteAccessRequest(
    $input: DeleteAccessRequestInput!
    $condition: ModelAccessRequestConditionInput
  ) {
    deleteAccessRequest(input: $input, condition: $condition) {
      Status
      ApprovalWorkflow {
        Type
        Target
        TargetDisplayName
        Steps {
          AccessApprover
          ConfigData
        }
        ConfigData
        Teams
        CanWrite
        id
        createdAt
        updatedAt
      }
      Username
      Reason
      TeamId
      ApprovalWorkflowQueue {
        items {
          Status
          TargetApproverIds
          TargetUsernameId
          TargetRoleId
          Reason
          ApprovedBy
          ApproverIdentity
          ApprovalDate
          id
          createdAt
          updatedAt
          accessRequestApprovalWorkflowQueueId
        }
        nextToken
      }
      Readers
      id
      createdAt
      updatedAt
      accessRequestApprovalWorkflowId
    }
  }
`;
export const createUserReport = /* GraphQL */ `
  mutation CreateUserReport(
    $input: CreateUserReportInput!
    $condition: ModelUserReportConditionInput
  ) {
    createUserReport(input: $input, condition: $condition) {
      Username
      UserGroups
      RumSessionId
      ReportType
      Description
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateUserReport = /* GraphQL */ `
  mutation UpdateUserReport(
    $input: UpdateUserReportInput!
    $condition: ModelUserReportConditionInput
  ) {
    updateUserReport(input: $input, condition: $condition) {
      Username
      UserGroups
      RumSessionId
      ReportType
      Description
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserReport = /* GraphQL */ `
  mutation DeleteUserReport(
    $input: DeleteUserReportInput!
    $condition: ModelUserReportConditionInput
  ) {
    deleteUserReport(input: $input, condition: $condition) {
      Username
      UserGroups
      RumSessionId
      ReportType
      Description
      id
      createdAt
      updatedAt
    }
  }
`;
export const createAwsAccount = /* GraphQL */ `
  mutation CreateAwsAccount(
    $input: CreateAwsAccountInput!
    $condition: ModelAwsAccountConditionInput
  ) {
    createAwsAccount(input: $input, condition: $condition) {
      id
      Name
      DisplayName
      BusinessAreaId
      BusinessArea {
        id
        DisplayName
        createdAt
        updatedAt
      }
      Email
      ManagingTeams
      MainAccountId
      MainAccount {
        id
        Name
        DisplayName
        BusinessAreaId
        BusinessArea {
          id
          DisplayName
          createdAt
          updatedAt
        }
        Email
        ManagingTeams
        MainAccountId
        MainAccount {
          id
          Name
          DisplayName
          BusinessAreaId
          Email
          ManagingTeams
          MainAccountId
          IsMainAccount
          createdAt
          updatedAt
        }
        SubAccounts {
          nextToken
        }
        IsMainAccount
        createdAt
        updatedAt
      }
      SubAccounts {
        items {
          id
          Name
          DisplayName
          BusinessAreaId
          Email
          ManagingTeams
          MainAccountId
          IsMainAccount
          createdAt
          updatedAt
        }
        nextToken
      }
      IsMainAccount
      createdAt
      updatedAt
    }
  }
`;
export const updateAwsAccount = /* GraphQL */ `
  mutation UpdateAwsAccount(
    $input: UpdateAwsAccountInput!
    $condition: ModelAwsAccountConditionInput
  ) {
    updateAwsAccount(input: $input, condition: $condition) {
      id
      Name
      DisplayName
      BusinessAreaId
      BusinessArea {
        id
        DisplayName
        createdAt
        updatedAt
      }
      Email
      ManagingTeams
      MainAccountId
      MainAccount {
        id
        Name
        DisplayName
        BusinessAreaId
        BusinessArea {
          id
          DisplayName
          createdAt
          updatedAt
        }
        Email
        ManagingTeams
        MainAccountId
        MainAccount {
          id
          Name
          DisplayName
          BusinessAreaId
          Email
          ManagingTeams
          MainAccountId
          IsMainAccount
          createdAt
          updatedAt
        }
        SubAccounts {
          nextToken
        }
        IsMainAccount
        createdAt
        updatedAt
      }
      SubAccounts {
        items {
          id
          Name
          DisplayName
          BusinessAreaId
          Email
          ManagingTeams
          MainAccountId
          IsMainAccount
          createdAt
          updatedAt
        }
        nextToken
      }
      IsMainAccount
      createdAt
      updatedAt
    }
  }
`;
export const deleteAwsAccount = /* GraphQL */ `
  mutation DeleteAwsAccount(
    $input: DeleteAwsAccountInput!
    $condition: ModelAwsAccountConditionInput
  ) {
    deleteAwsAccount(input: $input, condition: $condition) {
      id
      Name
      DisplayName
      BusinessAreaId
      BusinessArea {
        id
        DisplayName
        createdAt
        updatedAt
      }
      Email
      ManagingTeams
      MainAccountId
      MainAccount {
        id
        Name
        DisplayName
        BusinessAreaId
        BusinessArea {
          id
          DisplayName
          createdAt
          updatedAt
        }
        Email
        ManagingTeams
        MainAccountId
        MainAccount {
          id
          Name
          DisplayName
          BusinessAreaId
          Email
          ManagingTeams
          MainAccountId
          IsMainAccount
          createdAt
          updatedAt
        }
        SubAccounts {
          nextToken
        }
        IsMainAccount
        createdAt
        updatedAt
      }
      SubAccounts {
        items {
          id
          Name
          DisplayName
          BusinessAreaId
          Email
          ManagingTeams
          MainAccountId
          IsMainAccount
          createdAt
          updatedAt
        }
        nextToken
      }
      IsMainAccount
      createdAt
      updatedAt
    }
  }
`;
export const createBusinessArea = /* GraphQL */ `
  mutation CreateBusinessArea(
    $input: CreateBusinessAreaInput!
    $condition: ModelBusinessAreaConditionInput
  ) {
    createBusinessArea(input: $input, condition: $condition) {
      id
      DisplayName
      createdAt
      updatedAt
    }
  }
`;
export const updateBusinessArea = /* GraphQL */ `
  mutation UpdateBusinessArea(
    $input: UpdateBusinessAreaInput!
    $condition: ModelBusinessAreaConditionInput
  ) {
    updateBusinessArea(input: $input, condition: $condition) {
      id
      DisplayName
      createdAt
      updatedAt
    }
  }
`;
export const deleteBusinessArea = /* GraphQL */ `
  mutation DeleteBusinessArea(
    $input: DeleteBusinessAreaInput!
    $condition: ModelBusinessAreaConditionInput
  ) {
    deleteBusinessArea(input: $input, condition: $condition) {
      id
      DisplayName
      createdAt
      updatedAt
    }
  }
`;
export const createAuditEntry = /* GraphQL */ `
  mutation CreateAuditEntry(
    $input: CreateAuditEntryInput!
    $condition: ModelAuditEntryConditionInput
  ) {
    createAuditEntry(input: $input, condition: $condition) {
      id
      AuditBatch
      Type
      Username
      TeamId
      TargetId
      Message
      Auditors
      Readers
      ActionReason
      Action
      ActionDate
      DueDate
      NotificationDates
      lastModifiedBy
      lastModifiedById
      lastModifiedByIP
      createdAt
      updatedAt
    }
  }
`;
export const updateAuditEntry = /* GraphQL */ `
  mutation UpdateAuditEntry(
    $input: UpdateAuditEntryInput!
    $condition: ModelAuditEntryConditionInput
  ) {
    updateAuditEntry(input: $input, condition: $condition) {
      id
      AuditBatch
      Type
      Username
      TeamId
      TargetId
      Message
      Auditors
      Readers
      ActionReason
      Action
      ActionDate
      DueDate
      NotificationDates
      lastModifiedBy
      lastModifiedById
      lastModifiedByIP
      createdAt
      updatedAt
    }
  }
`;
export const deleteAuditEntry = /* GraphQL */ `
  mutation DeleteAuditEntry(
    $input: DeleteAuditEntryInput!
    $condition: ModelAuditEntryConditionInput
  ) {
    deleteAuditEntry(input: $input, condition: $condition) {
      id
      AuditBatch
      Type
      Username
      TeamId
      TargetId
      Message
      Auditors
      Readers
      ActionReason
      Action
      ActionDate
      DueDate
      NotificationDates
      lastModifiedBy
      lastModifiedById
      lastModifiedByIP
      createdAt
      updatedAt
    }
  }
`;
export const createAnnouncement = /* GraphQL */ `
  mutation CreateAnnouncement(
    $input: CreateAnnouncementInput!
    $condition: ModelAnnouncementConditionInput
  ) {
    createAnnouncement(input: $input, condition: $condition) {
      Title
      Message
      Start
      End
      AudienceGroups
      CreatedBy
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateAnnouncement = /* GraphQL */ `
  mutation UpdateAnnouncement(
    $input: UpdateAnnouncementInput!
    $condition: ModelAnnouncementConditionInput
  ) {
    updateAnnouncement(input: $input, condition: $condition) {
      Title
      Message
      Start
      End
      AudienceGroups
      CreatedBy
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteAnnouncement = /* GraphQL */ `
  mutation DeleteAnnouncement(
    $input: DeleteAnnouncementInput!
    $condition: ModelAnnouncementConditionInput
  ) {
    deleteAnnouncement(input: $input, condition: $condition) {
      Title
      Message
      Start
      End
      AudienceGroups
      CreatedBy
      id
      createdAt
      updatedAt
    }
  }
`;
export const createUserNotification = /* GraphQL */ `
  mutation CreateUserNotification(
    $input: CreateUserNotificationInput!
    $condition: ModelUserNotificationConditionInput
  ) {
    createUserNotification(input: $input, condition: $condition) {
      id
      Title
      Description
      Url
      ImageKey
      Username
      Read
      createdAt
      updatedAt
    }
  }
`;
export const updateUserNotification = /* GraphQL */ `
  mutation UpdateUserNotification(
    $input: UpdateUserNotificationInput!
    $condition: ModelUserNotificationConditionInput
  ) {
    updateUserNotification(input: $input, condition: $condition) {
      id
      Title
      Description
      Url
      ImageKey
      Username
      Read
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserNotification = /* GraphQL */ `
  mutation DeleteUserNotification(
    $input: DeleteUserNotificationInput!
    $condition: ModelUserNotificationConditionInput
  ) {
    deleteUserNotification(input: $input, condition: $condition) {
      id
      Title
      Description
      Url
      ImageKey
      Username
      Read
      createdAt
      updatedAt
    }
  }
`;
export const createEntityToBusinessAreaMapping = /* GraphQL */ `
  mutation CreateEntityToBusinessAreaMapping(
    $input: CreateEntityToBusinessAreaMappingInput!
    $condition: ModelEntityToBusinessAreaMappingConditionInput
  ) {
    createEntityToBusinessAreaMapping(input: $input, condition: $condition) {
      id
      EntityId
      EntityType
      Description
      BusinessAreaId
      BusinessArea {
        id
        DisplayName
        createdAt
        updatedAt
      }
      Team
      IsBillable
      createdAt
      updatedAt
    }
  }
`;
export const updateEntityToBusinessAreaMapping = /* GraphQL */ `
  mutation UpdateEntityToBusinessAreaMapping(
    $input: UpdateEntityToBusinessAreaMappingInput!
    $condition: ModelEntityToBusinessAreaMappingConditionInput
  ) {
    updateEntityToBusinessAreaMapping(input: $input, condition: $condition) {
      id
      EntityId
      EntityType
      Description
      BusinessAreaId
      BusinessArea {
        id
        DisplayName
        createdAt
        updatedAt
      }
      Team
      IsBillable
      createdAt
      updatedAt
    }
  }
`;
export const deleteEntityToBusinessAreaMapping = /* GraphQL */ `
  mutation DeleteEntityToBusinessAreaMapping(
    $input: DeleteEntityToBusinessAreaMappingInput!
    $condition: ModelEntityToBusinessAreaMappingConditionInput
  ) {
    deleteEntityToBusinessAreaMapping(input: $input, condition: $condition) {
      id
      EntityId
      EntityType
      Description
      BusinessAreaId
      BusinessArea {
        id
        DisplayName
        createdAt
        updatedAt
      }
      Team
      IsBillable
      createdAt
      updatedAt
    }
  }
`;
export const createPricingList = /* GraphQL */ `
  mutation CreatePricingList(
    $input: CreatePricingListInput!
    $condition: ModelPricingListConditionInput
  ) {
    createPricingList(input: $input, condition: $condition) {
      id
      ProductCode
      Description
      ApplicationGroup
      ValidForBusinessAreas
      Unit
      Cost
      createdAt
      updatedAt
    }
  }
`;
export const updatePricingList = /* GraphQL */ `
  mutation UpdatePricingList(
    $input: UpdatePricingListInput!
    $condition: ModelPricingListConditionInput
  ) {
    updatePricingList(input: $input, condition: $condition) {
      id
      ProductCode
      Description
      ApplicationGroup
      ValidForBusinessAreas
      Unit
      Cost
      createdAt
      updatedAt
    }
  }
`;
export const deletePricingList = /* GraphQL */ `
  mutation DeletePricingList(
    $input: DeletePricingListInput!
    $condition: ModelPricingListConditionInput
  ) {
    deletePricingList(input: $input, condition: $condition) {
      id
      ProductCode
      Description
      ApplicationGroup
      ValidForBusinessAreas
      Unit
      Cost
      createdAt
      updatedAt
    }
  }
`;
export const submitAccessRequest = /* GraphQL */ `
  mutation SubmitAccessRequest(
    $teamId: String!
    $requestTarget: String!
    $requestType: String
    $reason: String
  ) {
    submitAccessRequest(
      teamId: $teamId
      requestTarget: $requestTarget
      requestType: $requestType
      reason: $reason
    ) {
      Status
      ApprovalWorkflow {
        Type
        Target
        TargetDisplayName
        Steps {
          AccessApprover
          ConfigData
        }
        ConfigData
        Teams
        CanWrite
        id
        createdAt
        updatedAt
      }
      Username
      Reason
      TeamId
      ApprovalWorkflowQueue {
        items {
          Status
          TargetApproverIds
          TargetUsernameId
          TargetRoleId
          Reason
          ApprovedBy
          ApproverIdentity
          ApprovalDate
          id
          createdAt
          updatedAt
          accessRequestApprovalWorkflowQueueId
        }
        nextToken
      }
      Readers
      id
      createdAt
      updatedAt
      accessRequestApprovalWorkflowId
    }
  }
`;
export const approveAccessRequestQueueItem = /* GraphQL */ `
  mutation ApproveAccessRequestQueueItem($id: String!, $reason: String!) {
    approveAccessRequestQueueItem(id: $id, reason: $reason) {
      AccessRequest {
        Status
        ApprovalWorkflow {
          Type
          Target
          TargetDisplayName
          ConfigData
          Teams
          CanWrite
          id
          createdAt
          updatedAt
        }
        Username
        Reason
        TeamId
        ApprovalWorkflowQueue {
          nextToken
        }
        Readers
        id
        createdAt
        updatedAt
        accessRequestApprovalWorkflowId
      }
      ApprovalWorkflowStepType {
        AccessApprover
        ConfigData
      }
      Status
      TargetApproverIds
      TargetUsernameId
      TargetRoleId
      Reason
      ApprovedBy
      ApproverIdentity
      ApprovalDate
      id
      createdAt
      updatedAt
      accessRequestApprovalWorkflowQueueId
    }
  }
`;
export const addUserToRole = /* GraphQL */ `
  mutation AddUserToRole($username: String!, $roleId: String) {
    addUserToRole(username: $username, roleId: $roleId)
  }
`;
export const denyAccessRequestQueueItem = /* GraphQL */ `
  mutation DenyAccessRequestQueueItem($id: String!, $reason: String!) {
    denyAccessRequestQueueItem(id: $id, reason: $reason) {
      AccessRequest {
        Status
        ApprovalWorkflow {
          Type
          Target
          TargetDisplayName
          ConfigData
          Teams
          CanWrite
          id
          createdAt
          updatedAt
        }
        Username
        Reason
        TeamId
        ApprovalWorkflowQueue {
          nextToken
        }
        Readers
        id
        createdAt
        updatedAt
        accessRequestApprovalWorkflowId
      }
      ApprovalWorkflowStepType {
        AccessApprover
        ConfigData
      }
      Status
      TargetApproverIds
      TargetUsernameId
      TargetRoleId
      Reason
      ApprovedBy
      ApproverIdentity
      ApprovalDate
      id
      createdAt
      updatedAt
      accessRequestApprovalWorkflowQueueId
    }
  }
`;
export const putApprovalWorkflow = /* GraphQL */ `
  mutation PutApprovalWorkflow(
    $teamId: String!
    $target: String!
    $steps: [ApprovalWorkflowStepInput]!
    $configData: AWSJSON
  ) {
    putApprovalWorkflow(
      teamId: $teamId
      target: $target
      steps: $steps
      configData: $configData
    ) {
      Type
      Target
      TargetDisplayName
      Steps {
        AccessApprover
        ConfigData
      }
      ConfigData
      Teams
      CanWrite
      id
      createdAt
      updatedAt
    }
  }
`;
export const createTeamRole = /* GraphQL */ `
  mutation CreateTeamRole(
    $roleName: String!
    $roleDescription: String!
    $roles: [String]
    $teamId: String!
  ) {
    createTeamRole(
      roleName: $roleName
      roleDescription: $roleDescription
      roles: $roles
      teamId: $teamId
    )
  }
`;
export const updateTeamRole = /* GraphQL */ `
  mutation UpdateTeamRole(
    $roleId: String!
    $roleDescription: String
    $roles: [String]
    $teamId: String!
  ) {
    updateTeamRole(
      roleId: $roleId
      roleDescription: $roleDescription
      roles: $roles
      teamId: $teamId
    )
  }
`;
export const updateTeamUser = /* GraphQL */ `
  mutation UpdateTeamUser(
    $teamId: String!
    $username: String!
    $roles: [String]
    $expirationDate: AWSDateTime
  ) {
    updateTeamUser(
      teamId: $teamId
      username: $username
      roles: $roles
      expirationDate: $expirationDate
    ) {
      Username
      FirstName
      LastName
      Status
      Email
      City
      Department
      DisplayName
      Division
      Locale
      Manager
      MobilePhone
      Organization
      PreferredLanguage
      PrimaryPhone
      SecondEmail
      StreetAddress
      Timezone
      Title
      UserType
      Roles {
        RoleId
        RoleType
        RoleName
        Description
        Roles
      }
      ExpirationDate
      LastLogin
      Created
      LastUpdated
    }
  }
`;
export const addTeamUser = /* GraphQL */ `
  mutation AddTeamUser(
    $teamId: String!
    $firstName: String!
    $lastName: String!
    $username: String!
    $roles: [String]
    $expirationDate: AWSDateTime
  ) {
    addTeamUser(
      teamId: $teamId
      firstName: $firstName
      lastName: $lastName
      username: $username
      roles: $roles
      expirationDate: $expirationDate
    ) {
      Username
      FirstName
      LastName
      Status
      Email
      City
      Department
      DisplayName
      Division
      Locale
      Manager
      MobilePhone
      Organization
      PreferredLanguage
      PrimaryPhone
      SecondEmail
      StreetAddress
      Timezone
      Title
      UserType
      Roles {
        RoleId
        RoleType
        RoleName
        Description
        Roles
      }
      ExpirationDate
      LastLogin
      Created
      LastUpdated
    }
  }
`;
export const activateTeamUser = /* GraphQL */ `
  mutation ActivateTeamUser($teamId: String!, $username: String!) {
    activateTeamUser(teamId: $teamId, username: $username) {
      Username
      FirstName
      LastName
      Status
      Email
      City
      Department
      DisplayName
      Division
      Locale
      Manager
      MobilePhone
      Organization
      PreferredLanguage
      PrimaryPhone
      SecondEmail
      StreetAddress
      Timezone
      Title
      UserType
      Roles {
        RoleId
        RoleType
        RoleName
        Description
        Roles
      }
      ExpirationDate
      LastLogin
      Created
      LastUpdated
    }
  }
`;
export const resetUserPassword = /* GraphQL */ `
  mutation ResetUserPassword($teamId: String!, $username: String!) {
    resetUserPassword(teamId: $teamId, username: $username) {
      Username
      FirstName
      LastName
      Status
      Email
      City
      Department
      DisplayName
      Division
      Locale
      Manager
      MobilePhone
      Organization
      PreferredLanguage
      PrimaryPhone
      SecondEmail
      StreetAddress
      Timezone
      Title
      UserType
      Roles {
        RoleId
        RoleType
        RoleName
        Description
        Roles
      }
      ExpirationDate
      LastLogin
      Created
      LastUpdated
    }
  }
`;
export const disableUser = /* GraphQL */ `
  mutation DisableUser($teamId: String!, $username: String!) {
    disableUser(teamId: $teamId, username: $username) {
      Username
      FirstName
      LastName
      Status
      Email
      City
      Department
      DisplayName
      Division
      Locale
      Manager
      MobilePhone
      Organization
      PreferredLanguage
      PrimaryPhone
      SecondEmail
      StreetAddress
      Timezone
      Title
      UserType
      Roles {
        RoleId
        RoleType
        RoleName
        Description
        Roles
      }
      ExpirationDate
      LastLogin
      Created
      LastUpdated
    }
  }
`;
export const resetUserMfa = /* GraphQL */ `
  mutation ResetUserMfa($teamId: String!, $username: String!) {
    resetUserMfa(teamId: $teamId, username: $username) {
      Username
      FirstName
      LastName
      Status
      Email
      City
      Department
      DisplayName
      Division
      Locale
      Manager
      MobilePhone
      Organization
      PreferredLanguage
      PrimaryPhone
      SecondEmail
      StreetAddress
      Timezone
      Title
      UserType
      Roles {
        RoleId
        RoleType
        RoleName
        Description
        Roles
      }
      ExpirationDate
      LastLogin
      Created
      LastUpdated
    }
  }
`;
export const resendActivationEmail = /* GraphQL */ `
  mutation ResendActivationEmail($teamId: String!, $username: String!) {
    resendActivationEmail(teamId: $teamId, username: $username) {
      Username
      FirstName
      LastName
      Status
      Email
      City
      Department
      DisplayName
      Division
      Locale
      Manager
      MobilePhone
      Organization
      PreferredLanguage
      PrimaryPhone
      SecondEmail
      StreetAddress
      Timezone
      Title
      UserType
      Roles {
        RoleId
        RoleType
        RoleName
        Description
        Roles
      }
      ExpirationDate
      LastLogin
      Created
      LastUpdated
    }
  }
`;
export const removeTeamMember = /* GraphQL */ `
  mutation RemoveTeamMember($teamId: String!, $username: String!) {
    removeTeamMember(teamId: $teamId, username: $username)
  }
`;
export const addIpWhitelist = /* GraphQL */ `
  mutation AddIpWhitelist($ip: AWSIPAddress!, $dryRun: Boolean) {
    addIpWhitelist(ip: $ip, dryRun: $dryRun)
  }
`;
export const adminUpdateGroupManagedBy = /* GraphQL */ `
  mutation AdminUpdateGroupManagedBy($groupId: ID!, $groupList: [String]) {
    adminUpdateGroupManagedBy(groupId: $groupId, groupList: $groupList) {
      GroupId
      GroupName
      GroupType
      Description
      Members
      BusinessArea
      ManagedByGroups
    }
  }
`;
export const adminCreateRole = /* GraphQL */ `
  mutation AdminCreateRole(
    $roleId: ID!
    $roleType: RoleType!
    $managedByGroups: [String]
  ) {
    adminCreateRole(
      roleId: $roleId
      roleType: $roleType
      managedByGroups: $managedByGroups
    ) {
      GroupId
      GroupName
      GroupType
      Description
      Members
      BusinessArea
      ManagedByGroups
    }
  }
`;
export const adminCreateTeam = /* GraphQL */ `
  mutation AdminCreateTeam(
    $teamId: ID!
    $teamName: String!
    $businessArea: String
  ) {
    adminCreateTeam(
      teamId: $teamId
      teamName: $teamName
      businessArea: $businessArea
    ) {
      GroupId
      GroupName
      GroupType
      Description
      Members
      BusinessArea
      ManagedByGroups
    }
  }
`;
export const adminUpdateTeam = /* GraphQL */ `
  mutation AdminUpdateTeam($teamId: ID!, $businessArea: String) {
    adminUpdateTeam(teamId: $teamId, businessArea: $businessArea) {
      GroupId
      GroupName
      GroupType
      Description
      Members
      BusinessArea
      ManagedByGroups
    }
  }
`;
export const adminAddGroupMember = /* GraphQL */ `
  mutation AdminAddGroupMember($groupId: String!, $username: String!) {
    adminAddGroupMember(groupId: $groupId, username: $username)
  }
`;
export const adminRemoveGroupMember = /* GraphQL */ `
  mutation AdminRemoveGroupMember($groupId: String!, $username: String!) {
    adminRemoveGroupMember(groupId: $groupId, username: $username)
  }
`;
export const adminCreateGroup = /* GraphQL */ `
  mutation AdminCreateGroup(
    $roleId: String!
    $roleType: RoleType
    $description: String!
    $managedByGroups: [String]
  ) {
    adminCreateGroup(
      roleId: $roleId
      roleType: $roleType
      description: $description
      managedByGroups: $managedByGroups
    ) {
      GroupId
      GroupName
      GroupType
      Description
      Members
      BusinessArea
      ManagedByGroups
    }
  }
`;
export const adminRemoveGroup = /* GraphQL */ `
  mutation AdminRemoveGroup($roleId: String!, $description: String!) {
    adminRemoveGroup(roleId: $roleId, description: $description)
  }
`;
export const adminResetUserPassword = /* GraphQL */ `
  mutation AdminResetUserPassword($username: String!) {
    adminResetUserPassword(username: $username)
  }
`;
export const adminDisableUser = /* GraphQL */ `
  mutation AdminDisableUser($username: String!) {
    adminDisableUser(username: $username)
  }
`;
export const adminEnableUser = /* GraphQL */ `
  mutation AdminEnableUser($username: String!) {
    adminEnableUser(username: $username)
  }
`;
export const adminSuspendUser = /* GraphQL */ `
  mutation AdminSuspendUser($username: String!) {
    adminSuspendUser(username: $username)
  }
`;
export const adminResetUserMfa = /* GraphQL */ `
  mutation AdminResetUserMfa($username: String!) {
    adminResetUserMfa(username: $username)
  }
`;
export const invokeAuditReview = /* GraphQL */ `
  mutation InvokeAuditReview($teamId: String) {
    invokeAuditReview(teamId: $teamId)
  }
`;
