import React, { Component } from 'react';
import '@aws-amplify/ui-react/styles.css';
import { Alert, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Input, Switch, TextField, } from '@mui/material';
import { Button, Zoom } from '@mui/material';
import { Paper } from '@mui/material';
import { Grid } from '@aws-amplify/ui-react';
import { Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useParams, useLocation, useNavigate } from "react-router-dom"

import { Settings } from '@mui/icons-material';

import {
    Stack,
    Tooltip
} from '@mui/material';

import { PageLoader } from '../Components/Loader';
import { createTeamConfig, getTeam, getTeamConfig, updateTeamConfig } from './Queries';
import { toast } from 'react-toastify';
import { Box } from '@mui/system';

class TeamSettings extends Component {
    constructor(props) {
        super(props);
        var teamId = this.props.router.params.teamId
        this.getTeamSettings = this.getTeamSettings.bind(this)

        this.evalSlackInput = this.evalSlackInput.bind(this)
        this.evalDatadogEventInput = this.evalDatadogEventInput.bind(this)

        this.isUnique = this.isUnique.bind(this)
        this.state = {
            teamId: teamId,
            formState: {},
            currentTeam: {},
            AuditReviewSchedule: [],
            ready: false,
            hasChanged: false
        }

        getTeam(teamId).then(team => { this.setState({ currentTeam: team }) }).then(o => { this.getTeamSettings() })


    }

    getTeamSettings() {
        return getTeamConfig(this.state.teamId).then(teamConfig => {
            if (!teamConfig) {
                teamConfig = {
                    id: this.state.teamId,
                    AuditReviewEnabled: true,
                    AuditReviewSchedule: [1, 4, 7, 10],
                    AccessRequestDenySelfApproval: false
                }
            }
            else {

                delete teamConfig.createdAt
                delete teamConfig.updatedAt
            }
            if(teamConfig.AuditReviewEnabled == null || teamConfig.AuditReviewEnabled == undefined){
                teamConfig.AuditReviewEnabled = true
            }
            if (!teamConfig.SecurityEventNotifications) {
                teamConfig['SecurityEventNotifications'] = {}
            }
            if (!teamConfig.SecurityEventNotifications.Slack) {
                teamConfig.SecurityEventNotifications['Slack'] = {}
            }

            if (!teamConfig.SecurityEventNotifications.EmailOnlyOnSuspectedThreats) {
                teamConfig.SecurityEventNotifications['EmailOnlyOnSuspectedThreats'] = true
            }
            if (!teamConfig.SecurityEventNotifications.DatadogEvent) {
                teamConfig.SecurityEventNotifications['DatadogEvent'] = {}
            }


            this.evalSlackInput(teamConfig)
            this.evalDatadogEventInput(teamConfig)

            this.setState({ teamConfig: teamConfig, ready: true, createConfig: teamConfig ? false : true, AuditReviewSchedule: teamConfig.AuditReviewSchedule ? teamConfig.AuditReviewSchedule : [1, 4, 7, 10], ddOk: this.evalDatadogEventInput(teamConfig), slackOk: this.evalSlackInput(teamConfig) })

        }).catch(err => {

        })
    }

    async saveConfig() {
        console.debug("Saving config")
        var teamConfig = this.state.teamConfig
        if (teamConfig) {
            var ddOk = this.evalDatadogEventInput(teamConfig)
            var slackOk = this.evalSlackInput(teamConfig)
            if (!ddOk || !slackOk) {
                this.setState({ submitError: true })
                toast.error("Team config not saved due to invalid options")
                return
            }


            var promise = updateTeamConfig(this.state.teamConfig).then(o => { this.setState({ hasChanged: false }) })
                .then(o => { this.setState({ submitError: false }) })
                .then(o => {
                    teamConfig.SecurityEventNotifications.DatadogEvent.ApiKey = '******'
                    this.setState({ teamConfig: teamConfig })
                })

            toast.promise(
                promise,
                {
                    pending: "Updating team configuration",
                    error: "Something went wrong while updating the team configuration",
                    success: "Team configuration updated"
                }
            )
        }

    }

    isUnique(item, pos) {
        return this.state.AuditReviewSchedule.indexOf(item) == pos;
    }
    evalSlackInput(teamConfig) {
        if (!teamConfig.SecurityEventNotifications.Slack.Enabled) {
            this.setState({ slackOk: true, slackError: undefined })
            console.debug({ ddOk: this.state.ddOk, slackOk: this.state.slackOk })
            return true
        }
        if (
            teamConfig.SecurityEventNotifications.Slack.WebhookUri &&
            teamConfig.SecurityEventNotifications.Slack.WebhookUri.startsWith("https://") &&
            teamConfig.SecurityEventNotifications.Slack.WebhookUri.includes('slack.com')
        ) {
            this.setState({ slackOk: true, slackError: undefined })
            console.debug({ ddOk: this.state.ddOk, slackOk: this.state.slackOk })
            return true
        }

        this.setState({ slackOk: false, slackError: "Invalid webhook uri" })
        console.debug({ ddOk: this.state.ddOk, slackOk: this.state.slackOk })
        return false
    }
    evalDatadogEventInput(teamConfig) {
        if (!teamConfig.SecurityEventNotifications.DatadogEvent.Enabled) {
            this.setState({ ddOk: true, ddError: undefined })
            console.debug({ ddOk: this.state.ddOk, slackOk: this.state.slackOk })
            return true
        }
        if (
            teamConfig.SecurityEventNotifications.DatadogEvent.ApiKey &&
            teamConfig.SecurityEventNotifications.DatadogEvent.ApiKey != ""
        ) {
            this.setState({ ddOk: true, ddError: undefined })
            console.debug({ ddOk: this.state.ddOk, slackOk: this.state.slackOk })
            return true
        }

        this.setState({ ddOk: false, ddError: "Invalid value" })
        console.debug({ ddOk: this.state.ddOk, slackOk: this.state.slackOk })
        return false


    }
    render() {
        if (!this.state.ready) {
            return (
                <PageLoader ready={this.state.ready} />
            )
        }

        const months = {
            January: 1,
            February: 2,
            March: 3,
            April: 4,
            May: 5,
            June: 6,
            July: 7,
            August: 8,
            September: 9,
            October: 10,
            November: 11,
            December: 12,
        }

        return (
            <Paper>
                <Paper sx={{ margin: 3 }}>
                    <div style={{ pb: 3, display: "flex", flexDirection: "row" }}>
                        <Grid container style={{ display: "flex", flexDirection: "row" }}>
                            <Grid item>
                                <Typography variant="h5" sx={{ marginTop: 4 }}>
                                    Team {this.state.currentTeam.TeamName}
                                </Typography>
                            </Grid>
                            <Grid item><Typography variant="h6" mt={5} ml={1}><ArrowForwardIosIcon /> <Settings /></Typography></Grid>
                            <Grid item><Typography variant="h6" mt={4.5} component="div"> &nbsp; Settings</Typography></Grid>
                        </Grid>

                    </div>
                    <Paper sx={{ display: 'block', ml: 3, pl: 3, pb: 3 }}>
                        <FormControl component="fieldset" variant='standard'>


                            <FormGroup aria-label="position" sx={{ paddingTop: 2 }}>
                                <FormLabel component="legend" >Audit Review</FormLabel>
                                <Tooltip title="Enable audit review beta" >
                                    <FormControlLabel
                                        value={this.state.teamConfig.AuditReviewEnabled}
                                        checked={this.state.teamConfig.AuditReviewEnabled}
                                        control={<Switch color="primary" />}
                                        onChange={(event) => {
                                            console.debug(event)
                                            var teamConfig = this.state.teamConfig
                                            teamConfig['AuditReviewEnabled'] = event.target.checked
                                            this.setState({ teamConfig: teamConfig, hasChanged: true })

                                        }}
                                        label="Audit review feature"
                                    />
                                </Tooltip>



                                {
                                    this.state.teamConfig['AuditReviewEnabled'] ?
                                        [<FormLabel component="legend" >Audit review schedule</FormLabel>,
                                        Object.keys(months).map(month => {
                                            return (
                                                <FormControlLabel
                                                    value={this.state.AuditReviewSchedule.includes(months[month])}
                                                    checked={this.state.AuditReviewSchedule.includes(months[month])}
                                                    control={<Switch color="primary" />}

                                                    onChange={(event) => {
                                                        var sched = this.state.AuditReviewSchedule
                                                        sched = sched.filter(o => { return o != months[month] })
                                                        var teamConfig = this.state.teamConfig
                                                        if (event.target.checked) {
                                                            sched.push(months[month])
                                                        }
                                                        sched.sort()
                                                        teamConfig['AuditReviewSchedule'] = sched
                                                        this.setState({ AuditReviewSchedule: sched, teamConfig: teamConfig, hasChanged: true })
                                                    }}
                                                    label={month}
                                                />
                                            )

                                        })] : null
                                }

                                <Divider />


                            </FormGroup>
                            <Divider />
                            <FormGroup aria-label="position" sx={{ paddingTop: 5 }}>
                                <FormLabel component="legend" >Security Notifications (BETA)</FormLabel>
                                <Typography variant='caption' pb={1}>
                                    Get detected user threats and anomalies forwarded to slack and datadog.
                                </Typography>

                                <Tooltip title="Turning off might potentially lead to high volumes of notification emails to team admins." >
                                    <FormControlLabel
                                        value={this.state.teamConfig['SecurityEventNotifications'].EmailOnlyOnSuspectedThreats}
                                        checked={this.state.teamConfig['SecurityEventNotifications'].EmailOnlyOnSuspectedThreats}
                                        control={<Switch color="primary" />}
                                        onChange={(event) => {
                                            console.debug(event)
                                            var teamConfig = this.state.teamConfig
                                            teamConfig['SecurityEventNotifications']['EmailOnlyOnSuspectedThreats'] = event.target.checked
                                            this.setState({ teamConfig: teamConfig, hasChanged: true })


                                        }}
                                        label="Email only on suspected threats"
                                    />
                                </Tooltip>

                                <Tooltip title="Enable Slack Notifications" >
                                    <FormControlLabel
                                        value={this.state.teamConfig['SecurityEventNotifications']['Slack']['Enabled']}
                                        checked={this.state.teamConfig['SecurityEventNotifications']['Slack']['Enabled']}
                                        control={<Switch color="primary" />}
                                        onChange={(event) => {
                                            console.debug(event)
                                            var teamConfig = this.state.teamConfig
                                            teamConfig['SecurityEventNotifications']['Slack']['Enabled'] = event.target.checked
                                            this.setState({ teamConfig: teamConfig, hasChanged: true })
                                            this.evalSlackInput(teamConfig)

                                        }}
                                        label="Slack Webhook"
                                    />
                                </Tooltip>

                                {
                                    this.state.teamConfig.SecurityEventNotifications.Slack.Enabled ?
                                        <Tooltip title="Enter a slack webhook uri" >
                                            <FormControlLabel
                                                value={this.state.teamConfig.SecurityEventNotifications.Slack.WebhookUri}
                                                checked={this.state.teamConfig.SecurityEventNotifications.Slack.WebhookUri}
                                                control={<TextField label="Slack WebHook URL" variant="standard" error={!this.state.slackOk} helperText={this.state.slackError ? this.state.slackError : "Enter a datadog api key"} />}
                                                onChange={(event) => {
                                                    console.debug(event)
                                                    var teamConfig = this.state.teamConfig
                                                    teamConfig['SecurityEventNotifications']['Slack']['WebhookUri'] = event.target.value
                                                    this.setState({ teamConfig: teamConfig, hasChanged: true })
                                                    this.evalSlackInput(teamConfig)
                                                }}
                                                sx={{ pl: 2 }}
                                            />
                                        </Tooltip>
                                        : null
                                }

                                <Tooltip title="Enable Datadog Events" >
                                    <FormControlLabel
                                        value={this.state.teamConfig['SecurityEventNotifications']['DatadogEvent']['Enabled']}
                                        checked={this.state.teamConfig['SecurityEventNotifications']['DatadogEvent']['Enabled']}
                                        control={<Switch color="primary" />}
                                        onChange={(event) => {
                                            console.debug(event)
                                            var teamConfig = this.state.teamConfig
                                            teamConfig['SecurityEventNotifications']['DatadogEvent']['Enabled'] = event.target.checked
                                            this.setState({ teamConfig: teamConfig, hasChanged: true })
                                            this.evalDatadogEventInput(teamConfig)

                                        }}
                                        label="Datadog event"
                                    />
                                </Tooltip>

                                {
                                    this.state.teamConfig.SecurityEventNotifications.DatadogEvent.Enabled ?
                                        <Tooltip title="Enter your datadog API key" >
                                            <FormControlLabel
                                                value={this.state.teamConfig.SecurityEventNotifications.DatadogEvent.ApiKey}
                                                checked={this.state.teamConfig.SecurityEventNotifications.DatadogEvent.ApiKey}
                                                control={<TextField label="Datadog API key" variant="standard" error={!this.state.ddOk} helperText={this.state.ddError ? this.state.ddError : "Enter a datadog api key"} />}
                                                onChange={(event) => {
                                                    console.debug(event)
                                                    var teamConfig = this.state.teamConfig
                                                    teamConfig['SecurityEventNotifications']['DatadogEvent']['ApiKey'] = event.target.value
                                                    this.setState({ teamConfig: teamConfig, hasChanged: true })
                                                    this.evalDatadogEventInput(teamConfig)

                                                }}
                                                sx={{ pl: 2 }}
                                            />
                                        </Tooltip>
                                        : null
                                }
                            </FormGroup>



                            <Box width={'100%'}></Box>
                            <Button
                                disabled={!this.state.hasChanged}
                                variant="contained"
                                onClick={(event) => this.saveConfig()}
                                sx={{ marginLeft: 40 }}
                                color={this.state.submitError ? 'error' : 'info'}
                            >
                                Save
                            </Button>
                        </FormControl>




                    </Paper>
                </Paper>
            </Paper>
        )
    }
}

function withRouter(C) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <C
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}

TeamSettings = withRouter(TeamSettings)
export { TeamSettings }