import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import awsExports from '../amplifyconfiguration';
import React, { Component, useRef } from 'react';
import { getTypographyUtilityClass, Grid, Paper, Tooltip, Typography } from '@mui/material';
import { AddModerator, AppRegistration, Apps, Group } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { oktaAuth } from '../Components/Auth';
import { PageLoader } from '../Components/Loader';
import { getOktaUser, listAnnouncements } from '../Components/Queries';
import shortid from 'shortid';



class HomeDashboard extends Component {
    constructor(props) {
        super(props)
        this._isMounted = false

        this.displayAnnouncements = this.displayAnnouncements.bind(this)
        this.init = this.init.bind(this)
        this.state = {
            groups: [],
            ready: false,
            announcements: []
        }
        this.iconItems = [
            {
                Icon: <AppRegistration sx={{ fontSize: 140 }} />,
                Title: "Request access & apps",
                Description: "Request access and apps",
                To: "/access/request",
            },
            {
                Icon: <Apps sx={{ fontSize: 140 }} />,
                Title: "Your access / app requests",
                Description: "",
                To: "/access/view-requests",
            },
            {
                Icon: <AddModerator sx={{ fontSize: 140 }} />,
                Title: "Whitelist IP",
                Description: "",
                To: "/modules/WhitelistIp",
            },
            {
                Icon: <Apps sx={{ fontSize: 140 }} />,
                Title: "Approve requests",
                Description: "",
                To: "/access/approve",
                When: "^Team-[^-]+-(Manager|Administrator)$"
            }
        ]








        this.paperSX = {
            minHeight: '20em',
            minWidth: '20em',
            px: 4,
            ':hover': {
                boxShadow: 10, // theme.shadows[20]
            }
        }

        this.linkSx = {
            ':hover': {
                boxShadow: 10, // theme.shadows[20]
            }
        }

    }

    init(userInfo) {

        this.setState({ groups: userInfo.groups })
        var teams = {}
        userInfo.groups.filter(o => { return o.match("^Team-[^-]+-(Manager|Administrator)$") }).forEach(g => {
            teams[String(g).split("-")[1]] = true
        })
        this.setState({
            extraIcons: Object.keys(teams).map(team => {
                return {
                    Icon: <Group sx={{ fontSize: 140 }} />,
                    Title: "Team " + team,
                    Description: "Team " + team,
                    To: "/modules/TeamAdministration/Team-" + team + "/Manage"
                }
            })
        })

        this.setState({ ready: true })
        this.displayAnnouncements()
    }



    async componentDidMount() {
        this._isMounted = true
        if (!this.props.userInfo) {
            getOktaUser().then(userInfo => {
                this.setState({ userInfo: userInfo })
                this.init(userInfo)
            })
        }
        else {
            this.setState({ userInfo: this.props.userInfo })
            this.init(this.props.userInfo)
        }



    }

    async componentWillUnmount() {
        this._isMounted = false
    }

    toHumanDateTime(string) {
        return string.replace(/\.\d+\w*/, '').replace("T", " ")
    }

    displayAnnouncements() {

        listAnnouncements({
            'Start': {
                'le': (new Date()).toISOString()
            },
            'End': {
                'gt': (new Date()).toISOString()
            }
        }).then(announcements => {
            this.setState({
                announcements: announcements
            })
        })

    }

    render() {

        if (!this._isMounted) return null
        if (!this.state.ready) {
            <PageLoader ready={this.state.ready} />
        }
        return (

            <Grid key={shortid.generate()} container>

                <Grid key={shortid.generate()} xs={6} sm={6} md={6} lg={6} xl={6} item>
                    <Paper key={shortid.generate()} sx={{ width: '100%', px: 2, py: 2, mb: 0.5 }}>
                        <Typography variant='h6' >News & Announcements</Typography>
                    </Paper>
                    {
                        this.state.announcements.map(a => {
                            return (
                                <Card key={shortid.generate()} sx={{ minWidth: '100%', mb: 2 }}>
                                    <CardContent key={shortid.generate()} sx={{ minWidth: '100%' }}>
                                        <Grid key={shortid.generate()} spacing={0.1} container>
                                            {
                                                a.AudienceGroups.map(t => {
                                                    var bgcolor = "#01579b"
                                                    if (t == 'Everyone') bgcolor = '#1b5e20'
                                                    return (
                                                        <Grid key={shortid.generate()} item>
                                                            <Typography px={0.1} py={0.1} bgcolor={bgcolor} sx={{ fontSize: 10 }} color="text.secondary" gutterBottom>
                                                                {t}
                                                            </Typography>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>

                                        <Typography variant="h5" component="div">
                                            {a.Title}
                                        </Typography>
                                        <Typography sx={{ mb: 1.5, fontSize: 10 }} color="text.secondary">
                                            {this.toHumanDateTime(a.Start)} - {this.toHumanDateTime(a.End)}
                                        </Typography>
                                        <Typography variant="body2">
                                            {a.Message}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>

                                    </CardActions>
                                </Card>
                            )

                        })
                    }


                </Grid>

                <Grid key={shortid.generate()} xs={6} sm={6} md={6} lg={6} xl={6} item>
                    <Paper sx={{ width: '100%', px: 2, py: 2, mb: 0.5 }}>
                        <Typography variant='h6' >Quick access</Typography>
                    </Paper>

                    <Grid key={shortid.generate()} spacing={2} container>

                        {
                            this.iconItems.map(item => {
                                var Icon = item.Icon
                                var Title = item.Title
                                var Description = item.Description
                                var To = item.To

                                return (
                                    <Grid pb={4} item>
                                        <Paper component={NavLink} to={To}>

                                            <Typography sx={this.linkSx} align='center' variant='subtitle2'>{Icon}<br />{Title}</Typography>

                                        </Paper>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Grid>


                {
                    this.state.extraIcons ?
                        <Grid key={shortid.generate()} item>
                            <Grid key={shortid.generate()} spacing={2} container>
                                <Paper sx={{ width: '100%', px: 2, py: 2, mb: 0.5 }}>
                                    <Typography variant='h6' >Team admin</Typography>
                                </Paper>
                                {
                                    this.state.extraIcons.map(item => {
                                        var Icon = item.Icon
                                        var Title = item.Title
                                        var Description = item.Description
                                        var To = item.To

                                        return (
                                            <Grid key={shortid.generate()} pb={4} item>
                                                <Paper component={NavLink} to={To}>

                                                    <Typography sx={this.linkSx} align='center' variant='subtitle2'>{Icon}<br />{Title}</Typography>

                                                </Paper>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Grid> : null
                }


            </Grid>


        );
    }
}
export default function Home() {
    return <HomeDashboard />;
}

