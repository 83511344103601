import { Grid } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Download } from '@mui/icons-material';
import { Button, Paper, Typography } from '@mui/material';
import { Amplify } from 'aws-amplify';
import MUIDataTable from 'mui-datatables';
import React, { Component } from 'react';
import { PageLoader } from '../Components/Loader';
import { getImageScanReport, listImageScanReports } from '../Components/Queries';
import awsExports from '../amplifyconfiguration';
Amplify.configure(awsExports);



class ModuleImageScanReports extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ready: false
        };
        this.datatable = {
            columns: [
                {
                    name: "Name",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            var isWorking = false
                            return (
                                <Typography>

                                    <Button
                                        variant='contained'
                                        color='success'
                                        disabled={isWorking}
                                        sx={{
                                            marginRight: 2
                                        }}
                                        onClick={(event) => {
                                            getImageScanReport(
                                                tableMeta.rowData[2],
                                                tableMeta.rowData[0]
                                            ).then(report => {
                                                console.debug(`Opening: ${report.DownloadUrl}`)
                                                window.open(report.DownloadUrl, '_blank', 'noopener,noreferrer')
                                            })

                                        }}
                                    >
                                        <Download />
                                    </Button>
                                    {tableMeta.rowData[0]}
                                </Typography>

                            )
                        }
                    },

                },
                { name: "Account Name", options: { filter: true, sort: true } },
                { name: "Account Id", options: { filter: true, sort: true } },
                { name: "Date", options: { filter: true, sort: true } },

            ],

            options: {
                rowsPerPage: 25,
                rowsPerPageOptions: [25, 50, 100],
                filter: true,
                download: false,
                print: false,
                filterType: 'dropdown',
                responsive: 'standard',
                selectableRows: 'none',
                expandableRowsOnClick: false,
                expandableRows: false,
            }
        }
        // AccountId: String!
        // AccountName: String!
        // ReportName: String!
        // ReportDate: AWSDate!
        // DownloadUrl: AWSURL
        listImageScanReports().then(reports => {
            this.setState({
                ready: true,
                reportArray: reports.map(report => {
                    return [
                        report.ReportName,
                        report.AccountName,
                        report.AccountId,
                        report.ReportDate
                    ]
                })
            })
        })
    }



    render() {
        if (this.state.ready != true) {
            return (<PageLoader />)
        }
        return (
            <Paper sx={{ minHeight: '800px', pl: 2, pb: 3, pt: 1 }}>
                <div style={{ pb: 3, display: "flex", flexDirection: "row" }}>
                    <Grid container style={{ display: "flex", flexDirection: "row" }}>
                        <Grid item><h1 >Image scan reports </h1></Grid>
                    </Grid>
                </div>

                <MUIDataTable
                    data={this.state.reportArray}
                    sear
                    columns={this.datatable.columns}
                    options={this.datatable.options}
                    xs={12} sm={12} md={12} lg={12} xl={12}
                />
            </Paper>

        )
    }
}

export default ModuleImageScanReports;
