import '@aws-amplify/ui-react/styles.css';
import { Add, Delete, Edit, Save } from '@mui/icons-material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Button, Checkbox, Collapse, Divider, FormControlLabel, Grid, IconButton, MenuItem, OutlinedInput, Paper, Select, Tooltip, Typography } from '@mui/material';
import React, { Component } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SyncLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { PageLoader } from './Loader';
import { getTeam, listApprovalWorkflowsFull, putApprovalWorkflow } from './Queries';

var self = {}

const teamsUpdatedEvent = new Event('cache-teams-updated')

class TeamAccessRequestSettings extends Component {

    constructor(props) {
        super(props);
        this.collapseEdit = this.collapseEdit.bind(this)

        this.accessApprovers = [
            {
                'Id': 'TeamAdministrator',
                'DisplayName': "Team Administrator"
            },
            {
                'Id': 'TeamManager',
                'DisplayName': "Team Manager"
            },
            {
                'Id': 'Auto',
                'DisplayName': "AutoApproval",
                'Hidden': true
            }
        ]

        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        this.menuProps = {
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250,
                },
            },
        }

        var teamId = this.props.router.params.teamId



        this.state = {
            teamId: teamId,
            ready: false,
            workflows: []
        }
        getTeam(teamId).then(currentTeam => {
            this.setState({ currentTeam: currentTeam })
            listApprovalWorkflowsFull(this.state.teamId).then(
                workflows => {
                    workflows.forEach((wf, ix) => {
                        if (wf['Steps'][0]['AccessApprover'] == "Auto") {
                            workflows[ix]['AutoApprove'] = true
                        }
                        if (wf.AllowSelfApproval == undefined && wf.ConfigData) {
                            try{
                                if (JSON.parse(wf['ConfigData']).AccessRequestSelfApprovalAllowed) {
                                    wf.AllowSelfApproval = true
                                }
                            }
                            catch {
                                console.error('Failed to decode configdata of workflow:', wf)
                            }
                            
                        }


                    })
                    var promises = []
                    var results = []


                    workflows = workflows.concat(
                        currentTeam.AvailableRoles.map(o => {
                            if (!(workflows.map(o => { return o.Target }).includes(o.RoleId))) {
                                return {
                                    Type: "Role",
                                    Target: o.RoleId,
                                    ConfigData: "{}",
                                    Steps: [
                                        {
                                            AccessApprover: "TeamAdministrator",
                                            ConfigData: "{}"
                                        }
                                    ]
                                }
                            }
                        })
                    )

                    workflows.sort((a, b) => (a.Target > b.Target) ? 1 : -1)
                    this.setState({ ready: true, workflows: workflows.filter(o => { return o != undefined }) })



                }
            )
        })


    }

    collapseEdit(id) {
        var state = {}
        if (this.state[id + "collapsed"]) {
            state[id + "collapsed"] = false
            this.setState(state)
        }
        else {
            state[id + "collapsed"] = true
            this.setState(state)
        }
    }

    render() {
        if (!this.state.ready) {
            return (
                <PageLoader ready={this.state.ready} />
            )
        }
        return (


            <Paper sx={{ minHeight: '800px', pl: 10, pb: 3, pt: 1 }}>

                <Grid container style={{ marginBottom: 2 }}>
                    <Grid item>
                        <Typography variant="h5" sx={{ marginTop: 4 }}>
                            Team {this.state.currentTeam.TeamName}
                        </Typography>
                    </Grid>
                    <Grid item><Typography variant="h6" mt={5} ml={1}><Edit /></Typography></Grid>
                    <Grid item><Typography variant="h6" mt={4.5} component="div"> &nbsp; Access request settings</Typography></Grid>
                </Grid>

                <Grid mt={4} container spacing={1}>
                    <Grid xs={0.3} item><Typography variant='h6'>Edit</Typography></Grid>
                    <Grid xs={11.7} item><Typography variant='h6'>Application/Permission</Typography></Grid>
                </Grid>
                <Divider sx={{ mb: 3 }} />
                {
                    this.state.workflows.map((workflow, wix) => {
                        console.debug("WF:", workflow)
                        var workflowEdit = workflow.Steps.map((step, ix) => {

                            return (
                                <Grid pl={8} mt={1} container spacing={1}>

                                    <Grid item>
                                        <Typography pt={1.4} variant='h6'>
                                            <IconButton sx={{ paddingBottom: 1.5 }} disabled={workflow.Steps.length == 1 || this.state.workflows[wix].AutoApprove ? true : false}
                                                onClick={e => {
                                                    var workflows = this.state.workflows
                                                    console.debug("Removing access approver on index: ", ix)
                                                    console.debug("IX:", ix, " WIX:", wix)
                                                    var steps = workflows[wix].Steps
                                                    steps.splice(ix, 1)
                                                    workflows[wix].Steps = steps

                                                    this.setState({ workflows: workflows })
                                                }}
                                            ><Delete /></IconButton>
                                            {ix}
                                        </Typography>
                                    </Grid>
                                    <Grid xs={4} item>
                                        <Select
                                            key={"access-approver" + `${wix}-${ix}`}
                                            labelId={"access-approver-label" + `${wix}-${ix}`}
                                            id={"access-approver" + `${wix}-${ix}`}
                                            value={step.AccessApprover}
                                            input={<OutlinedInput label="Name" />}
                                            MenuProps={this.menuProps}
                                            fullWidth={true}
                                            onChange={e => {

                                                var workflows = this.state.workflows

                                                console.debug("IX:", ix, " WIX:", wix)
                                                console.debug("Setting Access Approver to: ", e.target.value)
                                                workflows[wix].Steps[ix].AccessApprover = e.target.value
                                                this.setState({ workflows: workflows })
                                            }}
                                            disabled={this.state.workflows[wix].AutoApprove ? true : false}

                                        >
                                            {this.accessApprovers.map((accessApprover) => (
                                                <MenuItem
                                                    key={accessApprover.Id}
                                                    value={accessApprover.Id}
                                                    hidden={accessApprover.Hidden ? true : false}
                                                    disabled={accessApprover.Hidden ? true : false}
                                                >
                                                    {accessApprover.DisplayName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                </Grid>
                            )
                        })
                        try {
                            var roleDescription = this.state.currentTeam.AvailableRoles.find(o => {
                                return o.RoleId == workflow.Target
                            }).Description
                        }
                        catch {
                            roleDescription = ""
                        }

                        return (
                            [
                                <Grid container mb={2} spacing={1} onClick={e => {
                                    if (!this.state[wix + "collapsed"]) {
                                        this.collapseEdit(wix)
                                    }
                                }}>
                                    <Grid xs={0.3} item>
                                        <IconButton onClick={e => { this.collapseEdit(wix) }}>
                                            <Typography>
                                                {
                                                    this.state[wix + "collapsed"] ? <ArrowDropUpIcon /> : <Edit />
                                                }

                                            </Typography>
                                        </IconButton>
                                    </Grid>
                                    <Grid xs={10} item>
                                        <Typography paddingTop={0.5}>{workflow.Target}</Typography>
                                        <Typography paddingTop={0.5} variant='caption' >
                                            {
                                                roleDescription
                                            }
                                        </Typography>
                                    </Grid>
                                </Grid>,
                                <Collapse in={this.state[wix + "collapsed"] ? true : false}>
                                    <Tooltip title={`Denies self-approval of requests (ie. if a team admin requests an app)`}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox />

                                            }
                                            label="Deny self-approval"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                var temp = this.state.workflows
                                                temp[wix].AllowSelfApproval = this.state.workflows[wix].AllowSelfApproval ? false : true
                                                if (temp[wix].AllowSelfApproval) {
                                                    temp[wix].AutoApprove = false
                                                }
                                                this.setState({ workflows: temp })

                                            }}
                                            checked={this.state.workflows[wix].AllowSelfApproval ? true : false}


                                            sx={{ ml: 4, pt: 1 }}
                                        />
                                    </Tooltip>
                                    <Tooltip title={`All access request from team members in ${this.state.teamId} to this resource are automatically approved`}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox />

                                            }
                                            label="Enable auto approval"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                var temp = this.state.workflows
                                                temp[wix].AutoApprove = this.state.workflows[wix].AutoApprove ? false : true
                                                if (temp[wix].AutoApprove) {
                                                    temp[wix].AllowSelfApproval = false
                                                }
                                                this.setState({ workflows: temp })

                                            }}
                                            checked={this.state.workflows[wix].AutoApprove ? true : false}
                                            sx={{ ml: 4, pt: 1 }}
                                        />
                                    </Tooltip>
                                    <Typography ml={6} pt={1} variant='h6'>Approval steps:</Typography>
                                    {workflowEdit}
                                    <Grid pl={4} mt={1} container spacing={1}>
                                        <Grid item>
                                            <Tooltip title="Add approval step">
                                                <Button
                                                    onClick={e => {
                                                        var workflows = this.state.workflows

                                                        console.debug("Adding approval step")
                                                        workflows[wix].Steps.push(
                                                            Object.assign({}, workflows[wix].Steps[0])
                                                        )

                                                        this.setState({ workflows: workflows })

                                                    }}
                                                    disabled={this.state.workflows[wix].AutoApprove ? true : false}
                                                    sx={{ marginBottom: 2, marginLeft: 6 }} variant='contained' color='info'
                                                >
                                                    <Add />
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid xs={2} item>
                                            <Button
                                                onClick={e => {
                                                    var workflow = this.state.workflows[wix]
                                                    var loadingState = {}
                                                    loadingState[wix + "Working"] = true
                                                    this.setState(loadingState)

                                                    if (workflow.AutoApprove ? true : false) {
                                                        workflow.Steps = [
                                                            {
                                                                AccessApprover: 'Auto',
                                                                ConfigData: "{}"
                                                            }
                                                        ]
                                                    }

                                                    var configData = {
                                                        AccessRequestSelfApprovalAllowed: workflow.AllowSelfApproval ? true : false
                                                    }

                                                    var promise = putApprovalWorkflow(workflow.Target, workflow.Steps, this.state.teamId, configData)
                                                        .then(updatedWorkflow => {
                                                            updatedWorkflow.AutoApprove = workflow.AutoApprove
                                                            updatedWorkflow.AllowSelfApproval = workflow.AllowSelfApproval
                                                            var temp = this.state.workflows
                                                            temp[wix] = updatedWorkflow

                                                            this.setState({ workflows: temp })

                                                            loadingState[wix + "Working"] = false
                                                            this.setState(loadingState)

                                                        })

                                                    toast.promise(
                                                        promise,
                                                        {
                                                            pending: `Updating approval workflow for ${workflow.Target}`,
                                                            error: `Failed updating approval workflow for ${workflow.Target}`,
                                                            success: `Workflow for ${workflow.Target} updated!`,
                                                        }
                                                    )
                                                    promise.catch(err => {
                                                        loadingState[wix + "Working"] = false
                                                    })

                                                }}
                                                sx={{ marginBottom: 5, marginLeft: 6, height: '4em' }}
                                                fullWidth={true}
                                                variant='contained'
                                                color='success'
                                                disabled={this.state[wix + "Working"] ? true : false}
                                            >
                                                <Typography>
                                                    <SyncLoader sx={{ width: '100%' }} loading={this.state[wix + "Working"] ? true : false} color={'#e0e0e0'} />
                                                    <Save sx={{ visibility: this.state[wix + "Working"] ? 'hidden' : 'unset' }} />
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Collapse>,
                                <Grid container mb={2} spacing={1}>

                                    <Grid xs={12} item><Divider /></Grid>
                                </Grid>
                            ]
                        )
                    })


                }


            </Paper >

        )

    }
}



function withRouter(C) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <C
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}


TeamAccessRequestSettings = withRouter(TeamAccessRequestSettings)

export { TeamAccessRequestSettings };
