
import React, { Component, useState } from 'react';
import '@aws-amplify/ui-react/styles.css';
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, FormGroup, Grid, IconButton, MenuItem, Paper, Select, Tab, Tabs, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import MUIDataTable from "mui-datatables";
import { ArrowForward, ArrowRight, GppGood, Info, Warning } from '@mui/icons-material';
import { PageLoader } from './Loader';
import { toast } from 'react-toastify';
import { Checkbox } from '@mui/material';
import { getOktaUser, getTeam, invokeAuditReview, listAuditEntries, updateAuditEntries } from './Queries';
import { RiseLoader } from 'react-spinners';
import { oktaAuth } from '../Components/Auth';
import { NavLink, useParams, useSearchParams, useLocation, useNavigate } from "react-router-dom"

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 440,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

class AuditReview extends Component {

    constructor(props) {
        super(props);
        this.approveEntry = this.approveEntry.bind(this)
        this.denyEntry = this.denyEntry.bind(this)
        this.handleTabChange = this.handleTabChange.bind(this)
        this.updateEntries = this.updateEntries.bind(this)
        this.getTabIxName = this.getTabIxName.bind(this)
        this.getTabs = this.getTabs.bind(this)
        this.handleDialogOk = this.handleDialogOk.bind(this)
        this.handleDialogClose = this.handleDialogClose.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.isSelected = this.isSelected.bind(this)

        this.colwidth = [3, 2, 3]
        this.state = {
            ready: false,
            entries: [],
            entryStates: localStorage.auditEntries ? JSON.parse(localStorage.auditEntries) : {},
            currentTab: 0
        }
    }

    componentDidMount() {
        this.updateEntries()
        this.keyUpListener = document.addEventListener('keyup', this.handleKeyPress, false)
    }
    componentWillUnmount() {
        document.removeEventListener('keyup', this.handleKeyPress, false)
    }

    handleKeyPress = (event) => {
        event.stopPropagation()
        console.debug(event)
        if ((event.key == 'a' || event.key == 'd')) {
            var entries = this.state.entries.filter(
                (o) => {
                    if (this.state.entryStates[o.id]) {
                        return false
                    }
                    if (this.state.hideMarkedEntries && this.state.entryStates[o.id]) {
                        return false
                    }
                    if (this.state.currentTab == 0) {
                        return true
                    }
                    return o.TeamId == this.getTabIxName(this.state.currentTab)
                }
            )

            var entry = entries.filter(o => {
                if (this.state.entryFilter) {
                    var filter = this.state.entryFilter.split(":")
                    return o[filter[0]] == filter[1]
                }
                return true
            })[0]

            if (entry) {
                if (event.key == 'a') {
                    this.approveEntry(entry.id)
                }
                if (event.key == 'd') {
                    this.denyEntry(entry.id)
                }

                const element = document.getElementById(entry.id);
                if (element) {
                    // 👇 Will scroll smoothly to the top of the next section
                    // element.scrollIntoView({ behavior: 'smooth' });
                }
            }


        }
    }
    updateEntries() {
        listAuditEntries({
            'and': [
                { 'Action': { 'ne': 'RemoveOrDisable' } },
                { 'Action': { 'ne': 'Keep' } }
            ]
        }).then(auditEntries => {
            var teamList = auditEntries.map(o => { return o.TeamId }).filter(this.onlyUnique)
            teamList.sort()
            this.setState(

                {
                    teamList: teamList,
                    entries: auditEntries,
                    entryStates: localStorage.auditEntries ? JSON.parse(localStorage.auditEntries) : {},
                    ready: true
                }
            )
        })




    }

    approveEntry(id, markIdentical = true) {
        var auditEntries = localStorage.auditEntries ? JSON.parse(localStorage.auditEntries) : {}

        auditEntries[id] = auditEntries[id] == 'approved' ? undefined : 'approved'
        this.setState({ entryStates: auditEntries })
        localStorage.auditEntries = JSON.stringify(auditEntries)

        if (!auditEntries[id]) {
            return
        }

        if (markIdentical) {
            var entry = this.state.entries.find(o => { return o.id == id })
            this.state.entries.filter(o => {
                return o.id != entry.id && o.Type == entry.Type && o.Username == entry.Username && o.TargetId == entry.TargetId
            }).forEach(o => {
                if (auditEntries[o.id] != auditEntries[id] && auditEntries[id] != undefined) {
                    this.approveEntry(o.id, false)
                    toast.info("Found an identical review item in team: " + o.TeamId + '. Copying selection...')
                }

            })
        }


    }

    denyEntry(id, markIdentical = true) {
        var auditEntries = localStorage.auditEntries ? JSON.parse(localStorage.auditEntries) : {}
        auditEntries[id] = auditEntries[id] == 'denied' ? undefined : 'denied'
        this.setState({ entryStates: auditEntries })
        localStorage.auditEntries = JSON.stringify(auditEntries)

        if (!auditEntries[id]) {
            return
        }



        if (markIdentical) {
            var entry = this.state.entries.find(o => { return o.id == id })
            this.state.entries.filter(o => {
                return o.id != entry.id && o.Type == entry.Type && o.Username == entry.Username && o.TargetId == entry.TargetId
            }).forEach(o => {
                if (auditEntries[o.id] != auditEntries[id] && auditEntries[id] != undefined) {
                    this.denyEntry(o.id, false)
                    toast.info("Found an identical review item in team: " + o.TeamId + '. Copying selection...')
                }
            })
        }

        if (this.state.entries.find(o => { return o.id == id }).Type == 'UserInactivity' && markIdentical) {
            var entry = this.state.entries.find(o => { return o.id == id })
            this.state.entries.filter(o => {
                return o.id != entry.id && o.Type != 'UserInactivity' && o.Username == entry.Username && auditEntries[o.id] != "denied"
            }).forEach(o => {
                this.denyEntry(o.id, false)
                toast.info("Automatically denying " + o.TargetId + ' for ' + o.Username + ' since user is getting disabled')
            })
        }


    }

    getEntryState(id) {
        var auditEntries = localStorage.auditEntries ? JSON.parse(localStorage.auditEntries) : {}
        return auditEntries[id] ? auditEntries[id] : 'none'
    }

    handleTabChange(value) {
        this.setState({
            currentTab: value
        })


    }
    tabProps(ix) {
        return {
            id: `audit-tab-${ix}`,
            'aria-controls': `audit-tabpanel-${ix}`,
            sx: { bgcolor: this.state.currentTab == ix ? "#3d3d3d" : (!this.state.currentTab && ix == 0) ? "#3d3d3d" : null }
        };
    }
    getTabIxName(ix) {
        if (ix == 0) {
            return "All"
        }

        return this.state.teamList[ix - 1]
    }

    getTabs() {
        var tabs = [<Tab label="All" {...this.tabProps(0)} />]
        this.state.teamList.forEach((val, ix) => {
            tabs.push(
                <Tab label={val} {...this.tabProps(ix + 1)} />
            )

        })
        return tabs
    }

    onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    getPalette(ix) {
        var palette = [
            '#f57c00',
            '#29b6f6',
            '#ce93d8',
            '#90caf9',
            '#e57373',
            '#42a5f5',
            '#f3e5f5',
            '#ffb74d',
            '#81c784',
            '#66bb6a',
            '#388e3c',
            '#f44336',
            '#e3f2fd',
            '#ab47bc',
            '#0288d1',
            '#4fc3f7'
        ]
        if (ix > palette.length) {
            return "#388e3c"
        }
        return palette[ix]
    }
    handleDialogClose(event) {
        this.setState({ dialogOpen: false })

    }
    handleDialogOk(event) {
        this.setState({ working: true })
        updateAuditEntries(
            this.state.entries.filter(o => { return this.state.entryStates[o.id] ? true : false }).map(entry => {
                return {
                    id: entry.id,
                    keep: this.state.entryStates[entry.id] == 'approved'
                }
            })
        ).then(o => {
            this.updateEntries()
            this.setState({ working: false })
        }).catch(err => {
            console.error(err)
            this.handleDialogClose(event)
        })


    }

    isSelected(id) {

        var entry = this.state.entries.find(
            (o) => {
                if (this.state.entryStates[o.id]) {
                    return false
                }
                if (this.state.currentTab == 0) {
                    return true
                }
                return o.TeamId == this.getTabIxName(this.state.currentTab)
            }
        )
        if (entry) {
            if (entry.id == id) {
                return true
            }
        }
        return (
            false
        )


    }
    render() {

        if (!this.state.ready) {
            return (<PageLoader />)
        }

        return (
            <Paper sx={{ padding: 2 }}>
                <Dialog open={this.state.dialogOpen} minWidth={"50%"} maxWidth={"50%"} onClose={this.handleDialogClose}>
                    <DialogTitle>Review audit entries</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {
                                this.state.entries.filter(o => { return this.state.entryStates[o.id] ? true : false }).length > 0 ?
                                    "Review audited entries before submiting them for fullfilment" :
                                    "No entries selected - please select entries before submitting."
                            }

                        </DialogContentText>

                        <Grid pt={1} bgcolor={"rgba(0, 0, 0, 0.6)"} marginTop={1} maxWidth={"1200px"} minWidth={"1200px"} paddingBottom={2} paddingLeft={2} container>
                            <Grid xs={4} item>
                                <Typography variant='h6'><b>Username</b></Typography>
                            </Grid>
                            <Grid xs={8} item>
                                <Typography variant='h6'><b>Action</b></Typography>
                            </Grid>
                        </Grid>
                        {
                            this.state.entries.sort((a, b) => { return a.Username < b.Username }).filter(
                                (o) => {
                                    if (this.state.currentTab == 0 && this.state.entryStates[o.id]) {
                                        return true
                                    }
                                    return o.TeamId == this.getTabIxName(this.state.currentTab) && this.state.entryStates[o.id]
                                }
                            ).map((entry, ix) => {

                                if (entry.Type == 'UserInactivity') {
                                    var message = this.state.entryStates[entry.id] == 'approved' ?
                                        <Typography>Keep user enabled</Typography> :
                                        <Typography>Disable user</Typography>
                                }
                                else {
                                    var message = this.state.entryStates[entry.id] == 'approved' ?
                                        <Typography>Keep <em>{entry['TargetId']}</em></Typography> :
                                        <Typography>Remove <em>{entry['TargetId']}</em></Typography>
                                }


                                return (
                                    <Grid bgcolor={ix % 2 ? "rgba(0, 0, 0, 0.6)" : null} marginTop={1} maxWidth={"1200px"} minWidth={"1200px"} paddingLeft={2} py={1} container>
                                        <Grid xs={4} item>
                                            {entry.Username}
                                        </Grid>
                                        <Grid xs={8} item>
                                            <Chip sx={{ px: 0, py: 0, mx: 0, my: 0, bgcolor: this.state.entryStates[entry.id] == 'approved' ? '#81c784' : '#e57373' }} label={message} />

                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                    </DialogContent>
                    <DialogActions>
                        <Grid disabled={this.state.working} alignItems="center" px={4} minHeight={'100px'} container>
                            <Grid item>
                                <Button
                                    onClick={this.handleDialogClose}
                                    sx={{ minHeight: '100px', minWidth: '100%' }}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid marginLeft={'auto'} xs={4} item >
                                <Button
                                    disabled={this.state.working || (this.state.entries.filter(o => { return this.state.entryStates[o.id] ? true : false }).length == 0)}
                                    onClick={this.handleDialogOk}
                                    sx={{ minHeight: '100px', minWidth: '100%' }}
                                    variant='contained'
                                >
                                    <GppGood />
                                    {
                                        this.state.working ?
                                            <RiseLoader loading={this.state.working} /> :
                                            "Submit"



                                    }

                                </Button>
                            </Grid>
                        </Grid>


                    </DialogActions>
                </Dialog>
                <Typography variant='h2'>User access and permission audit</Typography>
                {
                    Object.keys(this.state.entryStates).filter(o => {
                        return this.state.entryStates[o] && this.state.entries.map(e => { return e.id }).includes(o)
                    }).length > 0 ?
                        <Grid my={2} pl={2} py={2} bgcolor={'#ffb74d'} container>

                            <Grid item>
                                <Warning color='error' />

                            </Grid>
                            <Grid item>
                                <Typography marginLeft={2} >
                                    You have &nbsp;
                                    <b>
                                        {
                                            Object.keys(this.state.entryStates).filter(o => {
                                                return this.state.entryStates[o] && this.state.entries.map(e => { return e.id }).includes(o)
                                            }).length
                                        }
                                    </b>
                                    &nbsp;
                                    unsubmitted entries. You need to submit the audit entry to complete it. All selected actions are saved in your browser.

                                </Typography>
                            </Grid>
                        </Grid> :
                        null
                }

                <Box mt={3} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Grid container>
                        <Grid xs={1} item>

                        </Grid>
                        <Grid xs={1} item>

                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid xs={12} item>
                            <Select
                                //labelId="demo-simple-select-label"
                                //id="demo-simple-select"
                                value={!this.state.entryFilter ? "none" : this.state.entryFilter}
                                //label="Age"
                                onChange={
                                    (event) => {

                                        this.setState({ entryFilter: event.target.value == "none" ? undefined : event.target.value })
                                    }
                                }





                            >
                                <MenuItem value="none">
                                    Filter by...
                                </MenuItem>
                                <Divider />
                                <MenuItem disabled><b>Application:</b></MenuItem>


                                {
                                    [...new Set(this.state.entries.filter(o => { return o.Type != "UserInactivity" }).map((item) => item.TargetId))].map(e => {

                                        return (<MenuItem value={['TargetId', e].join(":")}>{e}</MenuItem>)
                                    })

                                }
                                <Divider />
                                <MenuItem disabled><b>Type:</b></MenuItem>
                                <MenuItem sx={{ bgcolor: '#3d3d3d' }} value="Type:UserInactivity">
                                    Inactive users
                                </MenuItem>
                                <Divider />

                            </Select>

                        </Grid>
                        <Grid xs={12} item>



                            <Checkbox
                                value={this.state.hideMarkedEntries}
                                onClick={event => {
                                    this.setState({
                                        hideMarkedEntries: !this.state.hideMarkedEntries
                                    })
                                }}
                            />
                            <em>Hide entries with choosen action</em>
                        </Grid>
                    </Grid>

                    <Tabs value={this.state.currentTab ? this.state.currentTab : 0} onChange={(event, value) => this.handleTabChange(value)} aria-label="Audit Review Tab">

                        {
                            this.getTabs()
                        }
                    </Tabs>

                </Box>

                <Grid bgcolor='#3d3d3d' container>
                    <Grid xs={0.5} item>

                    </Grid>
                    <Grid xs={this.colwidth[0]} item>
                        <Typography variant='h6'>Username</Typography>
                    </Grid>
                    <Grid xs={this.colwidth[1]} item>

                    </Grid>
                    <Grid mr={13} xs={2} item>
                    </Grid>
                    <Grid item>
                        <Grid container>
                            <Grid xs={12} item>
                                <Typography variant='caption'>
                                    Keep hotkey: A
                                </Typography>
                            </Grid>
                            <Grid xs={12} item>
                                <Typography variant='caption'>
                                    Remove/Disable hotkey: D
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    this.state.entries.filter(
                        (o) => {
                            if (this.state.hideMarkedEntries && this.state.entryStates[o.id]) {
                                return false
                            }
                            if (this.state.currentTab == 0) {
                                return true
                            }
                            return o.TeamId == this.getTabIxName(this.state.currentTab)
                        }
                    ).filter(o => {
                        if (this.state.entryFilter) {
                            var filter = this.state.entryFilter.split(":")
                            return o[filter[0]] == filter[1]
                        }
                        return true
                    }).map((entry, row_ix) => {

                        return (
                            [
                                <Grid
                                    key={entry.id}
                                    id={entry.id}
                                    borderBottom={1}
                                    sx={{
                                        borderBottomColor: "#3d3d3d",
                                        bgcolor: this.isSelected(entry.id) ? '#636363' : row_ix % 2 ? '#3d3d3d' : null
                                    }}
                                    spacing={1}
                                    paddingY={0.5}
                                    minHeight={'7.5em'}
                                    container
                                >
                                    <Grid xs={0.5} item>
                                        <HtmlTooltip
                                            title={
                                                <React.Fragment>
                                                    <Typography color="inherit">{entry.Username} </Typography>
                                                    {
                                                        entry.Type == 'UserInactivity' ?
                                                            <div>><em>Inactive user</em><br /></div> :
                                                            <div><em>{entry.TargetId}</em><br /></div>


                                                    }


                                                    <b>Audit Entry ID: </b>{entry.id}<br />
                                                    <b>Audit Type: </b>{entry.Type}<br />
                                                    <b>TeamID: </b>{entry.TeamId}<br />
                                                    <b>Created: </b>{entry.createdAt}<br />
                                                    <b>DueDate: </b>{entry.DueDate}<br />

                                                    <b>Message: </b>{entry.Message ? entry.Message : "None"}<br />

                                                </React.Fragment>
                                            }
                                        >
                                            <IconButton sx={{ marginTop: 2 }}>
                                                <Info />
                                            </IconButton>

                                        </HtmlTooltip>


                                    </Grid>
                                    <Grid xs={3} item>
                                        {entry.Username}
                                        <br />
                                        <Chip sx={{ bgcolor: this.getPalette(this.state.teamList.indexOf(entry.TeamId)) }} label={entry.TeamId} />

                                    </Grid>


                                    {
                                        ['ScheduledAccessReview', 'UserInitiatedAccessReview'].includes(entry.Type) ?
                                            [
                                                <Grid marginTop={2} item>

                                                    <Chip label={<div>Access to </div>} />
                                                </Grid>,
                                                <Grid marginTop={2} item>
                                                    <ArrowForward sx={{ mt: 1 }} />
                                                </Grid>,
                                                <Grid marginTop={2} style={{ flexGrow: "1" }} item>
                                                    <Chip label={entry.TargetId} />
                                                </Grid>,
                                                <Grid marginLeft={3} xs={3} item>
                                                    <Button

                                                        //disabled={this.state.entryStates[entry.id] == 'approved'}
                                                        color={this.state.entryStates[entry.id] == 'approved' ? 'success' : 'inherit'}
                                                        variant={this.state.entryStates[entry.id] == 'approved' ? 'contained' : 'outlined'}
                                                        sx={{ mr: 1, minHeight: "6.5em" }}

                                                        onClick={(event) => {
                                                            this.approveEntry(entry.id)
                                                            this.forceUpdate()
                                                        }}
                                                    >
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Keep&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </Button>
                                                    <Button
                                                        //disabled={this.state.entryStates[entry.id] == 'denied'}
                                                        sx={{ mr: 1, minHeight: "6.5em" }}
                                                        color={this.state.entryStates[entry.id] == 'denied' ? 'error' : 'inherit'}
                                                        variant={this.state.entryStates[entry.id] == 'denied' ? 'contained' : 'outlined'}
                                                        onClick={(event) => {
                                                            this.denyEntry(entry.id)
                                                            this.forceUpdate()
                                                        }}
                                                    >
                                                        &nbsp;&nbsp;&nbsp;Remove&nbsp;&nbsp;&nbsp;
                                                    </Button>
                                                </Grid>
                                            ]
                                            : null
                                    }

                                    {
                                        entry.Type == 'UserInactivity' ?
                                            [
                                                <Grid marginTop={2} style={{ flexGrow: "1" }} item>

                                                    <Chip label={<div>{entry.Message}</div>} />
                                                </Grid>,
                                                <Grid marginLeft={2} xs={3} item>
                                                    <Button
                                                        //disabled={this.state.entryStates[entry.id] == 'approved'}
                                                        color={this.state.entryStates[entry.id] == 'approved' ? 'success' : 'inherit'}
                                                        variant={this.state.entryStates[entry.id] == 'approved' ? 'contained' : 'outlined'}
                                                        sx={{ mr: 1, minHeight: "6.5em" }}
                                                        onClick={(event) => {
                                                            this.approveEntry(entry.id)
                                                            this.forceUpdate()
                                                        }}
                                                    >
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Keep&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </Button>
                                                    <Button
                                                        //disabled={this.state.entryStates[entry.id] == 'denied'}
                                                        sx={{ mr: 1, minHeight: "6.5em" }}
                                                        color={this.state.entryStates[entry.id] == 'denied' ? 'error' : 'inherit'}
                                                        variant={this.state.entryStates[entry.id] == 'denied' ? 'contained' : 'outlined'}
                                                        onClick={(event) => {
                                                            this.denyEntry(entry.id)
                                                            this.forceUpdate()
                                                        }}
                                                    >
                                                        Deactivate
                                                    </Button>
                                                </Grid>
                                            ]
                                            : null

                                    }

                                </Grid>


                            ]
                        )
                    })
                }
                <Grid mt={4} container>
                    <Grid item>
                        <Button
                            variant='contained'
                            color='success'
                            sx={{ px: 1, py: 1 }}
                            onClick={(event) => { this.setState({ dialogOpen: true }) }}

                        >
                            Review & Submit audit
                        </Button>
                    </Grid>
                </Grid>
            </Paper >
        );
    }
}



class AuditView extends Component {

    constructor(props) {
        super(props);
        this.updateEntries = this.updateEntries.bind(this)

        this.state = {
            auditsEntries: []
        }


        this.datatable = {
            columns: [
                { name: "Team", options: { filter: true, sort: true } },
                { name: "Type", options: { filter: true, sort: true } },
                { name: "Username", options: { filter: true, sort: true } },
                { name: "Action", options: { filter: true, sort: true } },
                { name: "Target", options: { filter: true, sort: true } },
                { name: "Auditor", options: { filter: true, sort: true } },
                { name: "Date", options: { filter: true, sort: true } },


            ],

            options: {
                rowsPerPage: 100,
                rowsPerPageOptions: [50, 100, 200, 400, 500, 1000],
                filter: true,
                download: false,
                print: false,
                filterType: 'dropdown',
                responsive: 'standard',
                selectableRows: 'none',
                expandableRowsOnClick: false,
                expandableRows: false,
                download: true,
                onDownload: (buildHead, buildBody, columns, data) => {
                    return "\uFEFF" + buildHead(columns) + buildBody(data);
                },
                downloadOptions: {
                    filename: "4SP-Export-AuditEntries.csv"
                },


            }

        }

    }

    componentDidMount() {
        this.updateEntries()
    }


    updateEntries() {
        listAuditEntries().then(auditEntries => {
            this.setState(
                {
                    auditEntriesArray: auditEntries.map(o => {
                        return [
                            o.TeamId,
                            o.Type,
                            o.Username,
                            o.Action,
                            o.TargetId,
                            o.lastModifiedById,
                            o.ActionDate
                        ]
                    }),
                    auditEntries: auditEntries,
                    ready: true
                }
            )
        })
    }


    render() {

        if (!this.state.ready) {
            return (<PageLoader ready={false} />)
        }
        return (
            <Paper sx={{ pl: 3 }}>
                <Typography variant='h2'>Performed audits</Typography>
                <div style={{ display: 'table', tableLayout: 'fixed', width: '100%' }}>
                    <MUIDataTable
                        data={this.state.auditEntriesArray}
                        columns={this.datatable.columns}
                        options={this.datatable.options}
                    />
                </div>
            </Paper>

        );
    }
}

class AuditLaunch extends Component {

    constructor(props) {
        super(props);

        this.handleDialogClose = this.handleDialogClose.bind(this)
        this.handleDialogLaunch = this.handleDialogLaunch.bind(this)
        this.getMyTeams = this.getMyTeams.bind(this)
        var teamSettings = JSON.parse(localStorage.getItem('4sp-teamsettings'))
        this.state = {
            ready: false,
            managedTeams: [],
            selectedTeams: [],
            teamSettings: teamSettings ? teamSettings : {}
        }



    }
    getMyTeams() {
        getOktaUser().then(user => {

            user.groups.filter(o => {
                return o.match(
                    RegExp('^Team-[^-]+-(Manager|Administrator)$')
                ) != undefined
            }).map(o => {

                var p = o.split("-")
                return p[0] + "-" + p[1]

            }).filter((value, index, self) => {

                return self.indexOf(value) === index;

            }).forEach(teamId => {
                this.setState(previousState => ({
                    managedTeams: [...previousState.managedTeams, teamId]
                }));

            })
            this.setState({ ready: true })


        })
    }
    componentDidMount() {
        this.getMyTeams()
    }
    componentWillUnmount() {

    }


    handleDialogClose(event) {
        this.setState({ dialogOpen: false })

    }

    async handleDialogLaunch() {
        this.setState({ working: true })
        var promises = []
        console.debug(this.state.selectedTeams)
        this.state.selectedTeams.forEach(teamId => {
            var promise = invokeAuditReview(teamId)
            toast.promise(
                promise,
                {
                    pending: `Starting audit review for ${teamId}`,
                    error: `Error starting audit review for ${teamId}`,
                    success: `Audit review successfully launched for ${teamId}, please reload the page.`,
                }
            )
            promises.push(promise)

        })


        Promise.all(promises).then(o => { this.setState({ working: false, success: true }) }).catch(err => {
            console.error(err)
            this.setState({ working: false })
        })

    }

    render() {

        if (!this.state.ready) {
            return (<PageLoader />)
        }

        return (

            <Dialog open={true} minWidth={"50%"} maxWidth={"50%"} onClose={this.handleDialogClose}>
                <DialogTitle>Launch audit for teams</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Select the teams that you wish to start an audit review for.
                    </DialogContentText>
                    <FormGroup>
                        {
                            this.state.managedTeams.map(team => {
                                return (
                                    <FormControlLabel
                                        control={
                                            <Checkbox value={this.state.selectedTeams.includes(team)}
                                                disabled={
                                                    this.state.teamSettings[team] ? !this.state.teamSettings[team]['AuditReviewEnabled'] : false
                                                }
                                                onClick={(e) => {
                                                    if (this.state.selectedTeams.includes(team) && !e.target.checked) {
                                                        this.setState({ selectedTeams: this.state.selectedTeams.filter(o => { return (o != team) }) })
                                                    }
                                                    if (!this.state.selectedTeams.includes(team) && e.target.checked) {
                                                        this.setState(previousState => ({
                                                            selectedTeams: [...previousState.selectedTeams, team]
                                                        }));
                                                    }
                                                }}

                                            />}
                                        label={team + (this.state.teamSettings[team]['AuditReviewEnabled'] ? "" : " (Not enabled for this team)")}
                                    />
                                )
                            })
                        }
                    </FormGroup>


                </DialogContent>
                <DialogActions>

                    <Button
                        onClick={(e) => { window.history.back() }}
                    >
                        Back
                    </Button>
                    {
                        this.state.success ?
                            <Button component={NavLink} to={'/audit/review'} >
                                Review access
                            </Button> :
                            <Button
                                onClick={(event) => {
                                    console.debug(this.state.selectedTeams)
                                    this.handleDialogLaunch()
                                }}
                                disabled={
                                    this.state.selectedTeams.length == 0 || this.state.working
                                }
                            >
                                Launch
                            </Button>
                    }


                </DialogActions>
            </Dialog>
        );
    }
}
function withRouter(C) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <C
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}
AuditReview = withRouter(AuditReview)
AuditView = withRouter(AuditView)
AuditLaunch = withRouter(AuditLaunch)

export { AuditReview, AuditView, AuditLaunch }