import { Amplify } from "aws-amplify";
import React, { Component } from "react";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "../amplifyconfiguration";
import { Box, Container, TextField, Typography } from "@mui/material";
import { Button } from "@mui/material";
import { Paper } from "@mui/material";
import { Grid } from "@aws-amplify/ui-react";
import DoneIcon from "@mui/icons-material/Done";
import { whitelistIp } from "../Components/Queries";
import { RiseLoader } from "react-spinners";
import { toast } from "react-toastify";
import { PageLoader } from "../Components/Loader";
import { Warning } from "@mui/icons-material";
Amplify.configure(awsExports);

class WhitelistIp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            count: 0,
            success: false,
            working: false,
            failed: false,
            errorMsg: "",
            ready: false,
            domainResolvedIp: undefined,
            publicResolvedIp: undefined,
        };

        var promises = [];
        promises.push(
            fetch("https://ssp.tools.dev.assaabloyglobalsolutions.net/myIp")
                .then((request) => request.json())
                .then((data) => {
                    this.setState({
                        domainResolvedIp: data.IpAddress,
                        selectedIp: data.IpAddress,
                    });
                })
        );

        promises.push(
            fetch("https://api.ipify.org?format=json")
                .then((request) => request.json())
                .then((data) => {
                    this.setState({ publicResolvedIp: data.ip });
                })
                .catch((err) => {
                    console.error(err);
                    this.setState({ publicResolvedIp: undefined });
                })
        );

        Promise.all(promises).then((o) => {
            this.setState({ ready: true });
        });
    }
    async clickHandler() {
        this.setState({ working: true });

        var promise = whitelistIp(this.state.selectedIp, false)
            .then((result) => {
                this.setState({ working: false, success: true });
                console.debug(result);
            })
            .catch((err) => {
                this.setState({
                    working: false,
                    success: false,
                    failed: true,
                    errorMsg: err['errors'][0].message,
                })

                throw(err)
            
            })

        toast.promise(promise, {
            pending: "Whitelisting your IP - this might take a minute",
            error: "Whitelisting failed - See info below",
            success: "IP was whitelisted successfully!",
        });
    }

    render() {
        if (!this.state.ready) {
            return <PageLoader ready={this.state.ready} />;
        }
        return (
            <Paper sx={{ pt: 2, pb: 10, pl: 2 }}>
                <h1>Whitelist IP</h1>
                <p>
                    Whitelist your IP-address for accessing certain Global Solutions
                    services
                </p>
                {this.state.failed ? (
                    <Box py={2} mx={3} my={3} bgcolor={"yellow"} color="black">
                        <Typography paddingLeft={2}>
                            {
                                this.state.errorMsg ? this.state.errorMsg : "An error occured while sending the request - please try again"
                            }
                        </Typography>
                    </Box>
                ) : null}

                <Grid container style={{ display: "flex", flexDirection: "row" }}>
                    <Grid item>
                        <Button
                            variant="contained"
                            disabled={this.state.working || this.state.ipInputError}
                            color={
                                this.state.success
                                    ? "success"
                                    : this.state.failed
                                        ? "warning"
                                        : "primary"
                            }
                            onClick={() => this.clickHandler()}
                            sx={{ width: "20em", height: "7em" }}
                        >
                            <RiseLoader loading={this.state.working} />
                            {this.state.working ? null : "Whitelist my IP"}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Paper sx={{ px: 6, pt: 2 }}>
                            {this.state.publicResolvedIp != undefined &&
                                this.state.domainResolvedIp != this.state.publicResolvedIp ? (
                                <Typography color={"yellow"} variant="caption" width={"100%"}>
                                    <Warning /> Split Tunnel detected - Will auto select IP{" "}
                                    {this.state.domainResolvedIp} instead of{" "}
                                    {this.state.publicResolvedIp}
                                </Typography>
                            ) : null}
                            <TextField
                                error={this.state.ipInputError}
                                label={
                                    this.state.ipChanged ? "User defined IP:" : "Detected IP:"
                                }
                                defaultValue={this.state.domainResolvedIp}
                                variant="filled"
                                fullWidth
                                helperText={
                                    this.state.ipInputError
                                        ? "Not a valid ip address"
                                        : "Change only if the auto detected ip doesn't work."
                                }
                                onChange={(e) => {
                                    var is_ip =
                                        e.target.value.match(
                                            /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/
                                        ) != undefined;
                                    console.debug(is_ip);
                                    this.setState({
                                        ipInputError: is_ip == false,
                                        ipChanged: true,
                                    });
                                    if (is_ip) {
                                        this.setState({
                                            selectedIp: e.target.value,
                                        });
                                    }
                                }}
                            />
                        </Paper>
                    </Grid>
                </Grid>

                <div id="successInfo" hidden={this.state.success == false}>
                    <h3>
                        <DoneIcon /> IP added to whitelist
                    </h3>
                </div>
            </Paper>
        );
    }
}

export default WhitelistIp;
