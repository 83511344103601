import { Amplify, Authenticator, Logger } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import { AddUser, TeamAdministration } from './Modules/TeamAdministration';
import { TeamSettings } from './Components/TeamSettings'
import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes,
    Redirect

} from "react-router-dom";



import { ListModules, modules } from './Components/Modules'
import { Button, MenuList, Paper } from '@mui/material';
import { listUsers, listTeams } from "./Components/Queries"
import Home from './Dashboards/Home';
import { SecureRoute, Security, LoginCallback } from '@okta/okta-react';
import PrivateRoute from './Components/PrivateRoute';
import { RequestAccess, ApproveRequests, MyRequests } from './Components/AccessRequest';
import { ManageRoles, AddRole } from './Components/RoleManagement';
import { oktaAuth, oktaConfig } from "./Components/Auth"
import { ManageTeamUsers } from './Components/ManageTeamUsers';
import { TeamAccessRequestSettings } from './Components/TeamAccessRequestSettings';
import { EventRegister } from 'react-native-event-listeners';
import { ContactForm } from './Components/ContactForm';
import { ManageBillingEntities, ManageAnnouncements, ManageAppsAndRoles, ManageAwsAccounts, ManageBusinessAreas, ManageTeams, ManageUsers, UserReports, ManagePriceList } from './Components/Admin';

import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import { AuditLaunch, AuditReview, AuditView } from './Components/Audit';
import MyAccount from './Components/MyAccount';

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <Component
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}

export const isAuthenticated = async () => {
    try {
        return await oktaAuth.isAuthenticated()
    } catch (error) {
        return false;
    }
};

function DirectSignIn() {
    localStorage.lastLoginRedirect = (new Date()).getTime()
    oktaAuth.signInWithRedirect({
        redirectUri: oktaConfig.redirectUri,
        responseType: 'id_token'
    })

    return (
        <div>Signing in</div>
    )
}

function handleSignIn(loginFunction) {
    var lastSignIn = localStorage['lastLoginRedirect'] || 0
    var currentDate = new Date
    if ((currentDate.getTime() - (lastSignIn / 1000)) > 300) {
        loginFunction()
    }
}

function MinimalRoutes(props) {
    var lastLocation = "/"

    var currentTime = Math.round(new Date().getTime())
    var lastLoginAttempt = localStorage.lastLoginRedirect ? localStorage.lastLoginRedirect : 0
    var loginThreshold = (
        currentTime - lastLoginAttempt > (30 * 1000)
    )
    console.debug(currentTime)
    console.debug(lastLoginAttempt)
    console.debug(loginThreshold)

    if (!['/login', '/login/callback'].includes(window.location.pathname)) {
        window.location.pathname
    }
    localStorage['4sp-cache-last-uri'] = lastLocation

    const routes = (
        <Routes>

            <Route key="2001" path='/' element={<Navigate to={"/login"} />}>
            </Route>
            <Route key="2002" exact path={"/signedOut"} element={
                <div>
                    {
                        //handleSignIn(props.parent.login())
                    }
                    <h1>You have been signed out</h1><Button variant="contained" onClick={event => { window.location = "/login" }}  >Sign back in</Button>
                </div>
            } />
            {
                loginThreshold ?
                    <Route key="2003-1" path='/login' element={<DirectSignIn />} /> :
                    <Route key="2003" path='/login' element={
                        <div>
                            <Button variant="contained" onClick={(event) => props.parent.login()} >Too short duration since last login attempt <br /> Click the button to sign in </Button>
                        </div>
                    } />
            }

            <Route key="2004" path='/login/callback' component={LoginCallback} element={<LoginCallback></LoginCallback>} />
            <Route key="2005" path='*' element={<Navigate to={"/login"} />}></Route>

        </Routes>

    )
    return routes
}


class GetRoutes extends Component {
    constructor(props) {
        super(props)
        this.listItems = modules.map((item, index) =>
            <Route key={1000 + index} exact path={`/modules/${item.Id}`} element={item.Module} />
        );
        this.state = { userInfo: this.props.userInfo ? this.props.userInfo : null }
    }

    componentDidMount() {
        EventRegister.addEventListener('userInfoEvent', (data) => {
            this.setState({ userInfo: data.userInfo })
        })
    }
    componentWillUnmount() {
        EventRegister.removeEventListener('userInfoEvent')
    }

    render() {
        return (
            <Routes>
                <Route key="16" path='/contact' element={<ContactForm rumInfo={this.props.rumInfo} userInfo={this.state.userInfo} />} />
                <Route key="1" exact path={"/"} element={<Home userInfo={this.state.userInfo} />} />
                <Route key="2" path='/login' element={<Navigate to={"/"} />} />
                <Route key="3" exact path={"/test"} element={
                    <div>
                        <Paper>

                        </Paper>
                    </div>
                } />
                <Route key="4" exact path={"/about"} element={<div>About Content</div>} />
                <Route key="4.1" exact path={"/me"} element={<MyAccount/>} />
                <Route key="5" exact path={"/modules"} element={<div><MenuList><ListModules /></MenuList></div>} />
                <Route key="6"
                    exact
                    path={"/modules/TeamAdministration/:teamId/AddUser"}

                    loader={({ params }) => {
                        console.debug(params.teamId); // "hotspur"
                    }}

                    element={<AddUser key={new Date()} />}

                />,
                <Route key="7"
                    exact
                    path={"/modules/TeamAdministration/:teamId/roles/manage"}

                    loader={({ params }) => {
                        console.debug(params.teamId); // "hotspur"
                    }}

                    element={<ManageRoles key={new Date()} />}
                />,
                <Route key="8"
                    loader={({ params }) => {
                        console.debug(params.teamId); // "hotspur"
                    }}
                    exact path={"/modules/TeamAdministration/:teamId/roles/add"} element={<AddRole key={new Date()} />} />,
                <Route key="10"
                    loader={({ params }) => {
                        console.debug(params.teamId); // "hotspur"
                    }}
                    exact path={"/modules/TeamAdministration/:teamId/Manage"} element={<ManageTeamUsers key={new Date()} />} />,
                <Route key="14"
                    exact path={"/access/approve"} element={<ApproveRequests />} />
                <Route key="15"
                    loader={({ params }) => {
                        console.debug(params.teamId); // "hotspur"
                    }}
                    exact path={"/modules/TeamAdministration/:teamId/Settings/Team"} element={<TeamSettings key={new Date()} />} />

                <Route key="16-1"
                    loader={({ params }) => {
                        console.debug(params.teamId); // "hotspur"
                    }}
                    exact path={"/modules/TeamAdministration/:teamId/Settings/AccessRequests"} element={<TeamAccessRequestSettings key={new Date()} />} />

                <Route key="11" exact path={"/signedOut"} element={<h1>You have been signed out</h1>} />

                <Route key="12" exact path={"/access/request"} element={<RequestAccess oktaAuth={this.props.oktaAuth} />} />
                <Route key="13" exact path={"/access/view-requests"} element={<MyRequests />} />
                <Route key="audit-review" exact path={"/audit/review"} element={<AuditReview />} />
                <Route key="audit-view" exact path={"/audit/view"} element={<AuditView />} />
                <Route key="audit-view" exact path={"/audit/launch"} element={<AuditLaunch />} />
                {this.listItems}

                <Route key="16" path='/login/callback' element={<Navigate to={localStorage['4sp-cache-last-uri'.replace("/login/callback", "")]} />} />
                {
                    this.props.userInfo.groups ?
                        this.props.userInfo.groups.find(o => { return o == 'App-4SP-Admin' }) ?
                            [
                                <Route key="17" path='/admin/teams' element={<ManageTeams />} />,
                                <Route key="17-1" path='/admin/businessareas' element={<ManageBusinessAreas />} />,
                                <Route key="18" path='/admin/apps' element={<ManageAppsAndRoles />} />,
                                <Route key="19" path='/admin/users' element={<ManageUsers />} />,
                                <Route key="20" path='/admin/userreports' element={<UserReports />} />,
                                <Route key="21" path='/admin/awsaccounts' element={<ManageAwsAccounts />} />,
                                <Route key="22" path='/admin/announcements' element={<ManageAnnouncements />} />,
                                <Route key="23" path='/admin/billingentities' element={<ManageBillingEntities />} />,
                                <Route key="23" path='/admin/pricelist' element={<ManagePriceList />} />
                            ] : null
                        : null
                }




            </Routes>
        )
    }
}

GetRoutes = withRouter(GetRoutes)
MinimalRoutes = withRouter(MinimalRoutes)
export { GetRoutes, MinimalRoutes }