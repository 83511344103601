import { Grid,} from '@aws-amplify/ui-react';
import { Info } from '@mui/icons-material';
import '@aws-amplify/ui-react/styles.css';
import { ArrowDropDown, Done, Engineering, ErrorOutline, Group, GroupAdd, GroupSharp, Person, Settings } from '@mui/icons-material';
import AppsIcon from '@mui/icons-material/Apps';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import FeedbackIcon from '@mui/icons-material/Feedback';
import GroupsIcon from '@mui/icons-material/Groups';
import KeyIcon from '@mui/icons-material/Key';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Button, Collapse, Divider, FormControl, FormGroup, FormLabel, IconButton, Paper, Typography, Zoom } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Box } from '@mui/system';
import React, { Component } from 'react';
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ArrowRight from '@mui/icons-material/ArrowRight';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
    FormHelperText,
    Input,
    List,
    ListItem,
    MenuItem,
    MenuList,
    Stack,
    Tooltip
} from '@mui/material';
import { EventRegister } from 'react-native-event-listeners';
import { PageLoader } from '../Components/Loader';
import { addTeamUser, getOktaUser, getTeams } from "../Components/Queries";








function writeDef(input, def = "Not set") {
    if (input && input != "") {
        return input
    }

    return (
        <ErrorOutline sx={{ color: 'red', mt: 0.3 }} />
    )
}



const groupByRoleId = key => array =>
    array.reduce((objectsByKeyValue, obj) => {
        const value = obj.RoleId.split("-")[key];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
    }, {});


class AddUserClass extends Component {

    constructor(props) {
        super(props);
        var teamId = this.props.router.params.teamId

        this.state = {
            teamId: teamId,
            emailAddress: "",
            selectedRoles: [teamId],
            emailAddress: "",
            firstName: "",
            lastName: "",
            managedTeams: [],
            roleview: [],
            roles: [],
            selectedTeams: [teamId],
            stateresponder: null,
            user: {},
            ready: false
        }
        this.keyCount = 0;

        getOktaUser().then(user => {
            var teamIds = user.groups.filter(o => {
                return o.match(
                    RegExp('^Team-[^-]+-(Manager|Administrator)$')
                ) != undefined
            }).map(o => {

                var p = o.split("-")
                return p[0] + "-" + p[1]

            }).filter((value, index, self) => {

                return self.indexOf(value) === index;

            })
            getTeams(teamIds).then(teams => {
                var team = teams.find(o => { return o.TeamId == this.state.teamId })
                this.setState({
                    managedTeams: teams,
                    roles: team.AvailableRoles,
                    currentTeam: team,
                    ready: true
                })
            })
        })
    }

    componentDidMount() {

    }

    componentWillUnmount() {
        // Make sure to remove the DOM listener when the component is unmounted.
    }


    handleRoleUpdate() {

    }
    async updateRoles() {

        var new_roles = this.state.selectedTeams

        this.state.managedTeams.filter(o => {
            return this.state.selectedTeams.includes(o.TeamId)
        }).forEach((team) => {
            new_roles = new_roles.concat(team['AvailableRoles'])
        })
        this.setState({ 'roles': new_roles })
    }



    handleAddUserSubmit(e) {
        var obj = {
            Username: this.state.emailAddress,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            roles: this.state.selectedRoles
        }
        this.setState({
            isWorking: true
        })

        var promise = addTeamUser(
            this.state.teamId,
            this.state.emailAddress,
            this.state.firstName,
            this.state.lastName,
            this.state.selectedRoles
        ).catch(err => {
            console.error(err)
            this.setState({ isWorking: false })

        }).then(o => {
            this.setState({ isWorking: false, emailAddress: "", firstName: "", lastName: "" })
            window.scrollTo(0, 0)
        })

        toast.promise(
            promise,
            {
                pending: `Adding new team member ${this.state.emailAddress}`,
                success: `${this.state.emailAddress} was added successfully`,
                error: `Failed to add ${this.state.emailAddress}`
            }
        )
    }

    async handleRoleChange(item, event, state) {
        if (state) {
            var newRoles = this.state.selectedRoles.filter(
                i => {
                    return i !== item.RoleName
                }
            )
        }
        else {
            var newRoles = [...this.state.selectedRoles, item.RoleName]
        }

        this.setState(
            {
                'selectedRole': newRoles
            }
        )
    };

    validateEmail(emailAddress) {
        return String(emailAddress).toLowerCase().match(/^[a-z0-9][^ ]+@[a-z0-9][^ ]+(\.[a-z0-9][^ ]+){1,}$/) !== null
    }


    validateName(name) {
        return String(name).match(/^[^ ]{1,}/) !== null
    }

    evalCanSubmit() {

        var reasons = [];
        var s = true;
        if (String(this.state.firstName).match(/^[^ ]{1,}/) === null) { reasons.push('First name is invalid'); s = false }
        if (this.validateEmail(this.state.emailAddress) === false) { reasons.push('Email invalid'); s = false }
        if (String(this.state.lastName).match(/^[^ ]{1,}/) === null) { reasons.push('Last name is invalid'); s = false }
        if (Array(this.state.selectedTeams).length === 0) { reasons.push('At least one team must be selected'); s = false }

        return [s, reasons]

    }



    handleTeamChange(teamId, state) {

        var new_roles = []



        if (state) {

            this.setState({ 'selectedTeams': this.state.selectedTeams.filter(i => { return i !== teamId }) })
            console.debug('Removing ' + teamId)
        }
        else {

            this.setState({ 'selectedTeams': [teamId] })
            console.debug('Adding ' + teamId)
        }

        this.state.managedTeams.filter(o => {
            return (o.TeamId == teamId) === !state
        }).forEach((team) => {
            new_roles = new_roles.concat(team['AvailableRoles'])
            console.debug("Selected: ", teamId)
        })
        this.setState({ 'roles': new_roles })
        this.setState({ selectedRoles: this.state.selectedRoles.filter(o => { return o in new_roles.map(o => { return o.RoleId }) }) })

        console.debug("NewRoles: ", new_roles)
    };

    async handleRoleChange(appId, state) {
        var o = {}
        o["selected" + appId] = !state

        if (state) {
            this.setState({ 'selectedRoles': this.state.selectedRoles.filter(i => { return i !== appId }) })
            console.debug('Removing ' + appId)
        }
        else {
            this.setState({ 'selectedRoles': [...this.state.selectedRoles, appId] })
            console.debug('Adding ' + appId)
        }
        this.setState(o)
    };

    toggleRoleChange(appId) {
        var inRoles = this.state.selectedRoles.find(i => { return i !== appId }) != undefined
        console.debug(this.state.selectedRoles.find(i => { return i !== appId }) != undefined)
        if (inRoles) {
            this.setState({ 'selectedRoles': this.state.selectedRoles.filter(i => { return i !== appId }) })
            console.debug('Removing ' + appId)
        }
        else {
            this.setState({ 'selectedRoles': [...this.state.selectedRoles, appId] })
            console.debug('Adding ' + appId)
        }
    };


    handleSetText(setter, event) {
        let u = {}
        u[setter] = event.target.value
        this.setState(u)
    }


    render() {
        if (!this.state.ready || this.state.currentTeam == undefined) {
            return (
                <PageLoader ready={(!this.state.ready || this.state.currentTeam == undefined)} />
            )
        }

        return (

            this.state.currentTeam ? (
                <Paper sx={{ minHeight: '800px', pl: 2, pb: 3, pt: 1 }}>
                    <div style={{ pb: 3, display: "flex", flexDirection: "row" }}>
                        <Grid container style={{ display: "flex", flexDirection: "row" }}>
                            <Grid item><h1 >Team {this.state.currentTeam.TeamName} </h1></Grid>
                            <Grid item><Typography variant="h5" mt={5} mx={3}><ArrowForwardIosIcon /></Typography></Grid>
                            <Grid item><Typography variant="h5" mt={5} component="div"> <PersonAddIcon /> &nbsp; Add new team member</Typography></Grid>
                        </Grid>

                    </div>
                    <Paper sx={{ display: 'block', maxWidth: "80%", ml: 3, pl: 3, pb: 3, pr: 4 }}>
                        {
                            (this.state.lastUserAdded) ?

                                <Box
                                    width={'100%'}
                                    sx={{ backgroundColor: '#66bb6a', opacity: [0.9, 0.8, 0.7], px: 1, py: 1 }}
                                >
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        ml: 1
                                    }}>
                                        <Done /><b>{this.state.lastUserAdded}</b> &nbsp; was added to this team
                                    </div>
                                </Box>

                                : null
                        }
                        <p>Please enter the new member details below</p>

                        <Stack>

                            <FormControl>
                                <Typography variant="h5" component="h2" mt={1} > <Info />  User information </Typography>
                                <Divider sx={{ mb: 2, mt: 2 }} />

                                <FormLabel id='email-label' sx={{ ml: 2 }}>Email</FormLabel>
                                <FormGroup aria-label="email-label" sx={{ ml: 2 }}>
                                    <Input
                                        id="emailInput"
                                        onChange={(event) => this.handleSetText('emailAddress', event)}
                                        aria-describedby="email-helper-text"
                                        value={this.state.emailAddress}
                                    />
                                    <FormHelperText id="email-helper-text" hidden={this.state.emailAddress != ""} >Must be assaabloy.com or whitelisted domain</FormHelperText>
                                    <FormHelperText id="email-helper-text" hidden={(this.validateEmail(this.state.emailAddress) == true)} ><FeedbackIcon sx={{ color: 'orange', pt: 0.4 }} />Invalid email</FormHelperText>
                                </FormGroup>

                                <FormLabel sx={{ mt: 3, ml: 2 }} id='fn-label' >First name / Given name</FormLabel>
                                <FormGroup aria-labelledby='fn-label' sx={{ ml: 2 }}>
                                    <Input
                                        aria-label='fnLabel'
                                        onChange={(event) => this.handleSetText('firstName', event)}
                                        id="firstNameInput"
                                        aria-describedby="fn-helper-text"
                                        value={this.state.firstName}
                                    ></Input>
                                    <FormHelperText id="fn-helper-text">Example: John</FormHelperText>
                                    <FormHelperText id="fn-helper-text" hidden={(this.validateName(this.state.firstName) == true)} ><FeedbackIcon sx={{ color: 'orange', pt: 0.4 }} />Invalid name</FormHelperText>
                                    <FormHelperText id="fn-helper-text" hidden={(this.state.firstName != "")} ><FeedbackIcon sx={{ color: 'orange', pt: 0.4 }} />Cannot be empty</FormHelperText>
                                </FormGroup>

                                <FormLabel sx={{ mt: 3, ml: 2 }} id='ln-label'>Last name / Family name</FormLabel>
                                <FormGroup aria-labelledby='ln-label' sx={{ ml: 2 }}>
                                    <Input
                                        id="last-name-input"
                                        onChange={(event) => this.handleSetText('lastName', event)}
                                        aria-describedby="ln-helper-text"
                                        value={this.state.lastName}
                                    />
                                    <FormHelperText id="ln-helper-text">Example: Johnsson</FormHelperText>
                                    <FormHelperText id="ln-helper-text" hidden={(this.validateName(this.state.lastName) == true)} ><FeedbackIcon sx={{ color: 'orange', pt: 0.4 }} />Invalid name</FormHelperText>
                                    <FormHelperText id="ln-helper-text" hidden={(this.state.lastName != "")} ><FeedbackIcon sx={{ color: 'orange', pt: 0.4 }} />Cannot be empty</FormHelperText>
                                </FormGroup>




                                <Typography variant="h5" component="h2" mt={3}  > <GroupsIcon /> Team</Typography>
                                <Divider sx={{ mb: 2 }} />
                                <Box bgcolor={'#212121'} sx={{ opacity: 0.9 }} paddingBottom={3}>
                                    <FormGroup aria-labelledby='teams-label' sx={{ ml: 2 }}>
                                        <MenuList stateCount={this.state.stateresponder}>
                                            {
                                                this.state.managedTeams.filter(o => { return o.TeamId == this.state.teamId }).map((team) => {
                                                    var isSelected = this.state.selectedTeams.find(o => { return o === team.TeamId }) !== undefined
                                                    return (
                                                        <MenuItem
                                                            key={"main-" + team.TeamId}

                                                            value={team.TeamId}
                                                            disabled={true}
                                                            onClick={() => this.handleTeamChange(team.TeamId, isSelected)}
                                                            selected={isSelected}

                                                        >

                                                            {
                                                                isSelected ? <CheckBoxIcon sx={{ color: 'green', mr: 1, pt: 0.4 }} /> : <CheckBoxOutlineBlankIcon sx={{ color: 'gray', opacity: 0.1, mr: 1, pt: 0.4 }} />
                                                            }



                                                            {team.Description != team.TeamId ? team.Description : ''}
                                                            <FormLabel sx={{ ml: 1 }} >{team.TeamName}</FormLabel>
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                        </MenuList>
                                    </FormGroup>
                                </Box>
                                <Typography variant="h5" component="h2" mt={3}  > <AppsIcon /> Team Roles </Typography>
                                <Divider sx={{ mb: 2 }} />
                                <FormGroup aria-labelledby='roles-label' sx={{ ml: 2 }} >

                                    <MenuList stateCount={this.state.stateresponder} >
                                        {

                                            Object.entries(groupByRoleId(0)(this.state.roles.filter(r => {


                                                if (r.RoleId) { return r['RoleId'].match(RegExp("^(Role)-")) || r['RoleId'].match(RegExp("^Team-[^-]+-")) }
                                                else { return false }

                                            }))).map((entry) => {

                                                var roles = entry[1]
                                                return (
                                                    [
                                                        <FormLabel h sx={{ pl: 5, mt: 1 }}>{entry[0]}-roles</FormLabel>,
                                                        <MenuList sx={{ ml: 5, py: 0, my: 0 }}>
                                                            {
                                                                roles.map((role) => {
                                                                    var isSelected = ((this.state.selectedRoles.find(o => { return o === role.RoleId }) !== undefined))
                                                                    if (!isSelected) {
                                                                        isSelected = (this.state.selectedRoles.find(o => { return o.match(RegExp("^" + role.RoleId + "-.+$")) }) !== undefined)
                                                                    }
                                                                    return (
                                                                        <Tooltip TransitionComponent={Zoom} PopperProps={{ style: { pointerEvents: 'none', marginTop: '40px' } }} placement="left" title={
                                                                            <div key="rolesdiv">
                                                                                <List>
                                                                                    {
                                                                                        Array(this.state.managedTeams.filter(o => {
                                                                                            return this.state.selectedTeams.includes(o.TeamId)
                                                                                        }).map((o, index) => {
                                                                                            return (<ListItem key={index}><GroupsIcon sx={{ pr: 0.4 }} /><Typography variant="subtitle1">{o.Description}</Typography></ListItem>)
                                                                                        }))
                                                                                    }
                                                                                </List>
                                                                            </div>

                                                                        } >
                                                                            <MenuItem
                                                                                key={"sub3-" + role.RoleId}
                                                                                value={role.RoleId}
                                                                                onClick={(event) => {
                                                                                    if (!(this.state.selectedRoles.find(o => { return o.match(RegExp("^" + role.RoleId + "-.+$")) }) !== undefined)) {
                                                                                        this.handleRoleChange(role.RoleId, isSelected)
                                                                                    }

                                                                                }}
                                                                                selected={isSelected}
                                                                                sx={{ maxHeight: "2em" }}

                                                                            >
                                                                                {
                                                                                    isSelected ? <CheckBoxIcon sx={{ color: 'green', mr: 1, pt: 0.4 }} /> : <CheckBoxOutlineBlankIcon sx={{ color: 'gray', opacity: 0.1, mr: 1, pt: 0.4 }} />
                                                                                }
                                                                                <Typography fontSize={"12pt"} style={{ fontStyle: "bold" }}>{role.RoleId}</Typography>
                                                                                <FormLabel sx={{ ml: 3 }} >{role.Description}</FormLabel>
                                                                            </MenuItem>
                                                                        </Tooltip>

                                                                    )
                                                                })
                                                            }
                                                        </MenuList>

                                                    ]

                                                )

                                            })

                                        }
                                    </MenuList>
                                </FormGroup>

                                <Typography variant="h5" component="h2" mt={3}  > <AppsIcon /> Applications </Typography>
                                <Divider sx={{ mb: 2 }} />
                                <FormGroup aria-labelledby='apps-label' sx={{ ml: 2 }}>

                                    <MenuList stateCount={this.state.stateresponder}>
                                        {
                                            this.state.roles.map((role) => {
                                                var show = (role.RoleType === 'App' && role.RoleId.split("-").length === 2)
                                                var showSubRoles = false
                                                if (show) {
                                                    var isSelected = ((this.state.selectedRoles.find(o => { return o === role.RoleId }) !== undefined))
                                                    if (!isSelected) {
                                                        isSelected = (this.state.selectedRoles.find(o => { return o.match(RegExp("^" + role.RoleId + "-.+$")) }) !== undefined)
                                                    }
                                                    var parentItem = (
                                                        <Tooltip TransitionComponent={Zoom} PopperProps={{ style: { pointerEvents: 'none', marginTop: '40px' } }} placement="left" title={
                                                            <div key="appdiv">
                                                                <List>
                                                                    {
                                                                        Array(this.state.managedTeams.filter(o => {
                                                                            return this.state.selectedTeams.includes(o.TeamId)
                                                                        }).map((o, index) => {
                                                                            return (<ListItem key={"apps-" + index}><GroupsIcon sx={{ pr: 0.4 }} /><Typography variant="subtitle1">{o.Description}</Typography></ListItem>)
                                                                        }))
                                                                    }
                                                                </List>
                                                            </div>

                                                        } >
                                                            <MenuItem
                                                                key={"sub1-" + role.RoleId}
                                                                value={role.RoleId}
                                                                onClick={(event) => {
                                                                    if (!(this.state.selectedRoles.find(o => { return o.match(RegExp("^" + role.RoleId + "-.+$")) }) !== undefined)) {
                                                                        this.handleRoleChange(role.RoleId, isSelected)
                                                                    }

                                                                }}
                                                                selected={isSelected}
                                                                sx={{ maxHeight: "2em" }}
                                                                toolt

                                                            >

                                                                {
                                                                    isSelected ? <CheckBoxIcon sx={{ color: 'green', mr: 1, pt: 0.4 }} /> : <CheckBoxOutlineBlankIcon sx={{ color: 'gray', opacity: 0.1, mr: 1, pt: 0.4 }} />
                                                                }
                                                                <Typography fontSize={"14pt"} style={{ fontStyle: "bold" }}>{role.RoleId.split("-")[1]}</Typography>
                                                                <FormLabel sx={{ ml: 3 }} >{role.Description != undefined ? role.Description : ''}</FormLabel>
                                                            </MenuItem>
                                                        </Tooltip>
                                                    )
                                                    return (
                                                        [
                                                            parentItem,
                                                            <FormLabel hidden={(showSubRoles == false)} sx={{ pl: 5, mt: 1 }}>Roles & Permissions:</FormLabel>,
                                                            <MenuList sx={{ ml: 5, py: 0, my: 0 }}>
                                                                {

                                                                    this.state.roles.map((subRole) => {
                                                                        {

                                                                            if (subRole.RoleId != undefined) {
                                                                                var subIsSelected = (this.state["selected" + subRole.RoleId] == true)
                                                                                return subRole.RoleId.startsWith(role.RoleId + "-") ?
                                                                                    <MenuItem
                                                                                        key={"subrole-" + subRole.RoleId}
                                                                                        value={subRole.RoleId}
                                                                                        onClick={(event) => {

                                                                                            this.handleRoleChange(subRole.RoleId, subIsSelected).then(e => {
                                                                                                if ((this.state.selectedRoles.find(o => { return o === role.RoleId }) === undefined)) {
                                                                                                    this.handleRoleChange(role.RoleId, false)
                                                                                                }
                                                                                            })

                                                                                        }}

                                                                                        selected={subIsSelected}
                                                                                        sx={{ maxHeight: "2em" }}
                                                                                        hidden={(showSubRoles == false)}

                                                                                    >
                                                                                        {
                                                                                            showSubRoles = true
                                                                                        }
                                                                                        {
                                                                                            subIsSelected ? <CheckBoxIcon sx={{ color: 'green', mr: 1, pt: 0.4 }} /> : <CheckBoxOutlineBlankIcon sx={{ color: 'gray', opacity: 0.1, mr: 1, pt: 0.4 }} />
                                                                                        }
                                                                                        <Typography >{subRole.RoleName}</Typography>
                                                                                        <FormLabel sx={{ ml: 3 }} >{subRole.Description != undefined ? subRole.Description : ''}</FormLabel>
                                                                                    </MenuItem>
                                                                                    : null
                                                                            }
                                                                            else {

                                                                            }
                                                                        }
                                                                    })

                                                                }
                                                            </MenuList>,
                                                            <Divider />
                                                        ]

                                                    )
                                                }
                                                else {
                                                    return null
                                                }

                                            })
                                        }
                                    </MenuList>
                                </FormGroup>

                                <Typography variant="h5" component="h2" mt={3}  > <KeyIcon /> Aws Account Access </Typography>
                                <Divider sx={{ mb: 2 }} />

                                <FormGroup aria-labelledby='roles-label' sx={{ ml: 2 }}>

                                    <MenuList>
                                        {

                                            Object.entries(groupByRoleId(1)(this.state.roles.filter(r => {

                                                if (r.RoleId) { return r.RoleId.split("-")[0] === 'AwsAccount' }
                                                else { return false }

                                            }))).map((entry) => {

                                                var accountId = entry[0]
                                                var roles = entry[1]
                                                roles[0]['Description'] = roles[0]['Description'] ? roles[0]['Description'] : roles[0].RoleId.replace("AwsAccount-", "").replace("-", " - ")
                                                var accountDescription = roles[0]['Description'].split(" - ").splice(0, roles[0]['Description'].split(" - ").length - 1).join(" - ")
                                                return (
                                                    [
                                                        <FormLabel h sx={{ pl: 5, mt: 1 }}>
                                                            {accountDescription}
                                                        </FormLabel>,
                                                        <MenuList sx={{ ml: 5, py: 0, my: 0 }}>
                                                            {
                                                                roles.map((role) => {
                                                                    var isSelected = ((this.state.selectedRoles.find(o => { return o === role.RoleId }) !== undefined))
                                                                    if (!isSelected) {
                                                                        isSelected = (this.state.selectedRoles.find(o => { return o.match(RegExp("^" + role.RoleId + "-.+$")) }) !== undefined)
                                                                    }
                                                                    return (
                                                                        <Tooltip TransitionComponent={Zoom} PopperProps={{ style: { pointerEvents: 'none', marginTop: '40px' } }} placement="left" title={
                                                                            <div key="rolediv">
                                                                                <List>
                                                                                    {
                                                                                        Array(this.state.managedTeams.filter(o => {
                                                                                            return this.state.selectedTeams.includes(o.TeamId)
                                                                                        }).map((o, index) => {
                                                                                            return (<ListItem key={"accounts-" + index}><GroupsIcon sx={{ pr: 0.4 }} /><Typography variant="subtitle1">{o.Description}</Typography></ListItem>)
                                                                                        }))
                                                                                    }

                                                                                </List>
                                                                            </div>

                                                                        } >
                                                                            <MenuItem
                                                                                key={"sub2" + role.RoleId}
                                                                                value={role.RoleId}
                                                                                onClick={(event) => {
                                                                                    if (!(this.state.selectedRoles.find(o => { return o.match(RegExp("^" + role.RoleId + "-.+$")) }) !== undefined)) {
                                                                                        this.handleRoleChange(role.RoleId, isSelected)
                                                                                    }

                                                                                }}
                                                                                selected={isSelected}
                                                                                sx={{ maxHeight: "2em" }}

                                                                            >
                                                                                {
                                                                                    isSelected ? <CheckBoxIcon sx={{ color: 'green', mr: 1, pt: 0.4 }} /> : <CheckBoxOutlineBlankIcon sx={{ color: 'gray', opacity: 0.1, mr: 1, pt: 0.4 }} />
                                                                                }
                                                                                <Typography fontSize={"12pt"} style={{ fontStyle: "bold" }}>{role.RoleId.split("-")[2]}</Typography>
                                                                                <FormLabel sx={{ ml: 3 }} >{role.RoleId}</FormLabel>
                                                                            </MenuItem>
                                                                        </Tooltip>

                                                                    )
                                                                })
                                                            }
                                                        </MenuList>

                                                    ]

                                                )

                                            })

                                        }
                                    </MenuList>
                                </FormGroup>


                                <Typography variant="h5" component="h2" mt={3}  > <GroupSharp /> Other roles </Typography>
                                <Divider sx={{ mb: 2 }} />
                                <FormGroup aria-labelledby='other-roles-label' sx={{ ml: 2 }} >

                                    <MenuList stateCount={this.state.stateresponder} >
                                        {

                                            Object.entries(groupByRoleId(0)(this.state.roles.filter(r => {
                                                var excludedPrefixes = [
                                                    'AwsAccount',
                                                    'Team',
                                                    'App',

                                                ]

                                                if (r.RoleId) {
                                                    return excludedPrefixes.map(prefix => {
                                                        return r['RoleId'].startsWith(prefix + '-')
                                                    }).includes(true) == false
                                                }
                                                else { return false }

                                            }))).map((entry) => {

                                                var roles = entry[1]
                                                return (
                                                    [
                                                        <FormLabel h sx={{ pl: 5, mt: 1 }}>{entry[0]}-roles</FormLabel>,
                                                        <MenuList sx={{ ml: 5, py: 0, my: 0 }}>
                                                            {
                                                                roles.map((role) => {
                                                                    var isSelected = ((this.state.selectedRoles.find(o => { return o === role.RoleId }) !== undefined))
                                                                    if (!isSelected) {
                                                                        isSelected = (this.state.selectedRoles.find(o => { return o.match(RegExp("^" + role.RoleId + "-.+$")) }) !== undefined)
                                                                    }
                                                                    return (
                                                                        <Tooltip TransitionComponent={Zoom} PopperProps={{ style: { pointerEvents: 'none', marginTop: '40px' } }} placement="left" title={
                                                                            <div key="rolesdiv">
                                                                                <List>
                                                                                    {
                                                                                        Array(this.state.managedTeams.filter(o => {
                                                                                            return this.state.selectedTeams.includes(o.TeamId)
                                                                                        }).map((o, index) => {
                                                                                            return (<ListItem key={index}><GroupsIcon sx={{ pr: 0.4 }} /><Typography variant="subtitle1">{o.Description}</Typography></ListItem>)
                                                                                        }))
                                                                                    }
                                                                                </List>
                                                                            </div>

                                                                        } >
                                                                            <MenuItem
                                                                                key={"sub3-" + role.RoleId}
                                                                                value={role.RoleId}
                                                                                onClick={(event) => {
                                                                                    if (!(this.state.selectedRoles.find(o => { return o.match(RegExp("^" + role.RoleId + "-.+$")) }) !== undefined)) {
                                                                                        this.handleRoleChange(role.RoleId, isSelected)
                                                                                    }

                                                                                }}
                                                                                selected={isSelected}
                                                                                sx={{ maxHeight: "2em" }}

                                                                            >
                                                                                {
                                                                                    isSelected ? <CheckBoxIcon sx={{ color: 'green', mr: 1, pt: 0.4 }} /> : <CheckBoxOutlineBlankIcon sx={{ color: 'gray', opacity: 0.1, mr: 1, pt: 0.4 }} />
                                                                                }
                                                                                <Typography fontSize={"12pt"} style={{ fontStyle: "bold" }}>{role.RoleId}</Typography>
                                                                                <FormLabel sx={{ ml: 3 }} >{role.Description}</FormLabel>
                                                                            </MenuItem>
                                                                        </Tooltip>

                                                                    )
                                                                })
                                                            }
                                                        </MenuList>

                                                    ]

                                                )

                                            })

                                        }
                                    </MenuList>
                                </FormGroup>

                            </FormControl>

                            <Divider sx={{ mb: 2 }} />

                            <Typography variant="h5" component="h2" mt={3} pb={3}  > Summary </Typography>
                            <Paper sx={{ px: 3, pb: 4 }}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align='left' width={'20%'}>
                                                <Typography variant='h6'> First name: </Typography>
                                            </TableCell>
                                            <TableCell align='left' >
                                                <Typography variant='subtitle1'>{writeDef(this.state.firstName)}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='left' width={'20%'}>
                                                <Typography variant='h6'> Last name: </Typography>
                                            </TableCell>
                                            <TableCell align='left' >
                                                <Typography variant='subtitle1'>{writeDef(this.state.lastName)}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='left' width={'20%'}>
                                                <Typography variant='h6'> DisplayName: </Typography>
                                            </TableCell>
                                            <TableCell align='left' >
                                                <Typography variant='subtitle1'>{writeDef(this.state.firstName)} {writeDef(this.state.lastName)}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='left' sx={{ verticalAlign: 'top', pt: 0, pb: 0 }} width={'20%'}>
                                                <Typography sx={{ pt: 0, pb: 0 }} variant='h6'> Teams </Typography>
                                            </TableCell>
                                            <TableCell align='left' sx={{ verticalAlign: 'top', pt: 0 }}  >
                                                <Typography variant='subtitle1'>
                                                    <List>
                                                        {
                                                            this.state.selectedTeams.sort().map((o, index) => {
                                                                return (<ListItem key={index} sx={{ pt: 0, pb: 0 }} ><ArrowRightIcon />{o}</ListItem>)
                                                            })
                                                        }
                                                    </List>
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='left' sx={{ verticalAlign: 'top', pt: 0, pb: 0 }} width={'20%'}>
                                                <Typography variant='h6'> Applications & Permissions </Typography>
                                            </TableCell>
                                            <TableCell align='left' sx={{ verticalAlign: 'top', pt: 0 }}  >
                                                <Typography variant='subtitle1'>
                                                    <List>
                                                        {
                                                            this.state.selectedRoles.sort().map((o, index) => {
                                                                return (<ListItem key={index} sx={{ pt: 0, pb: 0 }} ><ArrowRightIcon />{o}</ListItem>)
                                                            })
                                                        }
                                                    </List>
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>

                            </Paper>

                            <Button
                                variant="contained"
                                sx={{ mt: 3, minHeight: '10em' }}
                                disabled={this.evalCanSubmit()[0] == false || this.state.isWorking}
                                onClick={
                                    (e) => this.handleAddUserSubmit()
                                }
                            >
                                Create user / Add to team
                            </Button>


                        </Stack>

                    </Paper >

                </Paper >
            ) : <h3>Loading...</h3>

        )
    }
}


class TeamAdministration extends Component {

    constructor(props) {
        super(props);
        this._isMounted = false
        this._setState = this.setState
        this.setState = this.setStateWhenMounted.bind(this)
        this.getKey = this.getKey.bind(this);


        this.state = {
            count: 0,
            hideTable: false,
            isOpen: {},
            managedTeams: [],
            userInfo: {},
            ready: false

        }


        this.rows = []
        this.onLoad()
        this.activeTeam = null
        this.state.isLoading = false



        this.keyCount = 0;


        getOktaUser().then(user => {
            var promises = []
            var teamList = user.groups.filter(o => {
                return o.match(
                    RegExp('^Team-[^-]+-(Manager|Administrator)$')
                ) != undefined
            }).map(o => {

                var p = o.split("-")
                return p[0] + "-" + p[1]

            }).filter((value, index, self) => {
                return self.indexOf(value) === index;
            })

            getTeams(teamList).then(teams => {
                this.setState({
                    managedTeams: teams,
                    ready: true
                });
                localStorage.setItem('4sp-cache-teams', JSON.stringify(teams))
            })



        })
    }



    setStateWhenMounted(obj) {
        if (this._isMounted) {
            return this._setState(obj)
        }
    }

    getKey(teamId = "") {
        var kc = this.keyCount++;
        return `${teamId}-${kc}`
    }

    async componentDidMount() {
        // When the component is mounted, add your DOM listener to the "nv" elem.
        // (The "nv" elem is assigned in the render function.)

        EventRegister.addEventListener('userInfoEvent', (data) => {
            this.setState({ userInfo: data.userInfo })
        })


        this._isMounted = true
    }

    async componentWillUnmount() {
        // Make sure to remove the DOM listener when the component is unmounted.

        this._isMounted = false
        EventRegister.removeEventListener('userInfoEvent')
    }



    async clickHandler() {
        this.setState({ count: this.state.count + 1 })
        this.state.success = true
    }

    onLoad() {

    }
    selectTeam(row) {

        this.setState({ activeTeam: row })

    }

    back() {
        this.setState({ activeTeam: null })
    }

    handleCollapse(id) {
        var isOpen = this.state.isOpen
        var r
        if (isOpen[id]) {
            r = false
        }
        else {
            r = true
        }
        isOpen[id] = r
        this.setState({ isOpen: isOpen })
        console.debug(isOpen)
    }


    render() {
        return this.state.managedTeams.sort((a, b) => { return a.TeamId < b.TeamId ? -1 : 1 }).map((row) => {

            var groups = this.props.userInfo.groups ? this.props.userInfo.groups : []
            var isManager = true
            return (
                <div key={"teamAdminMenu_" + row.TeamId}>
                    <MenuItem data-test={row.TeamId + '-main'} key={'main-' + row.TeamId} sx={{ bgcolor: (this.state.isOpen[row.TeamId] ? "#424242" : undefined), mr: 4, display: "flex" }} onClick={(e) => {
                        this.handleCollapse(row.TeamId)
                    }}>
                        <Typography sx={{ pt: 0.8 }}>{this.state.isOpen[row.TeamId] || false ? <ArrowDropDown /> : <ArrowRight />}</Typography>
                        <Typography>{row.TeamName}</Typography>
                    </MenuItem>
                    <Collapse in={this.state.isOpen[row.TeamId]}>
                        <MenuList sx={{ pl: 3, minWidth: "15em", mr: 4 }}>

                            <MenuItem key={"Users_" + row.TeamId} data-test={row.TeamId + '-users'} onClick={() => this.handleCollapse(row.TeamId + '-TeamMember')} >
                                <IconButton sx={{ mr: 1 }} >
                                    <Typography><Person sx={{ mr: 1 }} />Users</Typography>
                                </IconButton>
                            </MenuItem>
                            <Collapse in={this.state.isOpen[row.TeamId + "-TeamMember"]}>
                                <MenuList sx={{ pl: 3, minWidth: "15em", mr: 4 }}>
                                    <MenuItem key={"AddUser_" + row.TeamId} component={NavLink} to={'/modules/TeamAdministration/' + row.TeamId + '/AddUser'} >
                                        <IconButton sx={{ mr: 1 }} >
                                            <PersonAddIcon sx={{ mr: 1 }} /><Typography>Add user</Typography>
                                        </IconButton>
                                    </MenuItem>
                                    <MenuItem key={"manageUsers" + row.TeamId} component={NavLink} to={'/modules/TeamAdministration/' + row.TeamId + '/Manage'} >
                                        <IconButton sx={{ mr: 1 }} >
                                            <Settings sx={{ mr: 1 }} /><Typography>Manage users</Typography>
                                        </IconButton>
                                    </MenuItem>
                                </MenuList>

                            </Collapse>
                            <MenuItem key={"Roles_" + row.TeamId} onClick={() => this.handleCollapse(row.TeamId + '-TeamRole')} >
                                <IconButton sx={{ mr: 1 }} >
                                    <Typography><ManageAccountsIcon sx={{ mr: 1 }} />Roles</Typography>
                                </IconButton>
                            </MenuItem>
                            <Collapse in={this.state.isOpen[row.TeamId + "-TeamRole"]}>
                                <MenuList sx={{ pl: 3, minWidth: "15em", mr: 4 }}>
                                    <MenuItem key={"AddRole_" + row.TeamId} component={NavLink} to={'/modules/TeamAdministration/' + row.TeamId + '/roles/add'}  >
                                        <IconButton>
                                            <Typography>
                                                <GroupAdd /> New role
                                            </Typography>
                                        </IconButton>
                                    </MenuItem>
                                    <MenuItem key={"ManageRoles_" + row.TeamId} component={NavLink} to={'/modules/TeamAdministration/' + row.TeamId + '/roles/manage'}>
                                        <IconButton>
                                            <Typography>
                                                <Group /> Manage team roles
                                            </Typography>
                                        </IconButton>
                                    </MenuItem>
                                </MenuList>
                            </Collapse>
                            <MenuItem key={"TeamSettings_" + row.TeamId} onClick={() => this.handleCollapse(row.TeamId + '-Settings')} >
                                <IconButton sx={{ mr: 1 }} >
                                    <Settings sx={{ mr: 1 }} /><Typography>Settings</Typography>
                                </IconButton>
                            </MenuItem>
                            <Collapse in={this.state.isOpen[row.TeamId + "-Settings"]}>
                                <MenuList sx={{ pl: 3, minWidth: "15em", mr: 4 }}>
                                    <MenuItem key={"TeamAppSettings_" + row.TeamId} component={NavLink} to={'/modules/TeamAdministration/' + row.TeamId + '/Settings/AccessRequests'} >
                                        <IconButton sx={{ mr: 1 }} >
                                            <AdminPanelSettingsIcon sx={{ mr: 1 }} /><Typography>Application Settings</Typography>
                                        </IconButton>
                                    </MenuItem>
                                    <MenuItem key={"TeamSettings_" + row.TeamId} component={NavLink} to={'/modules/TeamAdministration/' + row.TeamId + '/Settings/Team'} >
                                        <IconButton sx={{ mr: 1 }} >
                                            <Engineering sx={{ mr: 1 }} /><Typography>Team Settings</Typography>
                                        </IconButton>
                                    </MenuItem>
                                </MenuList>
                            </Collapse>


                        </MenuList>
                    </Collapse >
                </div >

            )

        })


    }
}

function withRouter(C) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <C
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}

var AddUser = withRouter(AddUserClass)

export { AddUser, TeamAdministration };
export default TeamAdministration