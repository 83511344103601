import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  Badge,
  Card,
  CardActions,
  CardContent,
  Tooltip,
  Typography,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { ArrowForward, Notifications } from "@mui/icons-material";
import { markUserNotificationsAsRead } from "./Queries";

export default function NotificationsMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  var unreadNotifications = props.data.filter(o => {return o.Read})
  
  const handleClick = (event) => {
    
    if (unreadNotifications.length > 0){
      markUserNotificationsAsRead(unreadNotifications)
      unreadNotifications = []
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Badge
          badgeContent={unreadNotifications.length}
          color="warning"
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          
        </Badge>
        <Notifications />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {props.data.map((data) => {
          return (
            <MenuItem
              sx={{ wordBreak: "break-all", whiteSpace: "break-spaces" }}
            >
              <Card sx={{ maxWidth: "40em" }} variant="outlined">
                <CardContent>
                  <Typography
                    sx={{ fontSize: 12 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    {
                      data.ImageKey ? data.ImageKey : 'Notification'
                    }
                  </Typography>
                  <Typography variant="h6" component="div">
                    {data.Title}
                  </Typography>

                  <Typography variant="body2"> {data.Description}</Typography>
                </CardContent>
                <CardActions>
                  {data.Url ? (
                    <Tooltip title={data.Url}>
                      <Button component={NavLink} to={data.Url}>
                        <ArrowForward />
                      </Button>
                    </Tooltip>
                  ) : null}
                </CardActions>
              </Card>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
