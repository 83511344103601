import { Check, Edit, GroupAdd, SaveAs, Warning } from '@mui/icons-material';
import {
    Autocomplete,
    Button,
    Collapse,
    Divider,
    FormControl,
    FormGroup,
    FormLabel,
    Grid,
    Paper,
    Stack,
    TextField,
    Tooltip,
    Typography,
    createFilterOptions
} from '@mui/material';
import { Box } from '@mui/system';
import React, { Component } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { PageLoader } from './Loader';
import { createTeamRole, getOktaUser, getTeam, listTeamRoles, updateTeamRole } from './Queries';




class ManageRoles extends Component {
    constructor(props) {
        super(props);


        var teamId = this.props.router.params.teamId
        this._setState = this.setState
        this.setState = this.setStateWhenMounted.bind(this)
        this.saveRole = this.saveRole.bind(this)





        this.state = {
            teamId: teamId,
            teamRoles: [],
            isOpen: {},
            updateStates: {},
            ready: false

        }
        this.gridKey = 0

        var promises = []

        promises.push(
            getTeam(teamId).then(team => {
                listTeamRoles(teamId).then(o => {
                    this.setState({ teamRoles: o })
                    var updateObj = {}
                    o.map(role => {
                        updateObj[`${role.RoleId}-selectedRoles`] = team.AvailableRoles.filter(o => {
                            return role.Roles.includes(o.RoleId)
                        }).map(o => { return o.RoleId })

                        updateObj[`${role.RoleId}-roleDescription`] = role.Description ? role.Description : ""
                        this.setState(updateObj)
                    })

                }).then(o => {
                    this.setState({ ready: true })
                })
                this.setState({ currentTeam: team })

            })
        )

        promises.push(
            getOktaUser().then(user => {
                this.setState({ user: user })
            })
        )


        Promise.all(promises)




    }

    saveRole(roleId) {
        var descr = this.state[`${roleId}-roleDescription`]
        var roles = this.state[`${roleId}-selectedRoles`]
        var isWorking = this.state.updateStates
        isWorking[roleId] = true

        this.setState({ updateStates: isWorking })
        var promise = updateTeamRole(roleId, descr, roles, this.state.teamId).then(o => {
            isWorking[roleId] = false
            this.setState({ updateStates: isWorking })
        })


        toast.promise(
            promise,
            {
                pending: "Updating role " + roleId,
                success: roleId + " updated!",
                error: "Failed to update role " + roleId
            }
        )
    }

    setStateWhenMounted(obj) {
        if (this._isMounted) {
            return this._setState(obj)
        }

    }
    componentDidMount() {
        this._isMounted = true

    }

    componentWillUnmount() {
        this._isMounted = false
        // Make sure to remove the DOM listener when the component is unmounted.
        window.removeEventListener("cache-teams-updated", this.handleTeamsUpdated);
    }

    render() {
        if (!this.state.ready) {
            return (
                <PageLoader ready={this.state.ready} />
            )
        }
        return (
            <Paper sx={{ paddingBottom: 4 }}>
                <Grid marginLeft={2} marginBottom={4} paddingTop={3} container>
                    <Grid xs={12} item>
                        <Typography variant='h5'>Manage team roles</Typography>
                    </Grid>
                    <Grid xs={12} item>
                        <Typography variant='caption'>
                            Team role is a role specific for a team. The team role may also contain other roles that the member should have.
                        </Typography>
                    </Grid>
                </Grid>


                {
                    this.state.teamRoles.map(role => {

                        return (
                            [
                                <Divider sx={{ mx: 2 }} />,
                                <Grid key={this.gridKey++} marginTop={2} marginLeft={3} paddingBottom={2} container xs={12}>
                                    <Grid item xs={12}>
                                        <Typography variant='h6'>
                                            <Button
                                                variant='contained'
                                                color='secondary'
                                                sx={{ marginRight: 2 }}
                                                onClick={
                                                    (event) => {
                                                        var isOpen = this.state.isOpen
                                                        console.debug(isOpen)
                                                        isOpen[role.RoleId] = !isOpen[role.RoleId]
                                                        this.setState({ isOpen: isOpen })
                                                    }
                                                }
                                            >
                                                <Edit />
                                            </Button>
                                            {role.RoleId}
                                        </Typography>
                                    </Grid>

                                </Grid>,
                                <Collapse sx={{ paddingBottom: 2 }} in={this.state.isOpen[role.RoleId] ? true : false}>
                                    <Grid marginLeft={6} marginBottom={8} xs={6} item>



                                        <Tooltip title="Short description, max 64 chars" >
                                            <TextField
                                                required
                                                id="standard-required"
                                                label="Description"
                                                onChange={

                                                    (event) => {
                                                        var updateObj = {}
                                                        updateObj[`${role.RoleId}-roleDescription`] = event.target.value
                                                        this.setState(updateObj)
                                                    }
                                                }
                                                defaultValue={role.Description}
                                                margin="normal"
                                            />
                                        </Tooltip>

                                        <Autocomplete
                                            multiple
                                            id="selectRoles"
                                            defaultValue={
                                                this.state.currentTeam.AvailableRoles.filter(o => { return role.Roles.includes(o.RoleId) }).map(o => {
                                                    return { label: o.RoleId, description: o.Description }
                                                })
                                            }
                                            isOptionEqualToValue={
                                                (option, value) => {
                                                    return option.label == value.label
                                                }
                                            }
                                            onChange={
                                                (event, newInputValue) => {

                                                    var updateObj = {}
                                                    updateObj[`${role.RoleId}-selectedRoles`] = newInputValue.map(o => { return o.label })
                                                    this.setState(updateObj)
                                                }
                                            }
                                            filterOptions={
                                                createFilterOptions({
                                                    matchFrom: 'any',
                                                    stringify: (option) => option.label + (option.description ? option.description : ""),
                                                })
                                            }

                                            groupBy={(option) => { return String(option.label.split("-")[0]) }}
                                            options={
                                                this.state.currentTeam.AvailableRoles.sort((a, b) => { return a.RoleId < b.RoleId }).map(o => {
                                                    return { label: o.RoleId, description: o.Description, id: o.RoleId }
                                                })
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Select roles"
                                                    placeholder="Select roles"
                                                />
                                            )}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    <Box
                                                        sx={{
                                                            flexGrow: 1,
                                                            '& span': {
                                                                color: '#8b949e'

                                                            },
                                                        }}
                                                    >
                                                        {option.label}
                                                        <br />
                                                        <span>{option.description}</span>
                                                    </Box>
                                                </li>

                                            )}
                                        />

                                        <Tooltip title="Save">
                                            <Button
                                                sx={{ marginTop: 3 }}
                                                disabled={this.state.updateStates[role.RoleId]}
                                                variant='contained'
                                                color='success'
                                                onClick={
                                                    (event) => {
                                                        this.saveRole(role.RoleId)
                                                    }
                                                }
                                            >
                                                <SaveAs />
                                            </Button>
                                        </Tooltip>

                                    </Grid>
                                </Collapse>,

                            ]
                        )
                    })
                }

                {
                    this.state.teamRoles.length == 0 ? <Typography marginLeft={4} variant='h5'>No team roles found, please <a href="./add">add one</a></Typography> : null
                }
            </Paper >
        )
    }
}
class AddRole extends Component {
    constructor(props) {
        super(props);


        var teamId = this.props.router.params.teamId
        this._setState = this.setState
        this.setState = this.setStateWhenMounted.bind(this)
        this.createRole = this.createRole.bind(this)

        this.state = {
            teamId: teamId,
            ready: false,
            groups: [],
            roleName: "",
            roleDescription: "",
            selectedRoles: [],
            success: false
        }




        getOktaUser().then(user => {
            this.setState({ user: user })
        }).then(o => {
            getTeam(teamId).then(team => {
                this.setState({ currentTeam: team, ready: true })
            })

        })


    }


    createRole() {
        var promise = createTeamRole(
            this.state.roleName,
            this.state.roleDescription,
            this.state.selectedRoles,
            this.state.teamId
        ).then(o => {
            this.setState({
                roleName: "",
                roleDescription: "",
                success: true
            })
            window.location.replace(`/modules/TeamAdministration/${this.state.teamId}/roles/manage`);
        })

        toast.promise(
            promise,
            {
                pending: "Creating team role",
                success: "Team role created!",
                error: "Failed to create team role..."
            }
        )
    }


    setStateWhenMounted(obj) {
        if (this._isMounted) {
            return this._setState(obj)
        }

    }
    componentDidMount() {
        this._isMounted = true

    }

    componentWillUnmount() {
        this._isMounted = false
        // Make sure to remove the DOM listener when the component is unmounted.
    }

    render() {
        if (!this.state.ready || this.state.currentTeam == undefined) {
            return (
                <PageLoader ready={this.state.ready && this.state.currentTeam} />
            )
        }
        return (
            <Paper>

                <Paper sx={{ margin: 3 }}>
                    <div style={{ pb: 3, display: "flex", flexDirection: "row" }}>
                        <Grid container style={{ display: "flex", flexDirection: "row" }}>
                            <Grid item>
                                <Typography variant="h5" sx={{ marginTop: 4 }}>
                                    Team {this.state.currentTeam.TeamName}
                                </Typography>
                            </Grid>
                            <Grid item><Typography variant="h6" mt={5} ml={1}><GroupAdd /></Typography></Grid>
                            <Grid item><Typography variant="h6" mt={4.5} component="div"> &nbsp; Create new team role</Typography></Grid>
                        </Grid>

                    </div>
                    <Paper sx={{ display: 'block', maxWidth: "80%", ml: 3, pl: 3, pb: 3, pr: 4 }}>
                        <FormControl component="fieldset" variant='standard' sx={{ minWidth: '40%' }}>

                            <FormLabel component="legend" >Create a new team role. </FormLabel>
                            <FormLabel component="legend" >But be careful! Having roles as member of a role might make it tricky to remove a membership from a user later on if not all memberships are handled through roles. </FormLabel>
                            <FormGroup aria-label="position" sx={{ paddingTop: 2 }}>
                                {
                                    this.state.createDisabled ?
                                        <Typography><Warning /> {this.state.disableReason} </Typography>
                                        : null
                                }
                               
                                <Tooltip title="Team Name - alphanumerical only and must start with a capital letter" >
                                    <TextField
                                        required
                                        id="standard-required"
                                        label="Role name"
                                        value={this.state.roleName}
                                        onChange={
                                            (event) => {
                                                const str = event.target.value;
                                                const re = new RegExp(/^[A-Za-z0-9]+$/gi)
                                                var outString = (str.charAt(0).toUpperCase() + str.slice(1))




                                                var roleName = (this.state.teamId + "-" + outString)
                                                var foundRole = this.state.currentTeam.AvailableRoles.find(o => {
                                                    return o.RoleId.toLowerCase() == roleName.toLocaleLowerCase()
                                                })

                                                this.setState({ roleName: outString })

                                                if (foundRole) {
                                                    console.warn(roleName + " Exists!")
                                                    this.setState({ createDisabled: true })
                                                    this.setState({ disableReason: roleName + " exists already!" })
                                                }
                                                else if (!str.match(re)) {
                                                    this.setState({ createDisabled: true })
                                                    this.setState({ disableReason: roleName + " is not valid! May only contain A-Za-z0-9 " })
                                                }
                                                else if (str.length < 5) {
                                                    this.setState({ createDisabled: true })
                                                    this.setState({ disableReason: roleName + " is to short" })
                                                }
                                                else if (['administrator', 'manager'].includes(str.toLowerCase())) {
                                                    this.setState({ createDisabled: true })
                                                    this.setState({ disableReason: roleName + " Must not be Administrator or Manager!" })
                                                }
                                                else {
                                                    this.setState({ createDisabled: false })
                                                }
                                            }

                                        }
                                        defaultValue=""
                                        margin="normal"
                                    />
                                </Tooltip>

                                <Tooltip title="Short description, max 64 chars" >
                                    <TextField
                                        required
                                        id="standard-required"
                                        label="Description"
                                        onChange={
                                            (event) => this.setState({ roleDescription: event.target.value })
                                        }
                                        value={this.state.roleDescription}
                                        margin="normal"
                                    />
                                </Tooltip>
                                
                                <Autocomplete
                                    multiple
                                    id="selectRoles"
                                    onChange={
                                        (event, newInputValue) => {
                                            this.setState({ selectedRoles: newInputValue.map(o => { return o.id }) })
                                        }
                                    }
                                    filterOptions={
                                        createFilterOptions({
                                            matchFrom: 'any',
                                            stringify: (option) => option.label + (option.description ? option.description : ""),
                                        })
                                    }

                                    groupBy={(option) => { return String(option.label.split("-")[0]) }}
                                    options={
                                        this.state.currentTeam.AvailableRoles.sort((a, b) => { return a.RoleId < b.RoleId }).map(o => {
                                            return { label: o.RoleId, description: o.Description, id: o.RoleId }
                                        })
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select roles"
                                            placeholder="Select roles..."
                                        />
                                    )}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Box
                                                sx={{
                                                    flexGrow: 1,
                                                    '& span': {
                                                        color: '#8b949e'

                                                    },
                                                }}
                                            >
                                                {option.label}
                                                <br />
                                                <span>{option.description}</span>
                                            </Box>
                                        </li>

                                    )}
                                />
                                {
                                    this.state.selectedRoles < 1 ?
                                        <Typography><Warning /> You must select at least 1 role </Typography>
                                        : null
                                }

                            </FormGroup>

                            <Divider />

                        </FormControl>
                        <Grid container alignContent={'right'} marginTop={3} marginBottom={4} alignItems='baseline' hidden>
                            <Grid item width={'100%'}><Typography variant="body2">RoleId: {this.state.teamId}-{this.state.roleName}</Typography></Grid>

                        </Grid>


                        <Button
                            disabled={
                                this.state.createDisabled || (this.state.roleName == "" || this.state.roleName == undefined || this.state.roleDescription == "" || this.state.selectedRoles.length < 1)
                            }
                            variant="contained"
                            onClick={(event) => this.createRole()}
                            sx={{}}>
                            {
                                this.state.success || true ? <Check /> : null
                            }
                            Add new role
                        </Button>




                        <Stack>
                        </Stack>
                    </Paper>
                </Paper>
            </Paper>
        )
    }
}

function withRouter(C) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <C
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}


ManageRoles = withRouter(ManageRoles)
AddRole = withRouter(AddRole)
export { AddRole, ManageRoles };
