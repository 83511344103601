import { Amplify } from '@aws-amplify/core';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { generateClient } from 'aws-amplify/api';
import { oktaAuth } from './Auth';
import amplifyconfig from '../amplifyconfiguration.json';
import { AuditAction } from '../models';

const client = generateClient({

});

function returnArrayIfUndefined(arr) {
    if (!arr) {
        return []
    }
    return arr
}

// Functions already using client.graphql remain unchanged.
// Simple query
async function listUsers() {
    const req = await client.graphql({
        query: queries.listUsers
    })
    return req.data.listUsers
}

async function listTeams(v = {}) {
    const req = await client.graphql({
        query: queries.listTeams,
        variables: v
    })
    return returnArrayIfUndefined(req.data.listTeams)
}

// Update functions to use client.graphql
async function listApps() {
    const req = await client.graphql({
        query: queries.listApps
    })
    return req.data.listApps.items
}

async function getApprovalWorkflow(id) {
    console.debug("Id: ", id)
    const req = await client.graphql({
        query: queries.getApprovalWorkflow,
        variables: { id }
    })
    return req.data.getApprovalWorkflow
}
async function listAccessRequests(status = "Pending", limit = 500, filter = {}) {
    if (status) {
        filter['Status'] = { 'eq': status }
    }

    let vars = { 'filter': filter }
    if (limit) {
        vars['limit'] = limit
    }

    const query = `
        query ListAccessRequests(
            $filter: ModelAccessRequestFilterInput
            $nextToken: String,
            $limit:  Int
        ) {
            listAccessRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
                    nextToken
                    items {
                    id
                    createdAt
                    ApprovalWorkflowQueue {
                        items {
                        ApprovalDate
                        ApprovedBy
                        ApproverIdentity
                        Reason
                        Status
                        TargetApproverIds
                        TargetRoleId
                        TargetUsernameId
                        accessRequestApprovalWorkflowQueueId
                        createdAt
                        id
                        updatedAt
                        }
                    }
                    ApprovalWorkflow {
                        Type
                        Target
                        TargetDisplayName
                        ConfigData
                        Teams
                        CanWrite
                        id
                        createdAt
                        updatedAt
                    }
                    Readers
                    Reason
                    Status
                    TeamId
                    Username
                    accessRequestApprovalWorkflowId
                }
            }
        }
    `;

    let req = await client.graphql({
        query,
        variables: vars
    });

    let data = req.data.listAccessRequests.items;

    while (req.data.listAccessRequests.nextToken) {
        vars['nextToken'] = req.data.listAccessRequests.nextToken;
        req = await client.graphql({
            query,
            variables: vars
        });
        data = data.concat(req.data.listAccessRequests.items);
    }

    return returnArrayIfUndefined(data);
}

async function listUserAccessRequests(username, status = 'Pending') {
    let vars = { filter: { 'Username': { 'eq': username } } };
    if (status != '*') {
        vars['filter'] = {
            'Status': { 'eq': status },
            'Username': { 'eq': username }
        };
    }

    const query = `
        query ListAccessRequests(
            $filter: ModelAccessRequestFilterInput
            $limit: Int
            $nextToken: String
        ) {
            listAccessRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
                nextToken
                items {
                id
                createdAt
                ApprovalWorkflowQueue {
                    items {
                    ApprovalDate
                    ApprovedBy
                    ApproverIdentity
                    Reason
                    Status
                    TargetApproverIds
                    TargetRoleId
                    TargetUsernameId
                    accessRequestApprovalWorkflowQueueId
                    createdAt
                    id
                    updatedAt
                    }
                }
                ApprovalWorkflow {
                    Type
                    Target
                    TargetDisplayName
                    ConfigData
                    Teams
                    CanWrite
                    id
                    createdAt
                    updatedAt
                }
                Readers
                Reason
                Status
                TeamId
                Username
                accessRequestApprovalWorkflowId
                }
            }
        }
    `;

    const req = await client.graphql({
        query,
        variables: vars
    });

    return returnArrayIfUndefined(req.data.listAccessRequests.items);
}

async function listApprovalWorkflowQueueItems(status = undefined, workflowId = undefined, filter = undefined) {
    let vars = {};
    if (status || workflowId) {
        vars['filter'] = {};
    }
    if (filter) {
        vars['filter'] = filter;
    }

    if (status) {
        vars['filter']['Status'] = { 'eq': status };
    }
    if (workflowId) {
        vars['filter']["accessRequestApprovalWorkflowQueueId"] = { 'eq': workflowId };
    }

    const req = await client.graphql({
        query: queries.listApprovalWorkflowQueueItems,
        variables: vars
    });
    return returnArrayIfUndefined(req.data.listApprovalWorkflowQueueItems.items);
}

async function putApprovalWorkflow(target, steps, teamId, configData={}) {
    const data = {
        target: target,
        steps: steps,
        teamId: teamId
    };
    
    const req = await client.graphql({
        query: mutations.putApprovalWorkflow,
        variables: { 
            teamId: teamId,
            target: target,
            steps: steps,
            configData: JSON.stringify(configData)
        }
    });
    return req.data.putApprovalWorkflow;
}

async function listApprovalWorkflows(teamId = undefined) {
    let vars = {};
    if (teamId) {
        vars['filter'] = { 'Teams': { 'contains': teamId } };
    }

    const req = await client.graphql({
        query: queries.listApprovalWorkflows,
        variables: vars
    });
    return returnArrayIfUndefined(req.data.listApprovalWorkflows.items);
}

async function listApprovalWorkflowsFull(teamId = undefined) {
    const listApprovalWorkflowsFullQuery = /* GraphQL */ `
        query ListApprovalWorkflows(
            $filter: ModelApprovalWorkflowFilterInput
            $limit: Int
            $nextToken: String
        ) {
            listApprovalWorkflows(
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            ) {
            items {
                Type
                Target
                Steps {
                    AccessApprover
                    ConfigData
                }
                ConfigData
                Teams
                CanWrite
                id
                createdAt
                updatedAt
            }
            nextToken
            }
        }
    `;
    let vars = {};
    if (teamId) {
        vars['filter'] = { 'Teams': { 'contains': teamId } };
    }

    const req = await client.graphql({
        query: listApprovalWorkflowsFullQuery,
        variables: vars
    });
    return returnArrayIfUndefined(req.data.listApprovalWorkflows.items);
}

async function getSelf() {
    const req = await client.graphql({
        query: queries.getSelf
    });
    return req.data.getSelf;
}

async function submitAccessRequest(teamId, requestTarget, requestType = undefined, reason) {
    const data = { teamId: teamId, requestTarget: requestTarget, reason: reason, requestType: requestType };
    const req = await client.graphql({
        query: mutations.submitAccessRequest,
        variables: { teamId, requestTarget, requestType, reason }
    });
    return req;
}
async function approveAccessRequest(id, reason) {
    const data = {
        id,
        reason
    };
    const req = await client.graphql({
        query: mutations.approveAccessRequestQueueItem,
        variables: { id, reason }
    });
    return req;
}

async function denyAccessRequest(id, reason) {
    const data = {
        id,
        reason
    };
    const req = await client.graphql({
        query: mutations.denyAccessRequestQueueItem,
        variables: { id, reason }
    });
    return req;
}

async function createTeamRole(roleName, roleDescription, roles, teamId) {
    const data = {
        roleName: roleName,
        roleDescription: roleDescription,
        roles: roles,
        teamId: teamId
    };
    const req = await client.graphql({
        query: mutations.createTeamRole,
        variables: data
    });
    return req;
}

async function listGroups(filter = undefined) {
    const vars = { filter };
    const req = await client.graphql({
        query: queries.listGroups,
        variables: vars
    });
    return req.data.listGroups.items;
}


async function listGroupMembers(GroupId) {
    const vars = { GroupId };
    const req = await client.graphql({
        query: queries.listGroupMembers,
        variables: vars
    });
    return req.data.listGroupMembers;
}

async function listTeamRoles(teamId) {
    const req = await client.graphql({
        query: queries.listTeamRoles,
        variables: { teamId }
    });
    return returnArrayIfUndefined(req.data.listTeamRoles).sort((a, b) => a.RoleName ? a.RoleName.localeCompare(b.RoleName) : 0)
}

async function listTeamManagedRoles(teamId) {
    const req = await client.graphql({
        query: queries.listTeamManagedRoles,
        variables: { teamId }
    });
    return returnArrayIfUndefined(req.data.listTeamManagedRoles);
}

async function listTeamUsers(teamId) {
    const req = await client.graphql({
        query: queries.listTeamUsers,
        variables: { teamId }
    });
    return returnArrayIfUndefined(req.data.listTeamUsers);
}

async function updateTeamRole(roleId, roleDescription, roles, teamId) {
    const data = {
        roleId,
        roleDescription,
        roles,
        teamId
    };
    const req = await client.graphql({
        query: mutations.updateTeamRole,
        variables: data
    });
    return req;
}

async function getTeamUser(teamId, username) {
    const cGetTeamUser = /* GraphQL */ `
        query GetTeamUser($teamId: String!, $username: String!) {
            getTeamUser(teamId: $teamId, username: $username) {
                Username
                FirstName
                LastName
                Status
                Email
                City
                Department
                DisplayName
                Division
                Locale
                Manager
                MobilePhone
                Organization
                PreferredLanguage
                PrimaryPhone
                SecondEmail
                StreetAddress
                Timezone
                Title
                UserType
                ExpirationDate
                Roles {
                    RoleId
                    RoleType
                    RoleName
                    Description
                    Roles
                }
            }
        }
    `;


    const req = await client.graphql({
        query: cGetTeamUser,
        variables: { teamId, username }
    });
    const data = req.data.getTeamUser;
    return data;
    
}

var isFetchingUserInfo = false
var isFetchingTeams = false
async function getOktaUser() {
    let userCache = JSON.parse(localStorage.getItem('4sp-cache-userinfo') || '[]');

    if (userCache.length !== 0 && (Number(new Date()) / 1000) - userCache[0] < 30) {
        return userCache[1];
    }

    isFetchingUserInfo = true;
    const user = await oktaAuth.getUser();
    userCache = [Number(new Date()) / 1000, user];
    localStorage.setItem('4sp-cache-userinfo', JSON.stringify(userCache));
    isFetchingUserInfo = false;
    return user;
}
async function getTeam(teamId, cacheMaxAgeInSec = 120) {
    let teamCache = JSON.parse(localStorage.getItem('4sp-cache-teams') || "[]");
    let found = teamCache.find(o => o.TeamId === teamId);

    if (found && ((Number(new Date()) / 1000) - found.fetchedAt) < cacheMaxAgeInSec) {
        console.debug(`Returning ${teamId} from cache`);
        return found;
    } else {
        console.debug(`${teamId} NOT found in cache`);
        const req = await client.graphql({
            query: queries.getTeam,
            variables: { teamId }
        });

        const data = req.data.getTeam;
        teamCache = teamCache.filter(o => o.TeamId !== teamId);
        data['fetchedAt'] = Number(new Date()) / 1000;
        teamCache.push(data);
        localStorage.setItem('4sp-cache-teams', JSON.stringify(teamCache));
        return data;
    }
}
var lastGetTeamsResult = null
async function getTeams(teamIds, cacheMaxAgeInSec = 120) {
    if (lastGetTeamsResult !== null) {
        if ((Number(new Date()) / 1000) - lastGetTeamsResult[0] < 120) {
            return lastGetTeamsResult[1];
        }
    }
    if (isFetchingTeams) {
        let counter = 0;
        while (isFetchingTeams && counter <= 20) {
            await new Promise(res => setTimeout(res, 100));
            counter++;
        }
        await new Promise(res => setTimeout(res, 100));
        if (lastGetTeamsResult !== null) {
            return lastGetTeamsResult[1];
        }
    }
    isFetchingTeams = true;

    let teamCache = JSON.parse(localStorage.getItem('4sp-cache-teams') || "[]");
    let teams = [];
    teamIds = teamIds.filter(teamId => {
        let found = teamCache.find(o => o.TeamId === teamId);
        if (found && ((Number(new Date()) / 1000) - found.fetchedAt) < cacheMaxAgeInSec) {
            console.debug(`Returning ${teamId} from cache`);
            teams.push(found);
            return false;
        }
        return true;
    });

    let data = [];
    if (teamIds.length > 0) {
        const req = await client.graphql({
            query: queries.getTeams,
            variables: { teamIds, roles: true }
        });
        data = req.data.getTeams;
        data.forEach(team => {
            team['fetchedAt'] = Number(new Date()) / 1000;
            teamCache = teamCache.filter(o => o.TeamId !== team.TeamId); // Remove outdated cache
            teamCache.push(team);
        });
    }

    data = [...data, ...teams];
    localStorage.setItem('4sp-cache-teams', JSON.stringify(teamCache));
    lastGetTeamsResult = [Number(new Date()) / 1000, data];
    isFetchingTeams = false;
    return data;
}

async function updateTeamUser(teamId, username, roles, expirationDate = undefined) {
    const data = {
        teamId,
        username,
        roles,
        expirationDate
    };

    const req = await client.graphql({
        query: mutations.updateTeamUser,
        variables: data
    });
    return req.data.updateTeamUser;
}

async function addTeamUser(teamId, username, firstName, lastName, roles, expirationDate = undefined) {
    const data = {
        teamId,
        firstName,
        lastName,
        username,
        roles,
        expirationDate
    };

    const req = await client.graphql({
        query: mutations.addTeamUser,
        variables: data
    });
    return req.data.addTeamUser;
}
async function activateTeamUser(teamId, username) {
    const data = { teamId, username };
    const req = await client.graphql({
        query: mutations.activateTeamUser,
        variables: data
    });
    return req.data.activateTeamUser;
}

async function disableUser(teamId, username) {
    const data = { teamId, username };
    const req = await client.graphql({
        query: mutations.disableUser,
        variables: data
    });
    return req.data.resetUserPassword; // Ensure this is the correct field returned by your GraphQL operation
}

async function resetPassword(teamId, username) {
    const data = { teamId, username };
    const req = await client.graphql({
        query: mutations.resetUserPassword,
        variables: data
    });
    return req.data.resetUserPassword;
}

async function resetMFA(teamId, username) {
    const data = { teamId, username };
    const req = await client.graphql({
        query: mutations.resetUserMfa,
        variables: data
    });
    return req.data.resetUserMFA;
}



async function gqlQuery(query, variables = {}) {
    const req = await client.graphql({
        query: query,
        variables: { variables }
    });
    
    return req.data
}


async function removeTeamMember(teamId, username) {
    const data = { teamId, username };
    const req = await client.graphql({
        query: mutations.removeTeamMember,
        variables: data
    });
    return req.data.removeTeamMember;
}

async function resendActivationEmail(teamId, username) {
    const data = { teamId, username };
    const req = await client.graphql({
        query: mutations.resendActivationEmail,
        variables: data
    });
    return req.data.resendActivationEmail;
}

async function listUserNotifications(filter) {
    const req = await client.graphql({
        query: queries.listUserNotifications,
        variables: { filter }
    });
    return returnArrayIfUndefined(req.data.listUserNotifications.items);
}

async function markUserNotificationsAsRead(notifications) {
    const mutationsList = notifications.map((notification, index) => `
        q${index}: updateUserNotification(input: {id: "${notification.id}", Read: true}) {
            id
            Username
            Read
        }
    `).join('\n');

    const mutationQuery = `mutation UpdateUserNotifications {
        ${mutationsList}
    }`;

    const req = await client.graphql({
        query: mutationQuery
    });
    return req; // Adjust based on the actual GraphQL operation's return structure
}
async function createUserReport(Username, UserGroups, RumSessionId, ReportType, Description) {
    const req = await client.graphql({
        query: mutations.createUserReport,
        variables: {
            input: {
                Username: String(Username),
                UserGroups,
                RumSessionId: String(RumSessionId),
                ReportType: String(ReportType),
                Description: String(Description),
            }
        }
    });
    return req;
}

async function listBusinessAreas(filter = {}) {
    const req = await client.graphql({
        query: queries.listBusinessAreas,
        variables: { filter }
    });
    return returnArrayIfUndefined(req.data.listBusinessAreas.items);
}

async function createBusinessArea(id, displayName) {
    const req = await client.graphql({
        query: mutations.createBusinessArea,
        variables: {
            input: {
                id,
                DisplayName: displayName
            }
        }
    });
    return req.data.createBusinessArea;
}

async function updateAuditEntries(entries) {
    if (entries.length === 0) return {};

    const mutationsList = entries.filter(entry => entry.keep !== undefined && entry.keep !== null)
        .map((entry, ix) => `
            Q${ix}: updateAuditEntry(input: {id: "${entry.id}", Action: ${entry.keep ? AuditAction.KEEP : AuditAction.REMOVE_OR_DISABLE}}) {
                id
                Username
                TargetId
                Type
            }
        `).join('\n');

    if (!mutationsList) return {};

    const mutationQuery = `mutation UpdateAuditEntry {
        ${mutationsList}
    }`;

    const req = await client.graphql({
        query: mutationQuery
    });
    return req;
}

async function updateAuditEntry(id, keep) {
    const action = keep ? 'Keep' : 'RemoveOrDisable';
    const req = await client.graphql({
        query: mutations.updateAuditEntry,
        variables: {
            input: {
                id,
                action
            }
        }
    });
    return req.data.updateAuditEntry;
}

async function listAuditEntries(filter = undefined) {
    const req = await client.graphql({
        query: queries.listAuditEntries,
        variables: { filter }
    });
    return returnArrayIfUndefined(req.data.listAuditEntries.items);
}

async function adminListUsers(filter = undefined) {
    const req = await client.graphql({
        query: queries.adminListUsers,
        variables: { filter }
    });
    return returnArrayIfUndefined(req.data.adminListUsers);
}

async function adminListGroups(filter, members = false) {
    const req = await client.graphql({
        query: queries.adminListGroups,
        variables: { filter, members }
    });
    return returnArrayIfUndefined(req.data.adminListGroups);
}

async function adminUpdateGroupManagedBy(groupId, managedByGroups) {
    const req = await client.graphql({
        query: mutations.adminUpdateGroupManagedBy,
        variables: { groupId, groupList: managedByGroups }
    });
    return req.data.adminUpdateGroupManagedBy;
}

async function adminAddGroupMember(username, groupId) {
    const req = await client.graphql({
        query: mutations.adminAddGroupMember,
        variables: { username, groupId }
    });
    return req.data.adminAddGroupMember;
}

async function adminRemoveGroupMember(username, groupId) {
    const req = await client.graphql({
        query: mutations.adminRemoveGroupMember,
        variables: { username, groupId }
    });
    return req.data.adminRemoveGroupMember;
}

async function adminResetUserPassword(username) {
    const req = await client.graphql({
        query: mutations.adminResetUserPassword,
        variables: { username }
    });
    return req.data.adminResetUserPassword;
}

async function adminResetUserMfa(username) {
    const req = await client.graphql({
        query: mutations.adminResetUserMfa,
        variables: { username }
    });
    return req.data.adminResetUserMfa;
}

async function adminDisableUser(username) {
    const req = await client.graphql({
        query: mutations.adminDisableUser,
        variables: { username }
    });
    return req.data.adminDisableUser;
}

async function adminEnableUser(username) {
    const req = await client.graphql({
        query: mutations.adminEnableUser,
        variables: { username }
    });
    return req.data.adminEnableUser;
}

async function adminCreateTeam(teamId, teamName) {
    const req = await client.graphql({
        query: mutations.adminCreateTeam,
        variables: { teamId, teamName }
    });
    return req.data.adminCreateTeam;
}

async function adminUpdateTeam(teamId, teamName, businessArea) {
    const req = await client.graphql({
        query: mutations.adminUpdateTeam,
        variables: { teamId, teamName, businessArea }
    });
    return req.data.adminUpdateTeam;
}

async function listUserReports() {
    const req = await client.graphql({
        query: queries.listUserReports
    });
    return req.data.listUserReports.items;
}

async function listAwsAccounts(filter = undefined) {
    const req = await client.graphql({
        query: queries.listAwsAccounts,
        variables: { filter }
    });
    return returnArrayIfUndefined(req.data.listAwsAccounts.items);
}

async function updateAwsAccount(input) {
    const req = await client.graphql({
        query: mutations.updateAwsAccount,
        variables: { input }
    });
    return req.data.updateAwsAccount;
}
async function deleteAwsAccount(id) {
    const req = await client.graphql({
        query: mutations.deleteAwsAccount,
        variables: { id }
    });
    return returnArrayIfUndefined(req.data.deleteAwsAccounts.items);
}

async function listEntityToBusinessAreaMappings(filter = undefined) {
    const req = await client.graphql({
        query: queries.listEntityToBusinessAreaMappings,
        variables: { filter, limit: 1000 }
    });
    return returnArrayIfUndefined(req.data.listEntityToBusinessAreaMappings.items);
}

async function updateEntityToBusinessAreaMapping(input) {
    const req = await client.graphql({
        query: mutations.updateEntityToBusinessAreaMapping,
        variables: { input }
    });
    return req.data.updateEntityToBusinessAreaMapping;
}

async function deleteEntityToBusinessAreaMapping(id) {
    const req = await client.graphql({
        query: mutations.deleteEntityToBusinessAreaMapping,
        variables: { id }
    });
    return returnArrayIfUndefined(req.data.deleteEntityToBusinessAreaMappings);
}

async function whitelistIp(ip, dryRun = false) {
    const req = await client.graphql({
        query: mutations.addIpWhitelist,
        variables: { ip, dryRun }
    });
    return req.data.whitelistIp;
}

async function listImageScanReports(accountId = undefined) {
    const req = await client.graphql({
        query: queries.listImageScanReports,
        variables: { accountId }
    });
    return returnArrayIfUndefined(req.data.listImageScanReports);
}

async function getImageScanReport(accountId, reportName) {
    const req = await client.graphql({
        query: queries.getImageScanReport,
        variables: { accountId, reportName }
    });
    return req.data.getImageScanReport;
}

async function getTeamConfig(teamId) {
    const req = await client.graphql({
        query: queries.getTeamConfig,
        variables: { id: teamId }
    });
    return req.data.getTeamConfig;
}

async function updateTeamConfig(input) {
    const variables = { input: { ...input, createdAt: undefined, updatedAt: undefined } };
    const req = await client.graphql({
        query: mutations.updateTeamConfig,
        variables
    });
    return req.data.updateTeamConfig;
}

async function createTeamConfig(input) {
    const req = await client.graphql({
        query: mutations.createTeamConfig,
        variables: { input }
    });
    return req.data.createTeamConfig;
}

async function listAnnouncements(filter = undefined) {
    const req = await client.graphql({
        query: queries.listAnnouncements,
        variables: { filter }
    });
    return returnArrayIfUndefined(req.data.listAnnouncements.items);
}

async function createAnnouncement(input) {
    const req = await client.graphql({
        query: mutations.createAnnouncement,
        variables: { input }
    });
    return req.data.createAnnouncement;
}

async function deleteAnnouncement(id) {
    const req = await client.graphql({
        query: mutations.deleteAnnouncement,
        variables: { input: { id } }
    });
    return returnArrayIfUndefined(req.data.deleteAnnouncement.items);
}

async function invokeAuditReview(teamId) {
    const req = await client.graphql({
        query: mutations.invokeAuditReview,
        variables: { teamId }
    });
    return req.invokeAuditReview;
}

async function listPricingLists(filter = undefined) {
    const req = await client.graphql({
        query: queries.listPricingLists,
        variables: { filter }
    });
    return returnArrayIfUndefined(req.data.listPricingLists.items);
}

async function updatePricingList(input) {
    const req = await client.graphql({
        query: mutations.updatePricingList,
        variables: { input }
    });
    return req.data.updatePricingList;
}

async function createPricingList(input) {
    const req = await client.graphql({
        query: mutations.createPricingList,
        variables: { input }
    });
    return req.data.createPricingList;
}




async function deletePricingList(id) {
    var vars = { input: {id: id} }
    const req = await client.graphql({
        query: mutations.deletePricingList,
        variables: { vars }
    });
    
    var data = req.data.deletePricingList
    return returnArrayIfUndefined(data)
}

export {
    listGroupMembers,
    createPricingList,
    listPricingLists,
    updatePricingList,
    deletePricingList,
    listEntityToBusinessAreaMappings,
    deleteEntityToBusinessAreaMapping,
    updateEntityToBusinessAreaMapping,
    activateTeamUser,
    removeTeamMember,
    getOktaUser,
    getTeams,
    invokeAuditReview,
    gqlQuery,
    createTeamConfig,
    updateTeamConfig,
    getTeamConfig,
    updateAuditEntries,
    updateAuditEntry,
    listAuditEntries,
    adminUpdateTeam,
    listBusinessAreas,
    createBusinessArea,
    resendActivationEmail,
    getTeam,
    listTeamManagedRoles,
    createAnnouncement,
    deleteAnnouncement,
    listAnnouncements,
    getImageScanReport,
    listImageScanReports,
    whitelistIp,
    deleteAwsAccount,
    listAwsAccounts,
    updateAwsAccount,
    adminCreateTeam,
    listUserReports,
    adminResetUserMfa,
    adminResetUserPassword,
    adminEnableUser,
    adminDisableUser,
    adminListUsers,
    adminAddGroupMember,
    adminRemoveGroupMember,
    adminUpdateGroupManagedBy,
    adminListGroups,
    markUserNotificationsAsRead,
    createUserReport,
    listUserNotifications,
    listApprovalWorkflowsFull,
    putApprovalWorkflow,
    resetMFA,
    resetPassword,
    disableUser,
    addTeamUser,
    updateTeamUser,
    getTeamUser,
    listTeamUsers,
    updateTeamRole,
    listTeamRoles,
    createTeamRole,
    listGroups,
    getSelf,
    submitAccessRequest,
    getApprovalWorkflow,
    listUsers,
    listTeams,
    listApps,
    listAccessRequests,
    listApprovalWorkflowQueueItems,
    listApprovalWorkflows,
    listUserAccessRequests,
    denyAccessRequest,
    approveAccessRequest
}