import * as React from 'react'
import {
  ThemeProvider,
  defaultDarkModeOverride,
} from '@aws-amplify/ui-react';
import {ThemeProvider as MuiThemeProvider, createTheme} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import '@aws-amplify/ui-react/styles.css';
import '@aws-amplify/ui-react/styles/reset.layer.css' // global CSS reset
import '@aws-amplify/ui-react/styles/base.layer.css' // base styling needed for Amplify UI
import '@aws-amplify/ui-react/styles/button.layer.css' // component specific styles
import { Amplify } from '@aws-amplify/core';
import { GetRoutes, MinimalRoutes } from './Router';

import {
  NavLink,
  BrowserRouter as Router
} from "react-router-dom";



import Button from '@mui/material/Button';
import { Component } from 'react';
import { MenuItem, MenuList } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import {styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { AccountBox, AdminPanelSettings, ArrowRight, BugReport, GroupWork, Home, Key, People, Person, PlayCircleFilled, TableRows, VerifiedUser, Visibility } from '@mui/icons-material';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import AppsIcon from '@mui/icons-material/Apps';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CallToActionIcon from '@mui/icons-material/CallToAction';
import GroupsIcon from '@mui/icons-material/Groups';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import LogoutIcon from '@mui/icons-material/Logout';
import Collapse from '@mui/material/Collapse';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Security, withOktaAuth } from '@okta/okta-react';
import { EventRegister } from 'react-native-event-listeners';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logo from "./Components/Logo";
import ListModules from "./Components/Modules";
import * as Queries from "./Components/Queries";
import TeamAdministration from './Modules/TeamAdministration';
import { datadogRum } from '@datadog/browser-rum';
import ApprovalIcon from '@mui/icons-material/Approval';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import shortid from 'shortid';
import amplifyconfig from './amplifyconfiguration.json';
import { oktaAuth, oktaConfig } from './Components/Auth';
import NotificationsMenu from './Components/NotificationMenu';
import { listUserNotifications } from './Components/Queries';
import build_data from './build.json'

const getEnvironmentName = () => {
  return build_data.name
}
datadogRum.init({

  applicationId: '785eeb41-021e-44b0-b9d1-609cc421df84',
  clientToken: 'pub7606f19966c9b2ea315ad860c72890c7',
  site: 'datadoghq.eu',
  service: 'ssp.frontend',
  env: getEnvironmentName(),
  // Specify a version number to identify the deployed version of your application in Datadog 
  // version: '1.0.0',
  sampleRate: 100,
  replaySampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: 'allow',
  allowedTracingUrls: [
    amplifyconfig.aws_appsync_graphqlEndpoint.replace("/graphql"),
    (url) => url.startsWith(amplifyconfig.aws_appsync_graphqlEndpoint.replace("/graphql"))
  ]


});



datadogRum.startSessionReplayRecording();

function toDataUri(svg) {

  let dataUri = "data:image/svg+xml," + encodeURIComponent(svg);
  return dataUri
}

var notificationIcons = {
  notificationBell: `<svg xmlns='http://www.w3.org/2000/svg' focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CircleNotificationsIcon"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 16.5c-.83 0-1.5-.67-1.5-1.5h3c0 .83-.67 1.5-1.5 1.5zm5-2.5H7v-1l1-1v-2.61C8 9.27 9.03 7.47 11 7v-.5c0-.57.43-1 1-1s1 .43 1 1V7c1.97.47 3 2.28 3 4.39V14l1 1v1z"></path></svg>`,
  notificationsDefault: `<svg xmlns='http://www.w3.org/2000/svg' focusable='false' aria-hidden='true' ><path d='M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z'></path></svg>`,
  GroupNotification: `<svg xmlns='http://www.w3.org/2000/svg' focusable='false' aria-hidden='true' ><path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"></path></svg>`
}



const drawerWidth = 240;


TimeAgo.addDefaultLocale(en)

// Create formatter (English).
const timeAgo = new TimeAgo('en-US')


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));



Amplify.configure(amplifyconfig,{
  
  Auth: {
    tokenProvider:  {
      getTokens: async (forceRefresh) => {
        if(forceRefresh){

        }
        return {
          accessToken: oktaAuth.getIdToken(),
          idToken: oktaAuth.getIdToken(),
        }
      }
    }
  },
  API: {
      GraphQL: {
        endpoint: amplifyconfig.aws_appsync_graphqlEndpoint,
      }
    }
});

const theme = {
  name: "dark-theme",
  overrides: [defaultDarkModeOverride],
}; 


const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

/*oktaAuth.start()*/

//const AuthApp = withOktaAuth(class AuthApp extends Component {

export default withOktaAuth(class AuthApp extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.addNotification = this.addNotification.bind(this)
    this.setUserInfo = this.setUserInfo.bind(this)
    this._setState = this.setState
    this.setState = this.setStateWhenMounted.bind(this)
    this.notificationSubs = this.notificationSubs.bind(this)
    this.getTeamFeatures = this.getTeamFeatures.bind(this)
    oktaAuth.start()


    this.restoreOriginalUri = async (_oktaAuth, originalUri) => {
      window.history.pushState('', toRelativeUrl(originalUri || '/', window.location.origin))
    };

    this.state = {
      isAuthenticated: false,
      showAppsMenu: false,
      isAdmin: false,
      isTeamAdmin: false,
      isTeamManager: false,
      notifications: [],
      userGroups: [],
      userInfo: {},
      pendingAuditEntries: 0,
      teamSettings: {}
    }

    oktaAuth.isAuthenticated().then(o => {
      this.setState({
        isAuthenticated: o,
        ready: true,
      })
      if (oktaAuth.token) {
        this.setState({
          isPreview: ["https://okta.tools.dev.assaabloyglobalsolutions.net", "https://assaabloyglobalsolutions.oktapreview.com"].includes(oktaAuth.token.decode(oktaAuth.getIdToken()).payload.iss)
        })
      }
      if (o) {

        this.setUserInfo().then(o => {
          this.notificationSubs()
        })

      }
    })




    this.keyCount = 0;
    this.getKey = this.getKey.bind(this);



  }

  setStateWhenMounted(obj) {
    if (this._isMounted) {
      return this._setState(obj)
    }

  }

  componentDidMount() {
    this._isMounted = true
    this.sub()
    EventRegister.addEventListener('infoEvent', (data) => {
      this.notify(data)
    })
    EventRegister.addEventListener('notificationEvent', (data) => {
      this.addNotification(data)
    })

    Queries.listAuditEntries({
      'and': [
        { 'Action': { 'ne': 'RemoveOrDisable' } },
        { 'Action': { 'ne': 'Keep' } }
      ]
    }).then(auditEntries => {
      this.setState({ pendingAuditEntries: auditEntries.length })
    })







  }

  componentWillUnmount() {

    this._isMounted = false
    EventRegister.removeEventListener('infoEvent')
    EventRegister.removeEventListener('notificationEvent')
    EventRegister.removeEventListener('userInfoEvent')
    try {
      this.userNotificationSubscription.unsubscribe()
    }
    catch { }


  }
  async setUserInfo() {

    if (!this._isMounted) {
      console.debug("Not mounted!")
      return
    }

    console.debug("setUserInfo")
    if (this.state.isAuthenticated) {
      Queries.getOktaUser().then(o => {
        datadogRum.setUser({
          id: o.preferred_username,
          name: o.name,
          email: o.preferred_username,
          isManager: o.groups.find(g => { return g.match("^Team-[A-Za-z0-9]+-Manager") }) != undefined ? true : false,
          isAdministrator: o.groups.find(g => { return g.match("^Team-[A-Za-z0-9]+-Administrator") }) != undefined ? true : false,
        })

        if (o.groups) {
          var new_state = {
            userInfo: o,
            userGroups: o.groups,
            isTeamManager: o.groups.find(g => { return g.match("^Team-[A-Za-z0-9]+-Manager") }) != undefined,
            isTeamAdmin: o.groups.find(g => { return g.match("^Team-[A-Za-z0-9]+-Administrator") }) != undefined,
            isAdmin: o.groups.find(g => { return g == "App-4SP-Admin" }) != undefined,
            isAccountReportReceiver: o.groups.find(g => { return g.match("^AwsAccount-[A-Za-z0-9]+-ReportReceiver") }) != undefined,
            ready: true,
            isPreview: ["https://okta.tools.dev.assaabloyglobalsolutions.net", "https://assaabloyglobalsolutions.oktapreview.com"].includes(oktaAuth.token.decode(oktaAuth.getIdToken()).payload.iss)
          }
          console.debug("Setting state: ", new_state)
          this.setState(new_state)
          this.getTeamFeatures(o.groups)
          this.forceUpdate()

          console.debug("Emitting event")
          window.dispatchEvent(new CustomEvent('userInfoEvent', { userInfo: o }))
        }


      })
    }

  }
  getKey() {
    if (!this._isMounted) return
    return this.keyCount++;
  }

  addNotification(data) {
    if (!this._isMounted) return

    var notification = {
      image: null,
      message: data.Title,
      receivedTime: timeAgo.format(new Date(data.createdAt))
    }
    if (data.id) {
      notification.key = data.id
    }
    else {
      notification.key = this.getKey()
    }
    if (data.Url) {
      notification['detailPage'] = data.Url
    }
    else {
      if (data.ImageKey == "AccessRequest") {
        notification['detailPage'] = "/access/approve"
      }
    }


    if (notificationIcons[data.ImageKey] != undefined) {
      notification.image = toDataUri(notificationIcons[notification['ImageKey']])
    }
    else {
      notification.image = toDataUri(notificationIcons.notificationBell)
    }




    var notifications = this.state.notifications
    notifications.push(notification)

    this.setState({ notifications: notifications })


  }




  async sub() {
    oktaAuth.authStateManager.subscribe((authState) => {

      console.debug("AuthState events: ", authState.isAuthenticated)
      console.debug("State: ", this.state.isAuthenticated)

      if (authState.isAuthenticated) {
        console.debug('isAuthenticated')

        if (!this.state.isAuthenticated) {
          this.setState({ isAuthenticated: true })
          this.setUserInfo()
          this.notificationSubs()
        }

      }

      if (this.state.isAuthenticated && !authState.isAuthenticated) {
        console.debug("Was unauthed")
        this.setState({ isAuthenticated: false })
      }
      this.setState({ isAuthenticated: authState.isAuthenticated })

      if (!authState.isAuthenticated) {
        console.debug("Is not authenticated")
        this.setState({ isAuthenticated: false })
        return
      }
      if (!this.state.userInfo) {
        this.setUserInfo()
      }




    });
  }

  async getTeamFeatures(groups) {
    var added = []
    var subq = []
    var _teamSettings = {}
    groups.filter(o => { return o.match(/^Team-.*/) }).forEach((group, ix) => {

      group = group.replace(/-(Manager|Administrator)/, "")
      if (!added.includes(group)) {
        _teamSettings[group] = {}
        subq.push(
          `q${ix}:getTeamConfig(id: "${group}") {id AccessRequestDenySelfApproval AuditReviewEnabled createdAt updatedAt}`
        )
      }
    })

    Queries.gqlQuery(
      `query GetTeamConfigs{
        ${subq.join('\n')}
      }`
    ).catch((err) => {
      if (err.data) {
        return err.data
      }
      else { throw (err) }
    }).then(teamSettings => {
      Object.values(teamSettings).filter(o => { return o != null }).forEach(o => {
        var teamId = o['id']
        delete o.createdAt
        delete o.updatedAt
        _teamSettings[teamId] = o

        if (o.AuditReviewEnabled) {
          this.setState({ auditMenuEnabled: true })
        }
      })

      localStorage['4sp-teamsettings'] = JSON.stringify(_teamSettings)
      this.setState({ teamSettings: _teamSettings })
    })

  }

  async notificationSubs() {

    if (this.state.pollingNotifications) {
      return
    }

    console.debug("Updating notifications")
    this.setState({ pollingNotifications: true })
    listUserNotifications({ Read: { eq: false } }).then(data => {
      return data.sort((a, b) => (a.createdAt < b.createdAt) ? 1 : -1)
    }).then(dataArr => {
      var notificationList = []
      dataArr.forEach(data => {


        if (data.ImageKey == "AccessRequest") {
          data['Url'] = "/access/approve"
        }


        var notification = data

        notification.image = toDataUri(notificationIcons.notificationBell)
        if (data.Url) {
          notification['detailPage'] = data.Url
        }
        else {
          if (data.ImageKey == "AccessRequest") {
            notification['detailPage'] = "/access/approve"
          }
        }

        notificationList.push(notification)

      })
      this.setState({ notifications: notificationList.sort((a, b) => (a.createdAt > b.createdAt) ? 1 : -1) })
      this.setState({ userNotificationsFetched: true })
      console.debug(this.state.notifications)
      setTimeout(() => { this.notificationSubs() }, 60 * 1000)
    })

  }

  async login() {
    if (!this._isMounted) return
    await oktaAuth.signInWithRedirect({
      redirectUri: oktaConfig.redirectUri,
    });

    return (<div>Signing in...</div>)
  }


  async notify(data) {
    if (!this._isMounted) return
    var level = data['level']
    if (level == 'success') {
      toast.success(data['message'])
    }
    if (level == 'warning') {
      toast.warning(data['message'])
    }
    if (level == 'error') {
      toast.error(data['message'])
    }
    if (level == 'promise') {
      toast.promise(data['message'])
    }

  }

  async logout() {
    await oktaAuth.signOut('/');
  }


  render() {

    return (
      <MuiThemeProvider theme={darkTheme}>
      <ThemeProvider colorMode='dark' theme={theme}>
      <Router>
        <Security oktaAuth={oktaAuth} restoreOriginalUri={this.restoreOriginalUri} >
          
            
              <ToastContainer position='top-left' />
              <main>
                <Grid key={shortid.generate()} paddingLeft={4} container spacing={4}>
                  <CssBaseline />
                  {
                    this.state.isAuthenticated ? (
                      <AppBar position="absolute" sx={{
                        height: "100px",
                        paddingTop: "16px",
                        paddingBottom: "16px"
                      }}>
                        <Toolbar>
                          <a href="/">
                            <Logo sx={{ pt: 3 }} />
                          </a>
                          <Grid key={shortid.generate()} container>
                            <Grid key={shortid.generate()} item>
                              <Typography

                                component="caption"
                                variant="h5"
                                boxShadow={true}
                                color="#78909c"
                                noWrap
                                fontStyle={'bold'}
                                sx={{ flexGrow: 1, ml: 3 }}
                                fontFamily={'sans-serif'}
                              >
                                Self-Service Portal
                              </Typography>
                            </Grid>
                            <Grid key={shortid.generate()} item sx={{ pt: 1, ml: 4 }}>

                            </Grid>
                            {
                              this.state.isPreview ?
                                <Grid key={shortid.generate()} item sx={{ pt: 0, ml: 4 }}>
                                  <Typography variant='h5' color={'yellowgreen'}>TEST ENVIRONMENT</Typography>
                                  <Typography variant='overline'>Env: {build_data.name}<br/>Date: {build_data.date}</Typography>
                                  

                                  <Button
                                    onClick={(event) => {
                                      console.debug("ENV::", getEnvironmentName())
                                    }}
                                  >
                                    Test
                                  </Button>

                                </Grid>
                                : null
                            }

                          </Grid>
                          <NotificationsMenu data={this.state.notifications} />
                          
                          <IconButton onClick={(e) => oktaAuth.signOut()} color='inherit' >
                            <LogoutIcon />
                          </IconButton>
                        </Toolbar>
                      </AppBar>) :
                      (null)
                  }

                  <Grid key={shortid.generate()} item xs={12} sm={12} md={12} lg={2.8} xl={2.8} fontStyle={'h1'}>
                    {
                      this.state.isAuthenticated ? (
                        <div>
                          <MenuList sx={{ pt: 15 }}>
                            <MenuItem key={this.getKey()} component={NavLink} to={'/'} color="inherit" aria-label={'Home'}>
                              <Home sx={{ mr: 2 }} /> <Typography variant={"h5"}>Home</Typography>
                            </MenuItem>
                            <MenuItem key={this.getKey()} onClick={(e) => { e.preventDefault() }} component={NavLink} to={'/modules/'} color="inherit" aria-label={'Modules'}>
                              <CallToActionIcon sx={{ mr: 2 }} /> <Typography variant={"h5"}>Modules</Typography>
                            </MenuItem>
                            <ListModules groups={this.state.userGroups} />
                            <Divider sx={{ my: 1 }} />
                            <MenuList>

                              {
                                (this.state.isTeamAdmin || this.state.isTeamManager) ?
                                  <MenuItem
                                    aria-label="Team Administration"
                                    key={this.getKey()}
                                    onClick={(e) => {
                                      e.preventDefault()
                                      this.setState({ teamMenuVisible: !(this.state.teamMenuVisible || false) })
                                    }}
                                  >
                                    <GroupsIcon sx={{ mr: 2 }} />

                                    <Typography sx={{ pt: 0.8 }} align='left'>
                                      {
                                        this.state.teamMenuVisible || false ? <ArrowDropDownIcon /> : <ArrowRight />
                                      }
                                    </Typography>
                                    <Typography variant={"h5"}>Team Administration</Typography>
                                  </MenuItem> : null
                              }
                              {
                                (this.state.isTeamAdmin || this.state.isTeamManager) ?
                                  <Collapse in={this.state.teamMenuVisible || false}>
                                    <MenuList sx={{ ml: 6 }}>
                                      <TeamAdministration userInfo={this.state.userInfo} />
                                    </MenuList>
                                  </Collapse> : null
                              }

                              <MenuItem
                                key={this.getKey()}
                                color="inherit"
                                aria-label={'Apps&Roles'}
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.setState({ showAppsMenu: !this.state.showAppsMenu })
                                }}
                              >
                                <AppsIcon sx={{ mr: 2 }} />
                                <Typography sx={{ pt: 0.8 }} align='left'>
                                  {
                                    this.state.showAppsMenu || false ? <ArrowDropDownIcon /> : <ArrowRight />
                                  }
                                </Typography>
                                <Typography variant={"h5"}>Applications & Roles</Typography>
                              </MenuItem>

                              <Collapse in={this.state.showAppsMenu}>
                                <MenuList sx={{ ml: 2 }}>
                                  <MenuItem key={this.getKey()} component={NavLink} to={'/access/request'}  >
                                    <AppRegistrationIcon sx={{ mr: 1 }} />Request new apps & roles
                                  </MenuItem >
                                  <MenuItem key={this.getKey()} component={NavLink} to={'/access/view-requests'} >
                                    <AppsIcon sx={{ mr: 1 }} /> Your requests
                                  </MenuItem >
                                  <MenuItem key={this.getKey()} component={NavLink} to={'/access/approve'}>
                                    <ApprovalIcon sx={{ mr: 1 }} /> Approve/Deny requests
                                  </MenuItem >
                                </MenuList>
                              </Collapse>
                              {
                                this.state.auditMenuEnabled ?
                                  <div>
                                    <MenuItem key={this.getKey()} color="inherit" aria-label={'Audit'} onClick={(e) => { e.preventDefault(); this.setState({ showAuditMenu: !this.state.showAuditMenu }) }} >
                                      <Badge
                                        badgeContent={this.state.showAuditMenu ? 0 : this.state.pendingAuditEntries}
                                        color="warning"
                                        anchorOrigin={{
                                          vertical: 'top',
                                          horizontal: 'left',
                                        }}
                                      >
                                        <Visibility sx={{ mr: 2 }} />
                                      </Badge>

                                      <Typography sx={{ pt: 0.8 }} align='left'>
                                        {
                                          this.state.showAuditMenu || false ? <ArrowDropDownIcon /> : <ArrowRight />
                                        }
                                      </Typography>
                                      <Typography variant={"h5"}>Audit/Review</Typography>
                                    </MenuItem>
                                    <Collapse in={this.state.showAuditMenu}>
                                      <MenuList sx={{ ml: 2 }}>
                                        <MenuItem key={this.getKey()} component={NavLink} to={'/audit/review'}  >
                                          <Badge
                                            badgeContent={this.state.pendingAuditEntries}
                                            color="warning"

                                            anchorOrigin={{
                                              vertical: 'top',
                                              horizontal: 'left',
                                            }}
                                          >
                                            <VerifiedUser sx={{ mr: 1 }} />
                                            Review user access
                                          </Badge>
                                        </MenuItem >
                                        <MenuItem key={this.getKey()} component={NavLink} to={'/audit/view'}  >
                                          <TableRows sx={{ mr: 1 }} />
                                          View performed audits
                                        </MenuItem >
                                        <MenuItem key={this.getKey()} component={NavLink} to={'/audit/launch'}  >
                                          <PlayCircleFilled sx={{ mr: 1 }} />
                                          Manually launch audits
                                        </MenuItem >
                                      </MenuList>
                                    </Collapse>
                                  </div>
                                  : null
                              }



                              {
                                this.state.isAdmin ?
                                  <div>
                                    <MenuItem hidden={this.state.isAdmin != true} key={this.getKey()} color="inherit" aria-label={'AdminTools'} onClick={(e) => { e.preventDefault(); this.setState({ showAdminMenu: !this.state.showAdminMenu }) }} >
                                      <AdminPanelSettings sx={{ mr: 2 }} />
                                      <Typography sx={{ pt: 0.8 }} align='left'>
                                        {
                                          this.state.showAdminMenu || false ? <ArrowDropDownIcon /> : <ArrowRight />
                                        }
                                      </Typography>
                                      <Typography variant={"h5"}>Admin</Typography>
                                    </MenuItem>

                                    <Collapse in={this.state.showAdminMenu}>
                                      <MenuList sx={{ ml: 2 }}>
                                        <MenuItem key={this.getKey()} component={NavLink} to={'/admin/apps'}  >
                                          <Key sx={{ mr: 1 }} />Apps & Roles
                                        </MenuItem >
                                        <MenuItem key={this.getKey()} component={NavLink} to={'/admin/teams'}  >
                                          <GroupWork sx={{ mr: 1 }} />Teams
                                        </MenuItem >
                                        <MenuItem key={this.getKey()} component={NavLink} to={'/admin/businessareas'}  >
                                          <GroupWork sx={{ mr: 1 }} />Business Areas
                                        </MenuItem >
                                        <MenuItem key={this.getKey()} component={NavLink} to={'/admin/billingentities'}  >
                                          <GroupWork sx={{ mr: 1 }} />Billing Entities
                                        </MenuItem >
                                        <MenuItem key={this.getKey()} component={NavLink} to={'/admin/pricelist'}  >
                                          <GroupWork sx={{ mr: 1 }} />Price List
                                        </MenuItem >
                                        <MenuItem key={this.getKey()} component={NavLink} to={'/admin/users'}  >
                                          <People sx={{ mr: 1 }} />Users
                                        </MenuItem >
                                        <MenuItem key={this.getKey()} component={NavLink} to={'/admin/userreports'}  >
                                          <People sx={{ mr: 1 }} />User reports
                                        </MenuItem >
                                        <MenuItem key={this.getKey()} component={NavLink} to={'/admin/awsaccounts'}  >
                                          <AccountBox sx={{ mr: 1 }} />AwsAccounts
                                        </MenuItem >
                                        <MenuItem key={this.getKey()} component={NavLink} to={'/admin/announcements'}  >
                                          <AccountBox sx={{ mr: 1 }} />Announcements
                                        </MenuItem >
                                      </MenuList>
                                    </Collapse>
                                  </div>
                                  : null
                              }



                              <Divider sx={{ my: 1 }} />

                              <MenuItem key={this.getKey()} component={NavLink} to={'/about'} color="inherit">
                                <HelpCenterIcon sx={{ mr: 2 }} /> Documentation
                              </MenuItem>
                              <MenuItem key={this.getKey()} component={NavLink} to={'/contact'} color="inherit">
                                <BugReport sx={{ mr: 2 }} /> Request features & Bug report
                              </MenuItem>
                              <MenuItem key={this.getKey()} component={NavLink} to={'/me'} color="inherit">
                                <Person sx={{ mr: 2 }} /> My account
                              </MenuItem>
                            </MenuList>


                          </MenuList>
                          <Divider />




                        </div>
                      )
                        : (null)
                    }

                  </Grid>
                  <Grid key={shortid.generate()} item mt={15} ml={4} lg={8} minHeight={'800px'}>
                    {
                      !(this.state.isAuthenticated ? true : false) && this.state.ready ?
                        <MinimalRoutes parent={this} oktaAuth={oktaAuth} /> :
                        (this.state.isAuthenticated ? true : false) && this.state.ready ?
                          <GetRoutes oktaAuth={oktaAuth} rumInfo={datadogRum.getInternalContext() ? datadogRum.getInternalContext() : undefined} userInfo={this.state.userInfo} /> :
                          null
                    }
                  </Grid>

                </Grid>
                <Grid key={shortid.generate()} container spacing={4}>
                  <Grid key={shortid.generate()} item mt={15} ml={6} lg={6} md={6} sm={6}>

                  </Grid>
                  <Grid key={shortid.generate()} item mt={15} ml={4} lg={4} md={4} sm={4}>
                    <footer>Copyright Global Solutions | build_date: {build_data.date}</footer>
                  </Grid>
                  <Grid key={shortid.generate()} item mt={15} ml={4} lg={4} md={4} sm={4}>

                  </Grid>
                </Grid>

              </main>
            
          
        </Security >
        
      </Router>
      </ThemeProvider >
      </MuiThemeProvider>
    )
  }
})


/*

function getRouter() {
  return {
    "location": useLocation(),
    "navigate": useNavigate(),
    "params": useParams()
  }
}

export default function () {
  return (<Router><AuthApp /></Router>);
}
*/