import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import awsExports from '../amplifyconfiguration';
import React, { Component, useRef } from 'react';
import { getTypographyUtilityClass, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Tooltip, Typography } from '@mui/material';
import { AddModerator, AppRegistration, Apps, Circle, Group } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { oktaAuth } from '../Components/Auth';
import { PageLoader } from '../Components/Loader';
import { getOktaUser, listAnnouncements } from '../Components/Queries';
import shortid from 'shortid';



class MyAccountDashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ready: false
        }
    }



    async componentDidMount() {
        if (!this.props.userInfo) {
            getOktaUser().then(userInfo => {
                this.setState({ userInfo: userInfo, ready: true })
                console.debug(this.state.userInfo)

            })
        }
    }


    render() {

        if (!this.state.ready) {
            return <PageLoader ready={this.state.ready} />
        }
        return (
            <Paper sx={{px:2}}>
                <Typography variant='h2'>
                {this.state.userInfo.preferred_username}
                </Typography>
    
                <Grid mt={3} key={shortid.generate()} container>
                    <Grid borderBottom={'1px solid'} mt={2} xs={1} item><Typography  ><b>Username: </b></Typography></Grid>
                    <Grid borderBottom={'1px solid'} mt={2} md={4} item><Typography > {this.state.userInfo.preferred_username}</Typography></Grid>
                    <Grid  mt={2} xs={7} item></Grid>
                    <Grid borderBottom={'1px solid'} mt={2} xs={1} item><Typography  ><b>Given Name: </b></Typography></Grid>
                    <Grid borderBottom={'1px solid'} mt={2} md={4} item><Typography > {this.state.userInfo.given_name}</Typography></Grid>
                    <Grid mt={2} xs={7} item></Grid>
                    <Grid borderBottom={'1px solid'} mt={2} xs={1} item><Typography  ><b>Last Name: </b></Typography></Grid>
                    <Grid borderBottom={'1px solid'} mt={2} md={4} item><Typography > {this.state.userInfo.family_name}</Typography></Grid>
                    <Grid  mt={2} xs={7} item></Grid>
                    <Grid borderBottom={'1px solid'} mt={2} xs={1} item><Typography  ><b>Display Name: </b></Typography></Grid>
                    <Grid borderBottom={'1px solid'} mt={2} md={4} item><Typography > {this.state.userInfo.name}</Typography></Grid>
                    <Grid mt={2} xs={7} item></Grid>

                    <Grid borderBottom={'1px solid'} mt={2} xs={1} item><Typography  ><b>Teams: </b></Typography></Grid>
                    <Grid borderBottom={'1px solid'} mt={2} md={4} item>
                        <List
                             sx={{
                                width: '100%',
                                position: 'relative',
                                overflow: 'auto',
                                '& ul': { padding: 0 },
                              }}
                        
                        >
                            {
                                this.state.userInfo.groups.filter(o => {return o.startsWith("Team-")}).sort().map(o => {
                                    return (
                                        <ListItem sx={{padding: 0}}>
                                            <ListItemText primary={o} />
                                        </ListItem>
                                    )
                                })
                            }
                        </List>
                        
                    </Grid>
                    <Grid  mt={2} xs={7} item></Grid>

                    <Grid borderBottom={'1px solid'} mt={2} xs={1} item><Typography  ><b>Applications: </b></Typography></Grid>
                    <Grid borderBottom={'1px solid'} mt={2} md={4} item>
                        <List
                             sx={{
                                width: '100%',
                                position: 'relative',
                                overflow: 'auto',
                                '& ul': { padding: 0 },
                              }}
                        
                        >
                            {
                                this.state.userInfo.groups.filter(o => {return o.startsWith("App-")}).sort().map(o => {
                                    return (
                                        <ListItem sx={{padding: 0}}>
                                            <ListItemText primary={o} />
                                        </ListItem>
                                    )
                                })
                            }
                        </List>
                        
                    </Grid>
                    <Grid  mt={2} xs={7} item></Grid>
                    
                    <Grid borderBottom={'1px solid'} mt={2} xs={1} item><Typography  ><b>AWS account roles: </b></Typography></Grid>
                    <Grid borderBottom={'1px solid'} mt={2} md={4} item>
                        <List
                             sx={{
                                width: '100%',
                                position: 'relative',
                                overflow: 'auto',
                                '& ul': { padding: 0 },
                              }}
                        
                        >
                            {
                                this.state.userInfo.groups.filter(o => {return o.startsWith("AwsAccount-")}).sort().map(o => {
                                    return (
                                        <ListItem sx={{padding: 0}}>
                                            <ListItemText primary={o} />
                                        </ListItem>
                                    )
                                })
                            }
                        </List>
                        
                    </Grid>
                    <Grid  mt={2} xs={7} item></Grid>

                    <Grid borderBottom={'1px solid'} mt={2} xs={1} item><Typography  ><b>Azure roles/subscriptions: </b></Typography></Grid>
                    <Grid borderBottom={'1px solid'} mt={2} md={4} item>
                        <List
                             sx={{
                                width: '100%',
                                position: 'relative',
                                overflow: 'auto',
                                '& ul': { padding: 0 },
                              }}
                        
                        >
                            {
                                this.state.userInfo.groups.filter(o => {return (o.startsWith("AzSub-") || o.startsWith("Azure-"))}).sort().map(o => {
                                    return (
                                        <ListItem sx={{padding: 0}}>
                                            <ListItemText primary={o} />
                                        </ListItem>
                                    )
                                })
                            }
                        </List>
                        
                    </Grid>
                    <Grid  mt={2} xs={7} item></Grid>

                    <Grid borderBottom={'1px solid'} mt={2} xs={1} item><Typography  ><b>Other: </b></Typography></Grid>
                    <Grid borderBottom={'1px solid'} mt={2} md={4} item>
                        <List
                             sx={{
                                width: '100%',
                                position: 'relative',
                                overflow: 'auto',
                                '& ul': { padding: 0 },
                              }}
                        
                        >
                            {
                                this.state.userInfo.groups.filter(o => {return !(o == 'Everyone' || o.startsWith("Team-") || o.startsWith("AzSub-") || o.startsWith("Azure-") || o.startsWith("AwsAccount-") || o.startsWith("App-") )}).sort().map(o => {
                                    return (
                                        <ListItem sx={{padding: 0}}>
                                            <ListItemText primary={o} />
                                        </ListItem>
                                    )
                                })
                            }
                        </List>
                        
                    </Grid>
                    <Grid  mt={2} xs={7} item></Grid>
                    
                    

                </Grid>

            </Paper>

            

        );
    }
}
export default function MyAccount() {
    return <MyAccountDashboard />;
}

