/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTeamConfig = /* GraphQL */ `
  query GetTeamConfig($id: ID!) {
    getTeamConfig(id: $id) {
      id
      AccessRequestDenySelfApproval
      AuditReviewEnabled
      AuditReviewSchedule
      SecurityEventNotifications {
        Slack {
          Enabled
          WebhookUri
        }
        DatadogEvent {
          Enabled
          ApiKey
        }
        EmailOnlyOnSuspectedThreats
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTeamConfigs = /* GraphQL */ `
  query ListTeamConfigs(
    $filter: ModelTeamConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamConfigs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        AccessRequestDenySelfApproval
        AuditReviewEnabled
        AuditReviewSchedule
        SecurityEventNotifications {
          EmailOnlyOnSuspectedThreats
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getApprovalWorkflow = /* GraphQL */ `
  query GetApprovalWorkflow($id: ID!) {
    getApprovalWorkflow(id: $id) {
      Type
      Target
      TargetDisplayName
      Steps {
        AccessApprover
        ConfigData
      }
      ConfigData
      Teams
      CanWrite
      id
      createdAt
      updatedAt
    }
  }
`;
export const listApprovalWorkflows = /* GraphQL */ `
  query ListApprovalWorkflows(
    $filter: ModelApprovalWorkflowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApprovalWorkflows(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        Type
        Target
        TargetDisplayName
        Steps {
          AccessApprover
          ConfigData
        }
        ConfigData
        Teams
        CanWrite
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getApprovalWorkflowQueueItem = /* GraphQL */ `
  query GetApprovalWorkflowQueueItem($id: ID!) {
    getApprovalWorkflowQueueItem(id: $id) {
      AccessRequest {
        Status
        ApprovalWorkflow {
          Type
          Target
          TargetDisplayName
          ConfigData
          Teams
          CanWrite
          id
          createdAt
          updatedAt
        }
        Username
        Reason
        TeamId
        ApprovalWorkflowQueue {
          nextToken
        }
        Readers
        id
        createdAt
        updatedAt
        accessRequestApprovalWorkflowId
      }
      ApprovalWorkflowStepType {
        AccessApprover
        ConfigData
      }
      Status
      TargetApproverIds
      TargetUsernameId
      TargetRoleId
      Reason
      ApprovedBy
      ApproverIdentity
      ApprovalDate
      id
      createdAt
      updatedAt
      accessRequestApprovalWorkflowQueueId
    }
  }
`;
export const listApprovalWorkflowQueueItems = /* GraphQL */ `
  query ListApprovalWorkflowQueueItems(
    $filter: ModelApprovalWorkflowQueueItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApprovalWorkflowQueueItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        AccessRequest {
          Status
          Username
          Reason
          TeamId
          Readers
          id
          createdAt
          updatedAt
          accessRequestApprovalWorkflowId
        }
        ApprovalWorkflowStepType {
          AccessApprover
          ConfigData
        }
        Status
        TargetApproverIds
        TargetUsernameId
        TargetRoleId
        Reason
        ApprovedBy
        ApproverIdentity
        ApprovalDate
        id
        createdAt
        updatedAt
        accessRequestApprovalWorkflowQueueId
      }
      nextToken
    }
  }
`;
export const getAccessRequest = /* GraphQL */ `
  query GetAccessRequest($id: ID!) {
    getAccessRequest(id: $id) {
      Status
      ApprovalWorkflow {
        Type
        Target
        TargetDisplayName
        Steps {
          AccessApprover
          ConfigData
        }
        ConfigData
        Teams
        CanWrite
        id
        createdAt
        updatedAt
      }
      Username
      Reason
      TeamId
      ApprovalWorkflowQueue {
        items {
          Status
          TargetApproverIds
          TargetUsernameId
          TargetRoleId
          Reason
          ApprovedBy
          ApproverIdentity
          ApprovalDate
          id
          createdAt
          updatedAt
          accessRequestApprovalWorkflowQueueId
        }
        nextToken
      }
      Readers
      id
      createdAt
      updatedAt
      accessRequestApprovalWorkflowId
    }
  }
`;
export const listAccessRequests = /* GraphQL */ `
  query ListAccessRequests(
    $filter: ModelAccessRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccessRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        Status
        ApprovalWorkflow {
          Type
          Target
          TargetDisplayName
          ConfigData
          Teams
          CanWrite
          id
          createdAt
          updatedAt
        }
        Username
        Reason
        TeamId
        ApprovalWorkflowQueue {
          nextToken
        }
        Readers
        id
        createdAt
        updatedAt
        accessRequestApprovalWorkflowId
      }
      nextToken
    }
  }
`;
export const getUserReport = /* GraphQL */ `
  query GetUserReport($id: ID!) {
    getUserReport(id: $id) {
      Username
      UserGroups
      RumSessionId
      ReportType
      Description
      id
      createdAt
      updatedAt
    }
  }
`;
export const listUserReports = /* GraphQL */ `
  query ListUserReports(
    $filter: ModelUserReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        Username
        UserGroups
        RumSessionId
        ReportType
        Description
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAwsAccount = /* GraphQL */ `
  query GetAwsAccount($id: ID!) {
    getAwsAccount(id: $id) {
      id
      Name
      DisplayName
      BusinessAreaId
      BusinessArea {
        id
        DisplayName
        createdAt
        updatedAt
      }
      Email
      ManagingTeams
      MainAccountId
      MainAccount {
        id
        Name
        DisplayName
        BusinessAreaId
        BusinessArea {
          id
          DisplayName
          createdAt
          updatedAt
        }
        Email
        ManagingTeams
        MainAccountId
        MainAccount {
          id
          Name
          DisplayName
          BusinessAreaId
          Email
          ManagingTeams
          MainAccountId
          IsMainAccount
          createdAt
          updatedAt
        }
        SubAccounts {
          nextToken
        }
        IsMainAccount
        createdAt
        updatedAt
      }
      SubAccounts {
        items {
          id
          Name
          DisplayName
          BusinessAreaId
          Email
          ManagingTeams
          MainAccountId
          IsMainAccount
          createdAt
          updatedAt
        }
        nextToken
      }
      IsMainAccount
      createdAt
      updatedAt
    }
  }
`;
export const listAwsAccounts = /* GraphQL */ `
  query ListAwsAccounts(
    $filter: ModelAwsAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAwsAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Name
        DisplayName
        BusinessAreaId
        BusinessArea {
          id
          DisplayName
          createdAt
          updatedAt
        }
        Email
        ManagingTeams
        MainAccountId
        MainAccount {
          id
          Name
          DisplayName
          BusinessAreaId
          Email
          ManagingTeams
          MainAccountId
          IsMainAccount
          createdAt
          updatedAt
        }
        SubAccounts {
          nextToken
        }
        IsMainAccount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBusinessArea = /* GraphQL */ `
  query GetBusinessArea($id: ID!) {
    getBusinessArea(id: $id) {
      id
      DisplayName
      createdAt
      updatedAt
    }
  }
`;
export const listBusinessAreas = /* GraphQL */ `
  query ListBusinessAreas(
    $filter: ModelBusinessAreaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBusinessAreas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        DisplayName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAuditEntry = /* GraphQL */ `
  query GetAuditEntry($id: ID!) {
    getAuditEntry(id: $id) {
      id
      AuditBatch
      Type
      Username
      TeamId
      TargetId
      Message
      Auditors
      Readers
      ActionReason
      Action
      ActionDate
      DueDate
      NotificationDates
      lastModifiedBy
      lastModifiedById
      lastModifiedByIP
      createdAt
      updatedAt
    }
  }
`;
export const listAuditEntries = /* GraphQL */ `
  query ListAuditEntries(
    $filter: ModelAuditEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuditEntries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        AuditBatch
        Type
        Username
        TeamId
        TargetId
        Message
        Auditors
        Readers
        ActionReason
        Action
        ActionDate
        DueDate
        NotificationDates
        lastModifiedBy
        lastModifiedById
        lastModifiedByIP
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAnnouncement = /* GraphQL */ `
  query GetAnnouncement($id: ID!) {
    getAnnouncement(id: $id) {
      Title
      Message
      Start
      End
      AudienceGroups
      CreatedBy
      id
      createdAt
      updatedAt
    }
  }
`;
export const listAnnouncements = /* GraphQL */ `
  query ListAnnouncements(
    $filter: ModelAnnouncementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnnouncements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        Title
        Message
        Start
        End
        AudienceGroups
        CreatedBy
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserNotification = /* GraphQL */ `
  query GetUserNotification($id: ID!) {
    getUserNotification(id: $id) {
      id
      Title
      Description
      Url
      ImageKey
      Username
      Read
      createdAt
      updatedAt
    }
  }
`;
export const listUserNotifications = /* GraphQL */ `
  query ListUserNotifications(
    $filter: ModelUserNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Title
        Description
        Url
        ImageKey
        Username
        Read
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEntityToBusinessAreaMapping = /* GraphQL */ `
  query GetEntityToBusinessAreaMapping($id: ID!) {
    getEntityToBusinessAreaMapping(id: $id) {
      id
      EntityId
      EntityType
      Description
      BusinessAreaId
      BusinessArea {
        id
        DisplayName
        createdAt
        updatedAt
      }
      Team
      IsBillable
      createdAt
      updatedAt
    }
  }
`;
export const listEntityToBusinessAreaMappings = /* GraphQL */ `
  query ListEntityToBusinessAreaMappings(
    $filter: ModelEntityToBusinessAreaMappingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntityToBusinessAreaMappings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        EntityId
        EntityType
        Description
        BusinessAreaId
        BusinessArea {
          id
          DisplayName
          createdAt
          updatedAt
        }
        Team
        IsBillable
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPricingList = /* GraphQL */ `
  query GetPricingList($id: ID!) {
    getPricingList(id: $id) {
      id
      ProductCode
      Description
      ApplicationGroup
      ValidForBusinessAreas
      Unit
      Cost
      createdAt
      updatedAt
    }
  }
`;
export const listPricingLists = /* GraphQL */ `
  query ListPricingLists(
    $filter: ModelPricingListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPricingLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ProductCode
        Description
        ApplicationGroup
        ValidForBusinessAreas
        Unit
        Cost
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($Username: ID!) {
    getUser(Username: $Username) {
      Username
      FirstName
      LastName
      Status
      Email
      City
      Department
      DisplayName
      Division
      Locale
      Manager
      MobilePhone
      Organization
      PreferredLanguage
      PrimaryPhone
      SecondEmail
      StreetAddress
      Timezone
      Title
      UserType
      Roles {
        RoleId
        RoleType
        RoleName
        Description
        Roles
      }
      ExpirationDate
      LastLogin
      Created
      LastUpdated
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers {
    listUsers {
      Username
      FirstName
      LastName
      Status
      Email
      City
      Department
      DisplayName
      Division
      Locale
      Manager
      MobilePhone
      Organization
      PreferredLanguage
      PrimaryPhone
      SecondEmail
      StreetAddress
      Timezone
      Title
      UserType
      Roles {
        RoleId
        RoleType
        RoleName
        Description
        Roles
      }
      ExpirationDate
      LastLogin
      Created
      LastUpdated
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($GroupId: ID!) {
    getGroup(GroupId: $GroupId) {
      GroupId
      GroupName
      GroupType
      Description
      Members
      BusinessArea
      ManagedByGroups
    }
  }
`;
export const listGroupMembers = /* GraphQL */ `
  query ListGroupMembers($GroupId: ID!) {
    listGroupMembers(GroupId: $GroupId) {
      Username
      FirstName
      LastName
      Status
      Email
      City
      Department
      DisplayName
      Division
      Locale
      Manager
      MobilePhone
      Organization
      PreferredLanguage
      PrimaryPhone
      SecondEmail
      StreetAddress
      Timezone
      Title
      UserType
      Roles {
        RoleId
        RoleType
        RoleName
        Description
        Roles
      }
      ExpirationDate
      LastLogin
      Created
      LastUpdated
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups($filter: String) {
    listGroups(filter: $filter) {
      GroupId
      GroupName
      GroupType
      Description
      Members
      BusinessArea
      ManagedByGroups
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams {
    listTeams {
      TeamId
      TeamName
      Description
      Members
      Administrators
      Managers
      BusinessArea
      AvailableRoles {
        RoleId
        RoleType
        RoleName
        Description
        Roles
      }
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($teamId: String) {
    getTeam(teamId: $teamId) {
      TeamId
      TeamName
      Description
      Members
      Administrators
      Managers
      BusinessArea
      AvailableRoles {
        RoleId
        RoleType
        RoleName
        Description
        Roles
      }
    }
  }
`;
export const getTeams = /* GraphQL */ `
  query GetTeams($teamIds: [String]!, $roles: Boolean, $members: Boolean) {
    getTeams(teamIds: $teamIds, roles: $roles, members: $members) {
      TeamId
      TeamName
      Description
      Members
      Administrators
      Managers
      BusinessArea
      AvailableRoles {
        RoleId
        RoleType
        RoleName
        Description
        Roles
      }
    }
  }
`;
export const listApps = /* GraphQL */ `
  query ListApps {
    listApps {
      ApplicationName
      ApplicationId
      Description
      Roles {
        RoleId
        RoleType
        RoleName
        Description
        Roles
      }
    }
  }
`;
export const getSelf = /* GraphQL */ `
  query GetSelf {
    getSelf {
      Username
      Roles
      LastUpdated
      Created
    }
  }
`;
export const listTeamManagedRoles = /* GraphQL */ `
  query ListTeamManagedRoles($teamId: String!) {
    listTeamManagedRoles(teamId: $teamId) {
      RoleId
      RoleType
      RoleName
      Description
      Roles
    }
  }
`;
export const listTeamRoles = /* GraphQL */ `
  query ListTeamRoles($teamId: String!) {
    listTeamRoles(teamId: $teamId) {
      RoleId
      RoleType
      RoleName
      Description
      Roles
    }
  }
`;
export const listTeamUsers = /* GraphQL */ `
  query ListTeamUsers($teamId: String!) {
    listTeamUsers(teamId: $teamId) {
      Username
      FirstName
      LastName
      Status
      Email
      City
      Department
      DisplayName
      Division
      Locale
      Manager
      MobilePhone
      Organization
      PreferredLanguage
      PrimaryPhone
      SecondEmail
      StreetAddress
      Timezone
      Title
      UserType
      Roles {
        RoleId
        RoleType
        RoleName
        Description
        Roles
      }
      ExpirationDate
      LastLogin
      Created
      LastUpdated
    }
  }
`;
export const getTeamUser = /* GraphQL */ `
  query GetTeamUser($teamId: String!, $username: String!) {
    getTeamUser(teamId: $teamId, username: $username) {
      Username
      FirstName
      LastName
      Status
      Email
      City
      Department
      DisplayName
      Division
      Locale
      Manager
      MobilePhone
      Organization
      PreferredLanguage
      PrimaryPhone
      SecondEmail
      StreetAddress
      Timezone
      Title
      UserType
      Roles {
        RoleId
        RoleType
        RoleName
        Description
        Roles
      }
      ExpirationDate
      LastLogin
      Created
      LastUpdated
    }
  }
`;
export const listImageScanReports = /* GraphQL */ `
  query ListImageScanReports($accountId: String) {
    listImageScanReports(accountId: $accountId) {
      AccountId
      AccountName
      ReportName
      ReportDate
      DownloadUrl
    }
  }
`;
export const getImageScanReport = /* GraphQL */ `
  query GetImageScanReport($accountId: String, $reportName: String) {
    getImageScanReport(accountId: $accountId, reportName: $reportName) {
      AccountId
      AccountName
      ReportName
      ReportDate
      DownloadUrl
    }
  }
`;
export const adminListGroups = /* GraphQL */ `
  query AdminListGroups($filter: String, $members: Boolean) {
    adminListGroups(filter: $filter, members: $members) {
      GroupId
      GroupName
      GroupType
      Description
      Members
      BusinessArea
      ManagedByGroups
    }
  }
`;
export const adminListUsers = /* GraphQL */ `
  query AdminListUsers($filter: String) {
    adminListUsers(filter: $filter) {
      Username
      FirstName
      LastName
      Status
      Email
      City
      Department
      DisplayName
      Division
      Locale
      Manager
      MobilePhone
      Organization
      PreferredLanguage
      PrimaryPhone
      SecondEmail
      StreetAddress
      Timezone
      Title
      UserType
      Roles {
        RoleId
        RoleType
        RoleName
        Description
        Roles
      }
      ExpirationDate
      LastLogin
      Created
      LastUpdated
    }
  }
`;
