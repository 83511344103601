// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const RoleType = {
  "TEAM": "Team",
  "AWS_ACCOUNT": "AwsAccount",
  "AZ_SUB": "AzSub",
  "APP": "App"
};

const AccessApprover = {
  "TEAM_MANAGER": "TeamManager",
  "TEAM_ADMINISTRATOR": "TeamAdministrator",
  "SUPER_ADMIN": "SuperAdmin",
  "CUSTOM": "Custom",
  "AUTO": "Auto"
};

const ActionRequestType = {
  "ROLE": "Role",
  "MFA": "MFA"
};

const AccessRequestStatus = {
  "PENDING": "Pending",
  "APPROVED": "Approved",
  "DENIED": "Denied",
  "ABORTED_BY_USER": "AbortedByUser"
};

const AuditType = {
  "SCHEDULED_ACCESS_REVIEW": "ScheduledAccessReview",
  "USER_INITIATED_ACCESS_REVIEW": "UserInitiatedAccessReview",
  "USER_INITIATED_USER_REVIEW": "UserInitiatedUserReview",
  "USER_INACTIVITY": "UserInactivity"
};

const AuditAction = {
  "KEEP": "Keep",
  "REMOVE_OR_DISABLE": "RemoveOrDisable"
};

const PricingListUnit = {
  "USERS": "Users",
  "BUSINESS_AREAS": "BusinessAreas",
  "TEAMS": "Teams",
  "LINES": "Lines"
};

const { TeamConfig, ApprovalWorkflow, ApprovalWorkflowQueueItem, AccessRequest, UserReport, AwsAccount, BusinessArea, AuditEntry, Announcement, UserNotification, EntityToBusinessAreaMapping, PricingList, TeamSecurityEventNotifications, TeamSecurityEventSlackNotification, TeamSecurityEventDatadogEvent, ApprovalWorkflowStepType, ImageScanReport, Group, Team, Application, Role, User, SelfInfo } = initSchema(schema);

export {
  TeamConfig,
  ApprovalWorkflow,
  ApprovalWorkflowQueueItem,
  AccessRequest,
  UserReport,
  AwsAccount,
  BusinessArea,
  AuditEntry,
  Announcement,
  UserNotification,
  EntityToBusinessAreaMapping,
  PricingList,
  RoleType,
  AccessApprover,
  ActionRequestType,
  AccessRequestStatus,
  AuditType,
  AuditAction,
  PricingListUnit,
  TeamSecurityEventNotifications,
  TeamSecurityEventSlackNotification,
  TeamSecurityEventDatadogEvent,
  ApprovalWorkflowStepType,
  ImageScanReport,
  Group,
  Team,
  Application,
  Role,
  User,
  SelfInfo
};