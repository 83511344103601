import '@aws-amplify/ui-react/styles.css';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { MenuItem, MenuList } from '@mui/material';
import { Amplify } from 'aws-amplify';
import awsExports from '../amplifyconfiguration';

import { Link } from 'react-router-dom';

import ModuleImageScanReports from '../Modules/ImageScanReports';
import ModuleWhitelistIP from '../Modules/WhitelistIP';


Amplify.configure(awsExports);


const modules = [
    {
        Id: 'WhitelistIp',
        Module: <ModuleWhitelistIP />,
        Title: "Whitelist IP",
        Condition: undefined

    },
    {
        Id: 'ImageScanReports',
        Module: <ModuleImageScanReports />,
        Title: "Image Scan Reports",
        Condition: function (groups) {
            if (groups) {
                return groups.find(o => {
                    return o.match(/(AwsAccount-\d+-ImageScanReportReceiver|App-4SP-Admin)/)
                }) != undefined
            }
            else {
                return false
            }

        }

    }
]

function NotFound() {
    return (
        <p>Module not found</p>
    )
}

export default function ListModules(props) {

    const listItems = modules.map((item, index) => {
        if (item.Condition) {
            var cond = item.Condition(props.groups)
        }
        else {
            var cond = false
        }

        return (
            <MenuItem component={Link} to={'/modules/' + item.Id} key={item.Id} >
                <ArrowRightIcon sx={{ mr: 1 }} />{item.Title}
            </MenuItem >
        )
    }

    );
    return (
        <MenuList sx={{ ml: 2 }}>{listItems}</MenuList>
    );
}

export { ListModules, modules };
