import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Component } from 'react';
const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));


class PrivateRoute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: true
        }

    }
    updateState() {
        this.props.OktaAuth.isAuthenticated().then(result => {
            this.setState({ isAuthenticated: result })
            console.debug(result)
        })
    }
    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    render() {
        return (
            this.result ? <Outlet /> : <Navigate to="/login" />
        )

    }
}

export default PrivateRoute