export default function Logo() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg"
			width="150" height="39" viewBox="0 0 393 100">
			<path d="M142,1.3l-13.7,36c0-11.6-11.5-14-15.2-14.9c-12.8-3.3-15.3-3.8-15.3-7.8c0-4.3,4.2-5.8,7.8-5.8c5.4,0,9.8,1.6,10.1,7.7
	h10.9c0-11.8-9.8-16.5-20.5-16.5c-9.2,0-18.9,4.9-19.2,15.1C86.2,4.4,76.7,0.1,66.5,0.1c-9.2,0-19.1,5-19.1,15.5
	c0,9.6,7.7,12.6,15.3,14.6s15.2,2.9,15.2,8.5c0,5.2-6,6.4-10,6.4c-6,0-11.3-2.7-11.3-9.5H45.8L33,1.3H21.4L2,52.6h11.4l4-11.4h19.2
	l3.9,11.4h11.7l-1.8-4.8c4.1,4.1,10.5,6,17.1,6c10.2,0,17-3.8,19.8-9.6c3.6,6.6,11.5,9.6,19.8,9.6c8,0,13.9-2.4,17.4-6.2l-1.9,4.9
	H134l4-11.4h19.2l3.9,11.4h11.7L153.5,1.3H142z M20.4,32.7L27,14h0.1l6.5,18.8L20.4,32.7z M107.4,45c-6,0-11.3-2.6-11.3-9.4h-7.5
	c-1.1-10.1-11.6-12.3-15.1-13.2c-12.8-3.3-15.3-3.8-15.3-7.7c0-4.3,4.2-5.8,7.7-5.8c5.4,0,9.8,1.6,10.1,7.7H87
	c0.5,8.8,7.9,11.6,15.2,13.6c7.5,2,15.2,2.9,15.2,8.5C117.4,43.8,111.4,45,107.4,45z M140.9,32.7l6.7-18.8h0.1l6.5,18.8L140.9,32.7z
	 M263.5,24.6c4.5-2.2,7-5.7,7-10.7c0-9.5-6.6-12.7-16.4-12.7H230v47.4L212.3,1.2h-11.6l-19.4,51.3h11.4l4-11.4h19.2l3.9,11.4h35.1
	c9.2,0,18.1-4.5,18.1-14.9C272.9,31.2,269.8,26.4,263.5,24.6L263.5,24.6z M199.6,32.7l6.7-18.8h0.1l6.4,18.8H199.6z M241.3,10h10.5
	c4,0,7.8,1,7.8,5.9c0,4.3-3,6.1-6.9,6.1h-11.4V10z M253.4,43.7h-12.1v-14h12.3c4.8,0,8.1,2.1,8.1,7.3S257.8,43.8,253.4,43.7
	L253.4,43.7z M325.7,0c-15.8,0-25.2,11.8-25.2,27c-0.1,5.6,1.4,11.1,4.4,15.9l-19,0.1l-0.1-41.9l-11.2,0.1l0.2,51.3l32.6-0.1V46
	c4.3,4.8,10.6,7.7,18.4,7.7c15.9,0,25.2-11.8,25.2-26.6C350.9,11.8,341.5,0,325.7,0z M325.7,44.2c-10.1,0-13.9-8.6-13.9-17.1
	c0-9,3.8-17.6,13.9-17.6S339.6,18,339.6,27C339.6,35.6,335.8,44.2,325.7,44.2L325.7,44.2z M379.5,1.2l-11.9,20.2l-12-20.2h-12.7
	l18.9,31.3v20h11.3V32.8l19-31.6H379.5z"/>
			<path d="M15,99.5c-8.6,0-14.1-5-14.1-14.5c-0.1-3.3,0.7-6.6,2.3-9.5c3.8-6.2,10.6-6.4,13.3-6.4c2.5-0.1,5,0.3,7.4,1.2l-1,2.5
	c-2-1-4.2-1.4-6.5-1.3c-9.3,0-11.7,6.1-11.7,12.8c0,8.5,3.7,12.9,11.3,12.9c1.4,0,2.8-0.1,4.2-0.3V86h3.6v12.1
	C21,99.1,18,99.6,15,99.5z"/>
			<path d="M29.3,99.2V66.7h3.6v32.5H29.3z" />
			<path d="M47,99.8c-6.1,0-9.6-4.4-9.6-10.9c0-3.9,1.6-11.4,10-11.4c6.1,0,9.6,4.5,9.6,10.9C57,92.3,55.4,99.8,47,99.8z M47,79.6
	c-4.2,0-5.9,3.5-5.9,9.1c0,4.9,1.6,9,6.2,9c4.2,0,5.9-3.5,5.9-9.1C53.2,83.7,51.6,79.6,47,79.6L47,79.6z"/>
			<path d="M69,99.6c-2.5,0.1-5.1-0.3-7.4-1.3V66.7h3.5v13.4c0,0,1.8-2.4,6-2.4c5.3,0,8.7,3.9,8.7,10.5S76,99.6,69,99.6z M69.2,80.1
	c-1.4,0-2.8,0.3-4.1,1v16.2c1.2,0.3,2.3,0.4,3.5,0.3c4.9,0,7.4-3.1,7.4-9.1C76.1,83.3,73.6,80.1,69.2,80.1z"/>
			<path d="M94.5,99.2v-2c-1.4,1.5-3.3,2.3-5.3,2.3c-3.9,0-6.4-2.4-6.4-5.9c-0.1-1.9,0.8-3.7,2.3-4.8c1.5-1,3.6-1.3,6.2-1.3
	c1.5,0,3.2,0.1,3.2,0.1V86c0.1-1.3,0-2.6-0.2-3.9c-0.5-1.9-2.1-2.5-4.1-2.5c-1.5,0-3,0.4-4.3,1.2l-0.9-2.2c1.9-0.7,4-1.1,6.1-1
	c5.8,0,6.5,3.3,6.7,4.1c0.2,1.8,0.3,3.6,0.2,5.4v12.2L94.5,99.2z M94.5,89.4c0,0-0.6,0-2.6,0c-1.3-0.1-2.6,0.1-3.7,0.6
	c-1.2,0.7-1.9,2-1.8,3.4c0,2.9,2.2,3.8,4.4,3.8c1.2,0,2.5-0.2,3.6-0.7L94.5,89.4z"/>
			<path d="M103.5,99.2V66.7h3.5v32.5H103.5z" />
			<path d="M127.9,99.6c-5,0-7.5-1.3-7.5-1.3l1-2.7c1.7,1.1,3.7,1.7,5.8,1.6c3.7,0,6.7-1.2,6.7-5.5c0-1.9-1-3.6-2.7-4.5l-5.5-3.5
	c-1.1-0.7-4.5-2.6-4.5-6.9c0-4.8,3.1-7.7,8.6-7.7c2.3-0.1,4.6,0.4,6.8,1.2l-0.9,2.5c-1.6-0.9-3.5-1.4-5.4-1.3
	c-0.3,0-5.9-0.4-5.9,4.7c0,2.1,1,3.2,4,5l4.4,2.8c2.9,1.8,4.4,4,4.4,7.1C137.4,95.8,134.3,99.6,127.9,99.6z"/>
			<path d="M150.3,99.8c-6.1,0-9.6-4.4-9.6-10.9c0-3.9,1.6-11.4,10-11.4c6.1,0,9.6,4.5,9.6,10.9C160.4,92.3,158.8,99.8,150.3,99.8z
	 M150.3,79.6c-4.2,0-5.9,3.5-5.9,9.1c0,4.9,1.6,9,6.2,9c4.2,0,5.9-3.5,5.9-9.1C156.6,83.7,155,79.6,150.3,79.6L150.3,79.6z"/>
			<path d="M165,99.2V66.7h3.5v32.5H165z" />
			<path d="M187.4,99.2v-2.1c-1.6,1.7-3.9,2.6-6.3,2.6c-1.4,0-5.6-0.1-6.7-4.7c-0.3-1.5-0.4-3.1-0.4-4.7V78h3.5v11.2
	c0,1.3,0,2.6,0.2,3.9c0.4,2.9,2.2,4.2,5.4,4.2c1.5,0,2.9-0.4,4.2-1V78h3.5v21.2H187.4z"/>
			<path d="M203.1,99.5c-2.8,0-4.7-0.8-5.5-3.3c-0.3-1.4-0.4-2.9-0.4-4.4V80.2h-3.1v-1.4l0.4-0.1c2.5-0.8,4.1-2.3,4.3-4.9v-0.5h1.9v4.9
	h7v2.1h-7v12.8c0,2.3,0.4,4.3,3.5,4.3c1,0,2-0.2,2.9-0.6l0.7,2.1C206.4,99.3,204.8,99.5,203.1,99.5z"/>
			<path d="M212.9,73.9c-1.2,0.1-2.1-0.8-2.2-1.9c0,0,0,0,0,0v-0.2c-0.1-1.2,0.8-2.2,1.9-2.3c0,0,0,0,0,0c0.1,0,0.2,0,0.3,0
	c1.1-0.1,2.1,0.8,2.2,1.9v0.3c0.1,1.2-0.8,2.2-1.9,2.3c0,0,0,0,0,0H212.9z M211.2,99.2V78h3.5v21.2L211.2,99.2z"/>
			<path d="M228.8,99.8c-6.1,0-9.6-4.4-9.6-10.9c0-3.9,1.6-11.4,10-11.4c6.1,0,9.6,4.5,9.6,10.9C238.8,92.3,237.2,99.8,228.8,99.8z
	 M228.8,79.6c-4.2,0-5.9,3.5-5.9,9.1c0,4.9,1.6,9,6.2,9c4.2,0,5.9-3.5,5.9-9.1C235,83.7,233.4,79.6,228.8,79.6L228.8,79.6z"/>
			<path d="M256.6,99.2V88.3c0.1-1.4,0-2.8-0.2-4.2c-0.5-3-2.5-4.1-5.4-4.1c-1.5,0-2.9,0.4-4.2,1v18.3h-3.5V78h3.4v2.1
	c1.7-1.7,4-2.7,6.4-2.6c4.4,0,6.2,2.6,6.7,4.8c0.3,1.4,0.4,2.8,0.3,4.2v12.7H256.6z"/>
			<path d="M269.9,99.5c-2.1,0.1-4.3-0.3-6.2-1.1l0.8-2.2c1.6,0.8,3.4,1.1,5.2,1c2.9,0,4.6-1.3,4.6-3.4c0-1.7-0.8-2.3-2.4-3.2l-3.5-2
	c-2.5-1.4-3.9-2.9-3.9-5.6c0-4,3.1-5.5,7.1-5.5c1.9-0.1,3.7,0.4,5.4,1.2l-0.8,2.3c-1.4-0.8-3-1.2-4.6-1.2c-1.6,0-4,0.5-4,2.9
	c0,1.8,1,2.3,2.9,3.4l3.4,1.8c2.3,1.3,3.6,2.9,3.6,5.2C277.4,98.7,272.6,99.5,269.9,99.5z"/>
		</svg>

	)
}