
import React, { Component, useState } from 'react';
import '@aws-amplify/ui-react/styles.css';
import { NavLink, useParams, useSearchParams, useLocation, useNavigate } from "react-router-dom"
import { Button, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Input, InputLabel, Paper, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { oktaAuth } from './Auth';
import { PageLoader } from './Loader';
import { createUserReport, getOktaUser } from './Queries'


class ContactForm extends Component {

    constructor(props) {
        super(props);


        this.state = {
            rumInfo: this.props.rumInfo,
            rumSessionId: this.props.rumInfo ? this.props.rumInfo.session_id : "N/A",
            username: "N/A",
            formType: "bug",
            description: "",
            submitted: false
        }

        getOktaUser().then(user => {
            this.setState({
                userInfo: user,
                username: user.preferred_username ? user.preferred_username : "N/A",
                ready: true,
            })
        })


    }


    render() {
        if (!this.state.ready) {
            return (
                <PageLoader ready={this.state.ready} />
            )
        }
        if (this.state.submitted) {
            var msg = "Your request has been submitted!"
            if (this.state.formType == "bug") {
                msg = "Bug report submitted to pest control"
            }
            if (this.state.formType == "feature") {
                msg = "Feature request was sent to Santa"
            }

            return (
                <Paper sx={{ minHeight: '800px', pl: 10, pb: 3, pt: 1 }}>
                    <Grid container>
                        <Grid xs={8} item>
                            <Typography variant="h2">{msg}</Typography>
                        </Grid>
                        <Grid xs={4} item></Grid>

                    </Grid>
                </Paper>
            )
        }
        return (
            <Paper sx={{ minHeight: '800px', pl: 10, pb: 3, pt: 1 }}>
                <h1>Bug report / Feature request</h1>
                <Typography variant='caption'>This is not a support form! For bug reports and feature requests only!</Typography>
                <Grid pb={2} pt={2} container>
                    <Grid xs={4} item>
                        <FormControl>
                            <FormLabel id="form-select-label">What do you want to report?</FormLabel>
                            <RadioGroup
                                aria-labelledby="form-select-label"
                                defaultValue="bug"
                                name="radio-buttons-group"
                                onChange={(event) => {
                                    this.setState({ formType: event.target.value })
                                }}
                            >
                                <FormControlLabel value="bug" control={<Radio />} label="I found a bug" />
                                <FormControlLabel value="feature" control={<Radio />} label="I want a new feature / change to the SSP" />
                                <FormControlLabel value="other" control={<Radio />} label="Other" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid pb={2} container>
                    <Grid xs={4} item>
                        <TextField
                            fullWidth={true}
                            helperText="Session ID for user tracking"
                            id="rumId"
                            label="RUM session id"
                            value={this.state.rumSessionId}
                            disabled={true}
                        />

                    </Grid>
                </Grid>
                <Grid pb={2} container>
                    <Grid xs={4} item>
                        <TextField
                            fullWidth={true}
                            helperText="Your username (email)"
                            id="username"
                            label="Username"
                            defaultValue={this.state.username}
                            onChange={(event) => {
                                this.setState({ username: event.target.value })
                            }}
                            disabled={this.state.userInfo.preferred_username ? true : false}
                        />

                    </Grid>
                </Grid>
                <Grid pb={2} container>
                    <Grid xs={4} pb={2} container>
                        <TextField
                            fullWidth={true}
                            id="description"
                            label="Description"
                            multiline
                            rows={10}
                            placeholder="Please describe your request / what went wrong. Please describe the steps that lead to the bug as thoroughly as possible."
                            defaultValue=""
                            onChange={(event) => {
                                this.setState({ description: event.target.value })
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid pb={2} container>
                    <Grid xs={4} pb={2} container>
                        <Button variant='contained'
                            disabled={this.state.description.length < 20}
                            fullWidth={true}
                            onClick={event => {

                                createUserReport(
                                    this.state.username,
                                    this.state.userInfo.groups ? this.state.userInfo.groups : ['NONE'],
                                    this.state.rumSessionId,
                                    this.state.formType,
                                    this.state.description
                                ).then(o => {
                                    this.setState({ submitted: true })
                                })
                            }}
                        >
                            Submit
                        </Button>
                        {
                            this.state.description.length < 20 ? <Typography variant='caption' color={'orangered'} >You need to enter at least 20 characters or more into the description</Typography> : null
                        }
                    </Grid>
                </Grid>




            </Paper >

        )

    }
}



function withRouter(C) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <C
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}


ContactForm = withRouter(ContactForm)

export { ContactForm }