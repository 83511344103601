
import '@aws-amplify/ui-react/styles.css';
import { CheckBox, DoDisturb, PendingActions } from '@mui/icons-material';
import React, { Component } from 'react';

import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

class DataTableStatusMapping extends Component {
    static propTypes = {
        value: PropTypes.string.isRequired,
    }

    constructor(props) {
        super(props);
        const { value, accessRequests, colId, tableMeta, } = this.props;
        this.state = {
            value: value,
            accessRequests: accessRequests,
            tableMeta: tableMeta,
            colId: colId
        }




    }


    render() {
        const StatusIconMap = {
            'Pending': [PendingActions, 'info'],
            'Approved': [CheckBox, 'success'],
            'Denied': [DoDisturb, 'error']
        }
        const StatusIcon = StatusIconMap[this.state.value][0]

        if (!this.state.tableMeta) {
            return (
                <Typography marginLeft={0} color={StatusIconMap[this.state.value][1]} variant="subtitle1" ><StatusIcon color={StatusIconMap[this.state.value][1]} sx={{ mr: 1, pt: 0.4 }} />
                    {this.state.value}

                </Typography>
            )
        }

        var accessRequestId = this.state.tableMeta.rowData[this.state.colId]



        if (this.state.accessRequests) {
            var queueItems = this.state.accessRequests.find(o => { return o.id == accessRequestId }).ApprovalWorkflowQueue.items

            var pendingQueue = queueItems.filter(o => { return o.Status == 'Approved' })

            var queueSummaryString = "(" + [pendingQueue.length, queueItems.length].join("/") + ")"
            console.debug("Pending: ", pendingQueue)

        }

        return (
            <Typography marginLeft={0} color={StatusIconMap[this.state.value][1]} variant="subtitle1" ><StatusIcon color={StatusIconMap[this.state.value][1]} sx={{ mr: 1, pt: 0.4 }} />
                {this.state.value} {queueSummaryString}

            </Typography>
        );
    }
}

export default DataTableStatusMapping