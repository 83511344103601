import { Paper, Typography } from '@mui/material';
import { RiseLoader, } from 'react-spinners';

function PageLoader(props) {
    if (props.ready) return null

    return (
        <Paper sx={{ px: 10, py: 40 }}>
            <Typography align='center'>
                <RiseLoader loading={!props.ready} color={'#e0e0e0'} />
            </Typography>
            <Typography my={5} align='center' variant='subtitle1'>Loading...</Typography>
        </Paper>
    )
}


export {
    PageLoader
}