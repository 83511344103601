import { OktaAuth } from '@okta/okta-auth-js';


if (['ssp-prod.tools.dev.assaabloyglobalsolutions.net'].includes(window.location.host)) {
    var configValues = {
        issuer: 'https://okta.prod.assaabloyglobalsolutions.net',
        clientId: '0oa5h8o42rv6w3b6o417',
        redirectUri: window.location.origin + '/login/callback',
    }
}

else {
    var configValues = {
        issuer: 'https://okta.tools.dev.assaabloyglobalsolutions.net',
        clientId: '0oa3vkcpx5iskQHTa0x7',
        redirectUri: window.location.origin + '/login/callback',
    }
}


const oktaConfig = configValues


const oktaAuth = new OktaAuth({
    issuer: oktaConfig.issuer,
    clientId: oktaConfig.clientId,
    redirectUri: oktaConfig.redirectUri,
    postLogoutRedirectUri: window.location.origin + "/signedOut",
    scopes: ['openid', 'profile', 'groups', 'email']

})



export { oktaAuth, oktaConfig };
