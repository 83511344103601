import "@aws-amplify/ui-react/styles.css";
import {
  ArrowBack,
  Edit,
  GroupRemove,
  LockReset,
  PersonOff,
  Send
} from "@mui/icons-material";
import PasswordIcon from "@mui/icons-material/Password";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import {
  Autocomplete,
  Avatar,
  Button,
  Chip,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Popover,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { Box } from "@mui/system";
import MUIDataTable from "mui-datatables";
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import { RiseLoader } from "react-spinners";
import { toast } from "react-toastify";
import { PageLoader } from "./Loader";
import {
  activateTeamUser,
  disableUser,
  getTeam,
  getTeamUser,
  listTeamUsers,
  removeTeamMember,
  resendActivationEmail,
  resetMFA,
  resetPassword,
  updateTeamUser
} from "./Queries";

class ManageTeamUsers extends Component {
  constructor(props) {
    super(props);
    var teamId = this.props.router.params.teamId;
    this.selectUser = this.selectUser.bind(this);

    this.updateUser = this.updateUser.bind(this);
    this.updateTeamUsers = this.updateTeamUsers.bind(this);

    this.handleClickAction = this.handleClickAction.bind(this);
    this.handleCloseAction = this.handleCloseAction.bind(this);

    this.resetMFA = this.resetMFA.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.deactivateUser = this.deactivateUser.bind(this);
    this.activateUser = this.activateUser.bind(this);
    this.resendActivationEmail = this.resendActivationEmail.bind(this);

    this.setDate = this.setDate.bind(this);
    this.state = {
      teamId: teamId,
      accessRequests: [],
      approvalQueueItems: [],
      tableData: [],
      searchFilter: "Pending",
      reason: "",
      selectedRows: [],
      ready: false,
      selectedUser: undefined,
      userLoaded: true,
      userActions: {},
      datatable: {
        columns: [
          { 
            name: "Username",
            options: {
              filter: true,
              sort: true
            }
          },
          { name: "Name", options: { filter: true, sort: true } },
          { name: "Status", options: { filter: true, sort: true } },
          { name: "Actions", options: { filter: false, sort: false } },
        ],

        options: {
          filterType: "checkbox",
          filter: true,
          download: false,
          print: false,
          filterType: "dropdown",
          responsive: "vertical",

          expandableRows: false,
          expandableRowsHeader: false,
          onRowClick: (rowData,rowMeta) => {
            this.selectUser(this.state.userData[rowMeta.dataIndex].Username);
          },
          expandableRowsOnClick: false,
          selectableRows: "none",
        },
      },
    };
    getTeam(teamId,0)
      .then((team) => {
        this.setState({ currentTeam: team });
      })
      .then((o) => {
        this.updateTeamUsers();
      });
  }

  selectUser(username) {
    if (!this.state.userLoaded) return;

    this.setState({ userLoaded: false, selectedUser: null });
    this.setState({
      selectedUser: undefined,
    });
    getTeamUser(this.state.teamId, username)
      .then((user) => {
        console.debug(user);
        this.setState({
          selectedUser: user,
          selectedUserRoles: user.Roles.sort((a, b) => {
            return a > b;
          }).map((r) => {
            return r.RoleId;
          }),
        });
        if (user.ExpirationDate != "" && user.ExpirationDate) {
          this.setState({ expirationDate: user.ExpirationDate });
        } else {
          this.setState({ expirationDate: undefined });
        }
        console.debug(this.state.expirationDate);
      })
      .catch((err) => {
        toast.error("An error occured while loading " + username);
      })
      .then((o) => {
        this.setState({ userLoaded: true });
      });
  }

  resetPassword(selectedUser = undefined) {
    if (!selectedUser) {
      selectedUser = this.state.selectedUser
        ? this.state.selectedUser
        : this.state.actionUser;
    }
    var promise = resetPassword(this.state.teamId, selectedUser).then((o) => {
      this.updateTeamUsers();
    });
    toast.promise(promise, {
      pending: `Reset ${selectedUser} password`,
      success: `${selectedUser}'s password was reset`,
      error: `Failed to reset password for ${selectedUser}`,
    });
  }

  resetMFA(selectedUser = undefined) {
    if (!selectedUser) {
      selectedUser = this.state.selectedUser
        ? this.state.selectedUser
        : this.state.actionUser;
    }
    var promise = resetMFA(this.state.teamId, selectedUser).then((o) => {
      this.updateTeamUsers();
    });
    toast.promise(promise, {
      pending: `Reset ${selectedUser} MFA`,
      success: `${selectedUser}'s MFA was reset`,
      error: `Failed to reset MFA for ${selectedUser}`,
    });
  }

  deactivateUser(selectedUser = undefined) {
    if (!selectedUser) {
      selectedUser = this.state.selectedUser
        ? this.state.selectedUser
        : this.state.actionUser;
    }
    var promise = disableUser(this.state.teamId, selectedUser).then((o) => {
      this.updateTeamUsers();
    }).then(this.setState({selectedUser: undefined}));
    toast.promise(promise, {
      pending: `Disabling ${selectedUser}`,
      success: `${selectedUser} was deactivated`,
      error: `Failed to deactivate $${selectedUser}`,
    });
  }

  activateUser(selectedUser = undefined) {
    if (!selectedUser) {
      selectedUser = this.state.selectedUser
        ? this.state.selectedUser
        : this.state.actionUser;
    }
    var promise = activateTeamUser(this.state.teamId, selectedUser).then(
      (o) => {
        this.updateTeamUsers().then(o => {
            this.selectUser(selectedUser)
        });
      }
    );
    toast.promise(promise, {
      pending: `Activating ${selectedUser}`,
      success: `${selectedUser} was activated`,
      error: `Failed to activate ${selectedUser}`,
    });
  }

  removeTeamMember(selectedUser = undefined) {
    if (!selectedUser) {
      selectedUser = this.state.selectedUser
        ? this.state.selectedUser
        : this.state.actionUser;
    }
    var promise = removeTeamMember(this.state.teamId, selectedUser).then(
      (o) => {
        this.updateTeamUsers();
      }
    ).then(this.setState({selectedUser: undefined}));
    toast.promise(promise, {
      pending: `Removing ${selectedUser} from ${this.state.teamId}`,
      success: `${selectedUser} was removed from ${this.state.teamId}`,
      error: `Failed to remove $${selectedUser} from ${this.state.teamId}`,
    });
  }

  resendActivationEmail(selectedUser = undefined) {
    if (!selectedUser) {
      selectedUser = this.state.selectedUser
        ? this.state.selectedUser
        : this.state.actionUser;
    }
    var promise = resendActivationEmail(this.state.teamId, selectedUser);
    toast.promise(promise, {
      pending: `Resending activation email to ${selectedUser}`,
      success: `Activation email to ${selectedUser} was sent`,
      error: `Failed sending activation email to $${selectedUser}`,
    });
  }

  setDate(days) {
    var today = new Date();
    var newDate = new Date();
    newDate.setDate(today.getDate() + days);
    this.setState({
      expirationDate: newDate,
      selectedExpirationDate: `${days}d`,
    });
  }

  updateTeamUsers() {
    var promise = listTeamUsers(this.state.teamId)
      .then((users) => {
        this.setState({
          userData: users,
          users: users.map((user) => {
            if (user.Status == "PROVISIONED" || user.Status == "STAGED") {
              var actions = (
                <div>
                  <Tooltip title="The users enrollment is not complete, they need to enroll by following the instructions in the activation email. Click on this button to resend the activation email.">
                    <Button
                      onClick={(event) => {
                        event.stopPropagation();
                        this.handleClickAction(
                          event,
                          "ResendActivationEmail",
                          user.Username
                        );
                      }}
                    >
                      <Send /> &nbsp; Resend activation email
                    </Button>
                  </Tooltip>
                  <Tooltip title="Deactivate user (will be deleted in 30 days)">
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        this.handleClickAction(
                          event,
                          "DeactivateUser",
                          user.Username
                        );
                      }}
                    >
                      <PersonOff />
                    </IconButton>
                  </Tooltip>
                  ,
                  <Tooltip title="Remove user from team (will be deactivated if not member of another team)">
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        this.handleClickAction(
                          event,
                          "removeTeamMember",
                          user.Username
                        );
                      }}
                    >
                      <GroupRemove />
                    </IconButton>
                  </Tooltip>
                </div>
              );
            } else if (user.Status == "DEPROVISIONED") {
              var actions = (
                <div>
                  <Tooltip title="Activate user">
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        this.selectUser(user.Username);
                        this.handleClickAction(
                          event,
                          "ActivateUser",
                          user.Username
                        );
                      }}
                    >
                      <VerifiedUserIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              );
            } else {
              var actions = (
                <div>
                  <Tooltip title="Edit user">
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        this.selectUser(user.Username);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Reset password">
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        this.handleClickAction(
                          event,
                          "ResetPassword",
                          user.Username
                        );
                      }}
                    >
                      <PasswordIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Reset MFA">
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        this.handleClickAction(
                          event,
                          "ResetMFA",
                          user.Username
                        );
                      }}
                    >
                      <LockReset />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Deactivate">
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        this.handleClickAction(
                          event,
                          "DeactivateUser",
                          user.Username
                        );
                      }}
                    >
                      <PersonOff />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Remove user from team (will be deactivated if not member of another team)">
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        this.handleClickAction(
                          event,
                          "removeTeamMember",
                          user.Username
                        );
                      }}
                    >
                      <GroupRemove />
                    </IconButton>
                  </Tooltip>
                </div>
              );
            }

            return [
              user.Username,
              user.FirstName + " " + user.LastName,
              user.Status,
              actions,
            ];
          }),
          userObjects: users,
        });
      })
      .then((o) => {
        this.setState({ ready: true });
      });
    return promise;
  }
  async updateUser() {
    var date = undefined;
    if (this.state.expirationDate) date = this.state.expirationDate;
    this.setState({ isUpdating: true });
    var promise = updateTeamUser(
      this.state.teamId,
      this.state.selectedUser.Username,
      this.state.selectedUserRoles,
      date
    )
      .catch((err) => {
        this.setState({ isUpdating: false });
        throw err;
      })
      .then((user) => {
        console.debug(user);
        this.setState({ selectedUser: user, isUpdating: false });
        this.updateTeamUsers();
      });
    toast.promise(promise, {
      pending: `Updating ${this.state.selectedUser.Username}`,
      success: `${this.state.selectedUser.Username} was updated successfully`,
      error: `Failed to update ${this.state.selectedUser.Username}`,
    });
  }

  handleClickAction(event, action, username = undefined) {
    if (!username) username = this.state.selectedUser.Username;

    console.debug(action, "on ", username);

    if (action == "ResetPassword") {
      this.setState({
        action: action,
        actionUser: username,
        confirmActionText:
          "Are you sure that you want to reset this users password?",
        confirmActionButtonYesText: "Yes, reset the password",
        confirmActionButtonNoText: "No",
      });
    } else if (action == "ResetMFA") {
      this.setState({
        action: action,
        actionUser: username,
        confirmActionText:
          "Are you sure that you want to reset all MFA factors for this user?",
        confirmActionButtonYesText: "Yes, reset ALL factors",
        confirmActionButtonNoText: "No",
      });
    } else if (action == "DeactivateUser") {
      this.setState({
        action: action,
        actionUser: username,
        confirmActionText:
          "Are you sure that you want to deactivate this user?",
        confirmActionButtonYesText: "Yes, deactivate user",
        confirmActionButtonNoText: "No",
      });
    } else if (action == "ResendActivationEmail") {
      this.setState({
        action: action,
        actionUser: username,
        confirmActionText:
          "Are you sure that you want to resend the activation email to this user?",
        confirmActionButtonYesText: "Yes, resend",
        confirmActionButtonNoText: "No",
      });
    } else if (action == "ActivateUser") {
      this.setState({
        action: action,
        actionUser: username,
        confirmActionText:
          "Are you sure that you want to reactivate this user? All permissions except those managed by this team will be removed from the user...",
        confirmActionButtonYesText: "Yes, activate " + username,
        confirmActionButtonNoText: "No",
      });
    } else if (action == "removeTeamMember") {
      this.setState({
        action: action,
        actionUser: username,
        confirmActionText: `Are you sure that you want to remove ${username} from this team? The user will be deactivated if it is not a member of any other team.`,
        confirmActionButtonYesText: "Yes",
        confirmActionButtonNoText: "No",
      });
    }
    this.setState({ anchorEl: event.currentTarget });
  }
  handleDialogConfirmation() {
    if (this.state.action == "ResetPassword") {
      this.resetPassword(this.state.actionUser);
    } else if (this.state.action == "ResetMFA") {
      this.resetMFA(this.state.actionUser);
    } else if (this.state.action == "DeactivateUser") {
      this.deactivateUser(this.state.actionUser);
    } else if (this.state.action == "ResendActivationEmail") {
      this.resendActivationEmail(this.state.actionUser);
    } else if (this.state.action == "ActivateUser") {
      this.activateUser(this.state.actionUser);
    } else if (this.state.action == "removeTeamMember") {
      this.removeTeamMember(this.state.actionUser);
    }
    this.setState({ anchorEl: null });
  }

  handleCloseAction() {
    this.setState({ anchorEl: null });
  }

  handleRemoveGroupFromList(label) {
    this.setState({
      selectedUserRoles: this.state.selectedUserRoles.filter((o) => {
        return o != label;
      }),
    });
  }

  render() {
    if (!(this.state.ready && this.state.currentTeam != undefined)) {
      return (
        <PageLoader
          ready={this.state.ready && this.state.currentTeam != undefined}
        />
      );
    }
    return (
      <div>
        <Popover
          id={"popover-confirmation"}
          open={this.state.anchorEl ? true : false}
          anchorEl={this.state.anchorEl}
          onClose={this.handleCloseAction}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Typography variant="h6" sx={{ p: 2 }}>
            {this.state.confirmActionText}
          </Typography>
          <Grid spacing={3} px={3} py={3} container>
            <Grid width={"50%"} item>
              <Button
                sx={{ height: "5em" }}
                fullWidth={true}
                variant="contained"
                onClick={(event) => {
                  this.handleDialogConfirmation();
                }}
                color="success"
              >
                {this.state.confirmActionButtonYesText}
              </Button>
            </Grid>
            <Grid width={"50%"} item>
              <Button
                sx={{ height: "5em" }}
                onClick={this.handleCloseAction}
                fullWidth={true}
                variant="contained"
                color="error"
              >
                {this.state.confirmActionButtonNoText}
              </Button>
            </Grid>
          </Grid>
        </Popover>

        <Paper sx={{ minHeight: "800px", pl: 2, pb: 3, pt: 1 }}>
          <Grid
            container
            style={{ display: "flex", flexDirection: "row", marginBottom: 2 }}
          >
            <Grid item>
              <Typography variant="h5" sx={{ marginTop: 4 }}>
                Team {this.state.currentTeam.TeamName}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" mt={5} ml={1}>
                <Edit />
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" mt={4.5} component="div">
                {" "}
                &nbsp; Edit user
              </Typography>
            </Grid>
          </Grid>
          <Grid spacing={2} minHeight={"800px"} container>
            <Grid xs={this.state.selectedUser ? 0 : 12} item>
              <Collapse in={this.state.selectedUser ? false : true}>
                <RiseLoader loading={!this.state.userLoaded} />
                <MUIDataTable
                  data={this.state.users}
                  columns={this.state.datatable.columns}
                  options={this.state.datatable.options}
                  key="UserTable"
                />
              </Collapse>
            </Grid>

            <Grid minWidth={this.state.selectedUser ? "100%" : "0%"} item>
              {this.state.selectedUser ? (
                <Grid
                  mx={4}
                  maxWidth={"66%"}
                  spacing={1}
                  direction={"column"}
                  container
                >
                  <Grid>
                    <Tooltip title="Go back">
                      <Button
                        onClick={(e) => {
                          this.setState({ selectedUser: undefined });
                        }}
                      >
                        <ArrowBack />
                      </Button>
                    </Tooltip>
                  </Grid>

                  <Grid mb={3} item>
                    {this.state.selectedUser.Status == "DEPROVISIONED" || this.state.selectedUser.Status == 'UserStatus.DEPROVISIONED' ? (
                      <Grid spacing={2} container>
                        <Grid item>
                          <Button
                            onClick={(event) => {
                              this.handleClickAction(event, "ActivateUser");
                            }}
                            variant="contained"
                            color="warning"
                          >
                            Reactivate user
                          </Button>
                        </Grid>
                      </Grid>
                    ) : null}
                    {this.state.selectedUser.Status == "ACTIVE" || this.state.selectedUser.Status == 'UserStatus.ACTIVE'  || this.state.selectedUser.Status == "PROVISIONED" || this.state.selectedUser.Status == 'UserStatus.PROVISIONED'  ? (
                      <Grid spacing={2} container>
                        <Grid item>
                          <Button
                            onClick={(event) => {
                              this.handleClickAction(event, "ResetPassword");
                            }}
                            variant="contained"
                            color="info"
                          >
                            Reset password
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            onClick={(event) => {
                              this.handleClickAction(event, "ResetMFA");
                            }}
                            variant="contained"
                            color="secondary"
                          >
                            Reset MFA
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            onClick={(event) => {
                              this.handleClickAction(event, "DeactivateUser");
                            }}
                            variant="contained"
                            color="warning"
                          >
                            Deactivate user
                          </Button>
                        </Grid>
                      </Grid>
                    ) : null}
                  </Grid>
                  <Grid item>
                    <Typography variant="h4">
                      {this.state.selectedUser.FirstName}{" "}
                      {this.state.selectedUser.LastName}
                    </Typography>

                    <Typography variant="h6">
                      ({this.state.selectedUser.Username})
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography mt={3} variant="h5">
                      Roles/Access:{" "}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Autocomplete
                      disabled={this.state.selectedUser.Status != 'UserStatus.ACTIVE' && this.state.selectedUser.Status != 'UserStatus.PROVISIONED'}
                      multiple
                      sx={{ maxWidth: "80%" }}
                      id="selectRoles"
                      value={this.state.currentTeam.AvailableRoles.filter(
                        (o) => {
                          return this.state.selectedUserRoles.includes(
                            o.RoleId
                          );
                        }
                      ).map((o) => {
                        return {
                          label: o.RoleId,
                          description: o.Description,
                        };
                      })}
                      isOptionEqualToValue={(option, value) => {
                        return option.label == value.label;
                      }}
                      onChange={(event, newInputValue) => {
                        this.setState({
                          selectedUserRoles: newInputValue.map((o) => {
                            return o.label;
                          }),
                        });
                      }}
                      options={this.state.currentTeam.AvailableRoles.map(
                        (o) => {
                          return {
                            label: o.RoleId,
                            description: o.Description,
                          };
                        }
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select roles"
                          placeholder="Select roles"
                        />
                      )}
                      renderTags={(values) =>
                        values
                          .sort((a, b) => {
                            return a.label > b.label;
                          })
                          .map((option) => {
                            var label = option.label;
                            var avatar = label.split("-")[0];
                            if (avatar == "AwsAccount") {
                              avatar = "AWS";
                            }

                            if (
                              option.description &&
                              option.label.startsWith("AwsAccount")
                            ) {
                              label = `${
                                option.description
                              } (${option.label.replace("AwsAccount-", "")})`;
                            }
                            return (
                              <Chip
                                label={label}
                                sx={{ mt: 0.2, my: 0.2 }}
                                key={option.label}
                                avatar={
                                  <Avatar sx={{ mx: 0.2, my: 0.2 }}>
                                    {avatar}
                                  </Avatar>
                                }
                                onDelete={(event) =>
                                  this.handleRemoveGroupFromList(option.label)
                                }
                              />
                            );
                          })
                      }
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Box
                            sx={{
                              flexGrow: 1,
                              "& span": {
                                color: "#8b949e",
                              },
                            }}
                          >
                            {option.label}
                            <br />
                            <span>{option.description}</span>
                          </Box>
                        </li>
                      )}
                    />
                  </Grid>

                  <Grid item>
                    <Typography mt={3} variant="h5">
                      Expiration date:{" "}
                    </Typography>
                    {this.state.expirationDate ? (
                      <DatePicker
                        selected={new Date(this.state.expirationDate)}
                        onChange={(value) => {
                          this.setState({
                            expirationDate: value.toISOString(),
                          });
                        }}
                        disabled={this.state.selectedUser.Status != 'UserStatus.ACTIVE' && this.state.selectedUser.Status != 'UserStatus.PROVISIONED'}
                      />
                    ) : null}
                    {["14d", "30d", "90d", "180d", null, undefined].includes(
                      this.state.selectedExpirationDate
                    ) ? null : (
                      <Typography variant="subtitle2">Select date</Typography>
                    )}
                    {[null, undefined].includes(
                      this.state.selectedExpirationDate
                    ) ? null : (
                      <Typography variant="subtitle2">
                        The user will get deactivated 00:00UTC on set date
                      </Typography>
                    )}
                  </Grid>
                  <Grid item>
                    <Grid spacing={1} container>
                      <Grid item>
                        <Button
                          disabled={this.state.selectedUser.Status != 'UserStatus.ACTIVE' && this.state.selectedUser.Status != 'UserStatus.PROVISIONED'}
                          color={this.state.expirationDate ? "info" : "success"}
                          variant="contained"
                          onClick={(event) => {
                            this.setState({
                              expirationDate: null,
                              selectedExpirationDate: null,
                            });
                          }}
                        >
                          Never
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          disabled={this.state.selectedUser.Status != 'UserStatus.ACTIVE' && this.state.selectedUser.Status != 'UserStatus.PROVISIONED'}
                          color={
                            this.state.selectedExpirationDate == "14d"
                              ? "success"
                              : "info"
                          }
                          onClick={(event) => {
                            this.setDate(14);
                          }}
                          variant="contained"
                        >
                          14 days
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          disabled={this.state.selectedUser.Status != 'UserStatus.ACTIVE' && this.state.selectedUser.Status != 'UserStatus.PROVISIONED'}
                          color={
                            this.state.selectedExpirationDate == "30d"
                              ? "success"
                              : "info"
                          }
                          onClick={(event) => {
                            this.setDate(30);
                          }}
                          variant="contained"
                        >
                          30 days
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          disabled={this.state.selectedUser.Status != 'UserStatus.ACTIVE' && this.state.selectedUser.Status != 'UserStatus.PROVISIONED'}
                          color={
                            this.state.selectedExpirationDate == "90d"
                              ? "success"
                              : "info"
                          }
                          onClick={(event) => {
                            this.setDate(90);
                          }}
                          variant="contained"
                        >
                          90 days
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          disabled={this.state.selectedUser.Status != 'UserStatus.ACTIVE' && this.state.selectedUser.Status != 'UserStatus.PROVISIONED'}
                          color={
                            this.state.selectedExpirationDate == "180d"
                              ? "success"
                              : "info"
                          }
                          onClick={(event) => {
                            this.setDate(180);
                          }}
                          variant="contained"
                        >
                          180 days
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          disabled={this.state.selectedUser.Status != 'UserStatus.ACTIVE' && this.state.selectedUser.Status != 'UserStatus.PROVISIONED'}
                          color={
                            [
                              "14d",
                              "30d",
                              "90d",
                              "180d",
                              null,
                              undefined,
                            ].includes(this.state.selectedExpirationDate)
                              ? "info"
                              : "success"
                          }
                          onClick={(event) => {
                            this.setDate(1);
                          }}
                          variant="contained"
                        >
                          Custom
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item></Grid>
                  <Grid item>
                    <Button
                      disabled={this.state.selectedUser.Status != 'UserStatus.ACTIVE' && this.state.selectedUser.Status != 'UserStatus.PROVISIONED' && !this.state.isUpdating}
                      onClick={(event) => {
                        this.updateUser();
                      }}
                      
                      sx={{
                        minHeight: "10em",
                        width: "80%",
                        px: 4,
                        sx: 4,
                        mt: 4,
                      }}
                      color="success"
                      variant="contained"
                    >
                      {this.state.isUpdating ? (
                        <RiseLoader
                          loading={this.state.isUpdating}
                          color={"#e0e0e0"}
                        />
                      ) : (
                        "Update user"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

function withRouter(C) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <C {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

ManageTeamUsers = withRouter(ManageTeamUsers);

export { ManageTeamUsers };

