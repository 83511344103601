
import '@aws-amplify/ui-react/styles.css';
import { Add, Delete, Done, Edit, Error } from '@mui/icons-material';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormLabel, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Paper, Popover, Select, TextField, Tooltip, Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import MUIDataTable from "mui-datatables";
import React, { Component } from 'react';
import ReactDatePicker from 'react-datepicker';
import { BeatLoader, RiseLoader, SyncLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { PageLoader } from './Loader';
import { adminAddGroupMember, adminCreateTeam, adminDisableUser, adminListGroups, adminListUsers, adminRemoveGroupMember, adminResetUserMfa, adminResetUserPassword, adminUpdateGroupManagedBy, adminUpdateTeam, createAnnouncement, createBusinessArea, createPricingList, deleteAnnouncement, deletePricingList, listAnnouncements, listAwsAccounts, listBusinessAreas, listEntityToBusinessAreaMappings, listPricingLists, listUserReports, updateAwsAccount, updateEntityToBusinessAreaMapping, updatePricingList } from './Queries';

function dynamicSort(property) {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

class ManageTeams extends Component {

    constructor(props) {
        super(props);

        this.clearAnchorEl = this.clearAnchorEl.bind(this)
        this.handleDialogClose = this.handleDialogClose.bind(this)
        this.handleDialogOk = this.handleDialogOk.bind(this)
        this.loadTeams = this.loadTeams.bind(this)
        this.state = {
            ready: false,
            anchorEl: null,
            activeRows: {},
            businessAreas: [],

        }

        this.loadTeams()

        this.datatable = {
            columns: [
                { name: "TeamId", options: { filter: true, sort: true } },
                { name: "Name", options: { filter: true, sort: true } },
                {
                    name: "Business Area",

                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {

                            return (
                                tableMeta.rowData[2] ? tableMeta.rowData[2] : <Typography variant='caption'>None</Typography>
                            )
                        },
                    }
                }


            ],

            options: {
                rowsPerPage: 100,
                rowsPerPageOptions: [50, 100, 200, 400, 500, 1000],
                filter: true,
                download: false,
                print: false,
                filterType: 'dropdown',
                responsive: 'standard',
                selectableRows: 'none',
                expandableRowsOnClick: true,
                expandableRows: true,
                download: true,
                onDownload: (buildHead, buildBody, columns, data) => {
                    return "\uFEFF" + buildHead(columns) + buildBody(data);
                },
                downloadOptions: {
                    filename: "4SP-Export-Teams.csv"
                },
                customToolbar: () => {
                    return (
                        <Tooltip title="Add a new team">
                            <Button
                                onClick={(event) => { this.setState({ dialogOpen: true }) }}
                                variant="contained" sx={{ minHeight: '100%', px: 2, py: 2 }} color="success"><GroupAddIcon />&nbsp;New team</Button>
                        </Tooltip>


                    );
                },
                renderExpandableRow: (rowData, rowMeta) => {
                    const colSpan = rowData.length;
                    const stateName = `TeamData${rowData[0]}`
                    if (this.state[`TeamData${rowData[0]}`] == undefined) {
                        adminListGroups(`profile.name sw "${rowData[0]}"`, true).then(teams => {
                            var s = {}
                            s[stateName] = teams.find(o => { return o.GroupName == rowData[0] })
                            s[stateName + "-Administrator"] = teams.find(o => { return o.GroupName == rowData[0] + "-Administrator" })
                            s[stateName + "-Manager"] = teams.find(o => { return o.GroupName == rowData[0] + "-Manager" })
                            console.debug(s)
                            this.setState(s)
                        })

                    }

                    return (

                        <TableRow>
                            <TableCell colSpan={colSpan}>
                                {
                                    this.state[`TeamData${rowData[0]}`] ?
                                        this.renderTeamInfo(
                                            this.state[`${stateName}`],
                                            this.state[`${stateName}-Administrator`],
                                            this.state[`${stateName}-Manager`]
                                        ) :
                                        <PageLoader ready={false} />
                                }
                                <Box marginTop={4} width={'100%'}></Box>
                                <Typography>Business Area:</Typography>
                                <Select
                                    labelId="select-ba-label"
                                    id="select-ba"
                                    placeholder='Select BA...'
                                    label="Business Area"
                                    value={rowData[2]}
                                    onChange={(event) => {
                                        var s = {}
                                        console.debug(event.target.value)
                                        s[`${rowData[0]}-selectedBA`] = event.target.value
                                        this.setState(s)
                                        rowData[2] = event.target.value
                                        console.debug(this.state[`${rowData[0]}-selectedBA`])

                                    }}
                                >
                                    {
                                        this.state.businessAreas.map(ba => {
                                            return (
                                                <MenuItem selected={rowData[2] == ba.id} value={ba.id}>{ba.DisplayName}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                                <Button
                                    sx={{ ml: 2 }}
                                    variant='contained'
                                    color='success'
                                    disabled={this.state[`${rowData[0]}-selectBAWorking`]}
                                    onClick={(event) => {
                                        var s = {}
                                        s[`${rowData[0]}-selectBAWorking`] = true
                                        this.setState(s)
                                        adminUpdateTeam(rowData[0], rowData[1], rowData[2]).then(o => {
                                            s[`${rowData[0]}-selectBAWorking`] = false
                                            this.setState(s)
                                        })
                                    }}
                                >
                                    {
                                        this.state[`${rowData[0]}-selectBAWorking`] ?
                                            <RiseLoader />
                                            :
                                            "Update"
                                    }
                                </Button>
                            </TableCell>
                        </TableRow>



                    )
                },
                onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded, rowData) =>
                    console.debug(curExpanded, allExpanded, rowsExpanded),
            }

        }

    }

    setAnchorEl(event, mode, team) {
        this.setState({ anchorEl: event.currentTarget })
        this.setState({ popoverMode: mode, currentTeam: team })
    }

    componentDidMount() {
        listBusinessAreas().then(bas => { this.setState({ businessAreas: bas.sort(dynamicSort("DisplayName")) }) })
    }
    async loadTeams() {
        this.setState({ ready: false })
        adminListGroups('profile.name sw "Team-"').then(groups => {
            groups.sort((a, b) => {
                return b.GroupName < a.GroupName
            });

            this.setState(
                {
                    groups: groups,
                    groupArray: groups.filter(o => { return o.GroupName.match(/^Team-[^-]+$/) }).map(o => {
                        return [o.GroupName, o.Description, o.BusinessArea]
                    }),
                }
            )

        }).then(o => {
            this.setState({ ready: true })
        })
    }

    renderTeamMembers(members, team) {

        if (members == undefined) {
            return (<Typography>NONE</Typography>)
        }
        return (
            <Grid spacing={1} container>
                {
                    members.sort((a, b) => { return a < b ? -1 : 1 }).map(username => {
                        return [

                            <Grid xs={12} item>
                                <Button
                                    variant='outlined'
                                    color='error'
                                    sx={{ mr: 2 }}
                                    onClick={(event) => {
                                        var promise = adminRemoveGroupMember(username, team['GroupName']).then(data => {
                                            var data = this.state['TeamData' + team['GroupName']]
                                            data.Members = data.Members.filter(row => { return row != username })
                                            var out = {}
                                            out['TeamData' + team['GroupName']] = data
                                            this.setState(out)
                                        })

                                        toast.promise(
                                            promise,
                                            {
                                                pending: `Removing ${username} from ${team['GroupName']}`,
                                                success: `Removed ${username} from ${team['GroupName']}`,
                                                error: `Failed to remove ${username} from ${team['GroupName']}`
                                            }
                                        )
                                    }}
                                >
                                    <PersonRemoveIcon />
                                </Button>
                                {username}

                            </Grid>

                        ]
                    })
                }
            </Grid>
        )
    }


    renderTeamInfo(team, admin, managers) {
        return (
            <Grid spacing={1} container>
                <Grid xs={4} item>
                    <Grid spacing={1} container>
                        <Grid xs={12} item>
                            <Typography variant={'h6'}>
                                Members
                            </Typography>

                        </Grid>
                        <Grid xs={12} marginLeft={2} item>
                            {
                                team.Members.length == 0
                                    ? <Typography variant='caption' color={'yellowgreen'}>No members</Typography>
                                    : this.renderTeamMembers(team.Members, team.GroupId)

                            }
                        </Grid>
                        <Grid marginLeft={2} marginTop={2} item>
                            <Button
                                color='success' variant='outlined'
                                onClick={(event) => {
                                    this.setAnchorEl(event, 'AddTeamMember', team.GroupId)
                                }}
                            >
                                <PersonAddIcon /> Add member
                            </Button>
                        </Grid>

                    </Grid>
                </Grid>
                {
                    admin ?
                        <Grid xs={4} item>
                            <Grid spacing={1} container>
                                <Grid xs={12} item>
                                    <Typography variant={'h6'}>
                                        Administrators
                                    </Typography>

                                </Grid>

                                <Grid xs={12} marginLeft={2} item>
                                    {
                                        admin.Members.length == 0
                                            ? <Typography variant='caption' color={'yellowgreen'}>No members</Typography>
                                            : this.renderTeamMembers(admin ? admin.Members : [], admin.GroupId)

                                    }
                                </Grid>
                                <Grid marginLeft={2} marginTop={2} item>
                                    <Button
                                        color='success' variant='outlined'
                                        onClick={(event) => {
                                            this.setAnchorEl(event, 'AddTeamAdmin', team.GroupId)
                                        }}
                                    >
                                        <PersonAddIcon /> Add admin
                                    </Button>
                                </Grid>

                            </Grid>
                        </Grid> : null

                }

                {
                    managers ?
                        <Grid xs={4} item>
                            <Grid spacing={1} container>
                                <Grid xs={12} item>
                                    <Typography variant={'h6'}>
                                        Managers
                                    </Typography>

                                </Grid>
                                <Grid xs={12} marginLeft={2} item>
                                    {
                                        managers.Members.length == 0
                                            ? <Typography variant='caption' color={'yellowgreen'}>No members</Typography>
                                            : this.renderTeamMembers(managers ? managers.Members : [], managers.GroupId)

                                    }
                                </Grid>
                                <Grid marginLeft={2} marginTop={2} item>
                                    <Button
                                        color='success' variant='outlined'
                                        onClick={(event) => {
                                            this.setAnchorEl(event, 'AddTeamManager', team.GroupId)
                                        }}
                                    >
                                        <PersonAddIcon /> Add manager
                                    </Button>
                                </Grid>

                            </Grid>
                        </Grid> : null
                }





            </Grid>
        )
    }
    clearAnchorEl(event) {

        this.setState({ anchorEl: null, submitButtonColor: 'success' })
    }
    handleDialogClose(event) {
        this.setState({ dialogOpen: false })

    }
    handleDialogOk(event) {
        this.setState({ workingAddTeam: true })
        adminCreateTeam(this.state.newTeamId, this.state.newTeamName).then(newTeam => {
            window.location.reload();
        }).catch(err => {
            console.error(err)
            this.setState({ workingAddTeam: false })
        })

    }
    render() {



        return (
            <Paper>
                <Popover
                    id={'AddUserPopOver'}
                    open={this.state.anchorEl != null}
                    anchorEl={this.state.anchorEl}
                    onClose={this.clearAnchorEl}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}

                >

                    <TextField
                        label="Enter username"
                        value={this.state.usernameToAdd}
                        onChange={(event) => {

                            this.setState({
                                usernameToAdd: event.target.value == "" ? undefined : event.target.value
                            })
                        }}
                    />
                    <Button
                        variant='contained'
                        color='success'
                        disabled={this.state.usernameToAdd ? false : true}
                        sx={{ minHeight: '55px' }}
                        onClick={(event) => {
                            this.setState({ working: true })
                            var groupId = null
                            if (this.state.popoverMode == 'AddTeamMember') {
                                groupId = this.state.currentTeam
                            }
                            if (this.state.popoverMode == 'AddTeamAdmin') {
                                groupId = this.state.currentTeam + "-Administrator"
                            }
                            if (this.state.popoverMode == 'AddTeamManager') {
                                groupId = this.state.currentTeam + "-Manager"
                            }
                            if (groupId) {
                                var promise = adminAddGroupMember(this.state.usernameToAdd, groupId).catch(o => { }).finally(o => { this.setState({ working: false }) }).then(o => {
                                    this.setState({ usernameToAdd: "" })
                                    const stateName = `TeamData${this.state.currentTeam}`
                                    adminListGroups(`profile.name sw "${this.state.currentTeam}"`, true).then(teams => {
                                        var s = {}
                                        s[stateName] = teams.find(o => { return o.GroupName == this.state.currentTeam })
                                        s[stateName + "-Administrator"] = teams.find(o => { return o.GroupName == this.state.currentTeam + "-Administrator" })
                                        s[stateName + "-Manager"] = teams.find(o => { return o.GroupName == this.state.currentTeam + "-Manager" })
                                        console.debug(s)
                                        this.setState(s)
                                    })
                                })
                                toast.promise(
                                    promise,
                                    {
                                        pending: `Adding team member to ${this.state.currentTeam}`,
                                        success: `${this.state.usernameToAdd} added to ${this.state.currentTeam}`,
                                        error: `Failed to add ${this.state.usernameToAdd} to ${this.state.currentTeam}`
                                    }
                                )
                            }
                            else {
                                console.error("No groupId supplied")
                            }

                        }}
                    >
                        <RiseLoader loading={this.state.working ? true : false} />
                        <PersonAddIcon /> Add
                    </Button>
                </Popover>

                <Dialog open={this.state.dialogOpen} onClose={this.handleDialogClose}>
                    <DialogTitle>Create a new team</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Create a new team, will be created with the Administrator and Manager group
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label={
                                "Team name" + (this.state.validTeamName ? "" : " (May only contain A-Z, a-z, 0-9 and space)!")
                            }
                            type="text"
                            error={this.state.validTeamName != true}

                            fullWidth
                            variant="standard"
                            onChange={(event) => {

                                var teamId = event.target.value
                                var teamName = event.target.value
                                var validTeamName = event.target.value.match(/^[A-Za-z0-9 ][A-Za-z0-9 ]{2,}$/) ? true : false
                                console.debug(validTeamName)



                                teamId = teamId.replace(RegExp('[^A-Za-z0-9]*', 'g'), "")
                                teamId = "Team-" + teamId.charAt(0).toUpperCase() + teamId.slice(1);
                                teamName = teamName.charAt(0).toUpperCase() + teamName.slice(1);
                                event.target.value = teamName

                                this.setState({ newTeamName: event.target.value, newTeamId: teamId, validTeamName: validTeamName })
                            }}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label={this.state.newTeamName ? null : "TeamId"}
                            type="text"

                            fullWidth
                            value={this.state.newTeamId}
                            disabled={true}
                            variant="standard"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogClose}>Cancel</Button>
                        <Button
                            disabled={this.state.validTeamName != true || this.state.workingAddTeam}
                            onClick={this.handleDialogOk}
                        >
                            <RiseLoader loading={this.state.workingAddTeam ? true : false} />
                            {

                                this.state.workingAddTeam ?
                                    ""
                                    : <Typography>Create team &nbsp; <GroupAddIcon /></Typography>
                            }

                        </Button>
                    </DialogActions>
                </Dialog>

                <MUIDataTable
                    data={this.state.groupArray}
                    sear
                    columns={this.datatable.columns}
                    options={this.datatable.options}
                    xs={12} sm={12} md={12} lg={12} xl={12}
                />

            </Paper>
        );
    }
}


class ManageAppsAndRoles extends Component {

    constructor(props) {
        super(props);
        this.MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: 48 * 4.5 + 8,
                    width: '30%',
                },
            },
        };

        this.state = {
            teams: [],
        }

        adminListGroups('profile.name sw "Team-"').then(teams => {
            this.setState({ 
                teams: teams.sort((a, b) => {
                    return b.GroupName < a.GroupName
                })
            })
        })


        adminListGroups().then(groups => {
            this.setState(
                {
                    groups: groups,
                    groupArray: groups.map(o => { return [o.GroupName, o.GroupType, o.Description, o.ManagedByGroups ? o.ManagedByGroups : []] })
                }
            )

        })



        this.datatable = {
            columns: [
                { name: "Name", options: { filter: true, sort: true } },
                { name: "Group type", options: { filter: true, sort: true } },
                { name: "Description", options: { filter: true, sort: true } },
                {
                    name: "ManagedBy",

                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <Grid spacing={1} container>
                                    {
                                        tableMeta.rowData[3].map(o => {
                                            return (
                                                <Grid item>
                                                    <Box>
                                                        {o}
                                                    </Box>
                                                </Grid>
                                            )


                                        })
                                    }
                                </Grid>
                            )
                        },
                    }
                },
            ],

            options: {
                rowsPerPage: 100,
                rowsPerPageOptions: [50, 100, 200, 400, 500, 1000],
                filter: true,
                download: false,
                print: false,
                filterType: 'dropdown',
                responsive: 'standard',
                selectableRows: 'none',
                expandableRowsOnClick: true,
                expandableRows: true,
                download: true,
                onDownload: (buildHead, buildBody, columns, data) => {
                    return "\uFEFF" + buildHead(columns) + buildBody(data);
                },
                downloadOptions: {
                    filename: "4SP-Export-ApplicationsAndRoles.csv"
                },

                renderExpandableRow: (rowData, rowMeta) => {
                    const colSpan = rowData.length + 1;


                    return (
                        <TableRow>
                            <TableCell colSpan={colSpan}>

                                <FormControl sx={{ m: 1, minWidth: '50%' }}>
                                    <Autocomplete
                                        multiple
                                        id={"select-teams-" + rowData[0]}
                                        options={
                                            this.state.groups.filter(o => { return o.GroupType == 'Team' }).map(o => {
                                                return o.GroupName

                                            })
                                        }
                                        value={
                                            this.state.groups.find(o => {
                                                return o.GroupName == rowData[0]
                                            }).ManagedByGroups
                                        }
                                        onChange={(event, value) => {
                                            console.debug("CHANGE")
                                            var groups = this.state.groups
                                            var ix = groups.findIndex(o => { return o.GroupName == rowData[0] })

                                            groups[ix].ManagedByGroups = value
                                            this.setState({ groups: groups })

                                        }}

                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Select managing teams"
                                                placeholder="Team..."
                                            />
                                        )}

                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Box
                                                    sx={{
                                                        flexGrow: 1,
                                                        '& span': {
                                                            color: '#8b949e'

                                                        },
                                                    }}
                                                >
                                                    {option}
                                                </Box>
                                            </li>

                                        )}
                                    />
                                </FormControl>
                                <Box width="100%"></Box>
                                <Button
                                    sx={{ marginLeft: 3 }}
                                    variant='contained'
                                    disabled={this.state['CurrentLoader'] == rowData[0]}

                                    color={
                                        this.state[rowData[0] + "Failed"] == undefined || this.state['CurrentLoader' + rowData[0]] == true ? "info" :
                                            this.state[rowData[0] + "Failed"] == true ? "error" :
                                                this.state[rowData[0] + "Failed"] == false ? "success" : null
                                    }

                                    onClick={(event) => {
                                        console.debug(event.target)
                                        var state = {}
                                        state['CurrentLoader' + rowData[0]] = true
                                        this.setState(state)


                                        adminUpdateGroupManagedBy(rowData[0], this.state.groups.find(o => { return o.GroupName == rowData[0] }).ManagedByGroups).then(o => {

                                            state[rowData[0] + "Failed"] = false

                                        }).catch(err => {

                                            state[rowData[0] + "Failed"] = true

                                        }).finally(o => {
                                            state['CurrentLoader' + rowData[0]] = false
                                            this.setState(state)
                                        })




                                    }}
                                >
                                    <SyncLoader loading={this.state['CurrentLoader' + rowData[0]] == true} />
                                    {
                                        this.state[rowData[0] + "Failed"] != undefined && this.state[rowData[0] + "Failed"] == false && this.state['CurrentLoader' + rowData[0]] != true ?
                                            <Done /> : null
                                    }
                                    {
                                        this.state[rowData[0] + "Failed"] != undefined && this.state[rowData[0] + "Failed"] == true ? <Error /> : null
                                    }
                                    {
                                        this.state[rowData[0] + "Failed"] == true ? "Update failed" :
                                            this.state['CurrentLoader' + rowData[0]] == true ?
                                                null : "Update"
                                    }
                                </Button>
                            </TableCell>
                        </TableRow>




                    )
                },
                onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded, rowData) =>
                    console.debug(curExpanded, allExpanded, rowsExpanded),
            }

        }

    }


    render() {


        return (

            <div style={{ display: 'table', tableLayout: 'fixed', width: '100%' }}>
                <PageLoader ready={this.state.groupArray ? true : false} />
                <MUIDataTable
                    data={this.state.groupArray}
                    columns={this.datatable.columns}
                    options={this.datatable.options}
                />

            </div>
        );
    }
}


class ManageUsers extends Component {

    constructor(props) {
        super(props);
        this.MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: 48 * 4.5 + 8,
                    width: '30%',
                },
            },
        };


        this.datatable = {
            columns: [
                { name: "Username", options: { filter: true, sort: true } },
                { name: "Status", options: { filter: true, sort: true } },
                { name: "Name", options: { filter: true, sort: true } },
                {
                    name: "Actions",
                    options: {
                        filter: true,//
                        customBodyRender: (value, tableMeta, updateValue) => {
                            console.debug(tableMeta)
                            var ids = [
                                tableMeta.rowData[0] + "resetPassword",
                                tableMeta.rowData[0] + "resetMfa",
                                tableMeta.rowData[0] + "disableUser",
                                tableMeta.rowData[0] + "enableUser"
                            ]
                            return (
                                <Grid spacing={2} container>
                                    <Grid item>
                                        <Button
                                            variant='contained'
                                            color='secondary'
                                            disabled={this.state[ids[0]]}
                                            id={ids[0]}
                                            onClick={(event) => {
                                                var st = {}
                                                st[event.target.id] = true
                                                this.setState(st)
                                                toast.promise(
                                                    adminResetUserPassword(tableMeta.rowData[0]).then(o => {
                                                        st[event.target.id] = false
                                                        this.setState(st)
                                                    }),
                                                    {
                                                        pending: `Reset password for ${tableMeta.rowData[0]}`,
                                                        success: `Reset password for ${tableMeta.rowData[0]}`,
                                                        error: `Error resetting password for ${tableMeta.rowData[0]}`,
                                                    }
                                                )

                                            }}
                                        >
                                            Reset password
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant='contained'
                                            color='warning'
                                            disabled={this.state[ids[1]]}
                                            id={ids[1]}
                                            onClick={(event) => {
                                                var st = {}
                                                st[event.target.id] = true
                                                this.setState(st)
                                                toast.promise(
                                                    adminResetUserMfa(tableMeta.rowData[0]).then(o => {
                                                        st[event.target.id] = false
                                                        this.setState(st)
                                                    }),

                                                    {
                                                        pending: `${tableMeta.rowData[0]} MFA reset`,
                                                        success: `${tableMeta.rowData[0]} MFA reset`,
                                                        error: `Error resetting MFA for ${tableMeta.rowData[0]}`,
                                                    }
                                                )
                                            }}
                                        >
                                            Reset MFA
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant='contained'
                                            color='error'
                                            id={ids[2]}
                                            disabled={this.state[ids[2]]}
                                            onClick={(event) => {
                                                var st = {}
                                                st[event.target.id] = true
                                                this.setState(st)
                                                toast.promise(
                                                    adminDisableUser(tableMeta.rowData[0]).then(o => {
                                                        st[event.target.id] = true
                                                        this.setState(st)
                                                    }),
                                                    {
                                                        pending: `Disabling ${tableMeta.rowData[0]}`,
                                                        success: `Disabling ${tableMeta.rowData[0]}`,
                                                        error: `Error disabling ${tableMeta.rowData[0]}`,
                                                    }

                                                )

                                            }}
                                        >
                                            Disable user
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant='contained'
                                            color='success'
                                            id={ids[3]}
                                            disabled={this.state[ids[3]]}
                                            onClick={(event) => {
                                                var st = {}
                                                st[event.target.id] = true
                                                this.setState(st)
                                                toast.promise(
                                                    adminDisableUser(tableMeta.rowData[0]).then(o => {
                                                        st[event.target.id] = true
                                                        this.setState(st)
                                                    }),
                                                    {
                                                        pending: `Disabling ${tableMeta.rowData[0]}`,
                                                        success: `Disabling ${tableMeta.rowData[0]}`,
                                                        error: `Error disabling ${tableMeta.rowData[0]}`,
                                                    }

                                                )

                                            }}
                                        >
                                            Enable user
                                        </Button>
                                    </Grid>
                                    <Grid xs={6} item>
                                    </Grid>

                                </Grid>
                            )
                        },
                    },

                }
            ],
            options: {
                rowsPerPage: 100,
                rowsPerPageOptions: [50, 100, 200, 400, 500, 1000],
                filter: true,
                download: false,
                print: false,
                filterType: 'dropdown',
                responsive: 'standard',
                selectableRows: 'none',
                expandableRowsOnClick: false,
                expandableRows: false,

                download: true,
                onDownload: (buildHead, buildBody, columns, data) => {
                    return "\uFEFF" + buildHead(columns) + buildBody(data);
                },
                downloadOptions: {
                    filename: "4SP-Export-Users.csv"
                },
            }
        }
        this.state = { users: [] }
        adminListUsers().then(users => {
            this.setState(
                {
                    users: users,
                    userArray: users.map(user => {
                        return [
                            user.Username,
                            user.Status,
                            user.DisplayName
                        ]
                    })
                }
            )
        })
    }



    render() {


        return (

            <div style={{ display: 'table', tableLayout: 'fixed', width: '100%' }}>
                <PageLoader ready={this.state.userArray ? true : false} />
                <MUIDataTable
                    data={this.state.userArray}
                    columns={this.datatable.columns}
                    options={this.datatable.options}
                />

            </div>
        );
    }
}


class UserReports extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reports: [],
            reportsArray: []
        }
        this.MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: 48 * 4.5 + 8,
                    width: '30%',
                },
            },
        };
        this.datatable = {
            columns: [
                { name: "Username", options: { filter: true, sort: true } },
                { name: "CreatedAt", options: { filter: true, sort: true } },
                { name: "RUM-ID", options: { filter: true, sort: true } },
                { name: "Description", options: { filter: true, sort: true } },
            ],
            options: {
                rowsPerPage: 100,
                rowsPerPageOptions: [50, 100, 200, 400, 500, 1000],
                filter: true,
                print: false,
                filterType: 'dropdown',
                responsive: 'standard',
                selectableRows: 'none',
                expandableRowsOnClick: false,
                expandableRows: false,

                download: true,
                onDownload: (buildHead, buildBody, columns, data) => {
                    return "\uFEFF" + buildHead(columns) + buildBody(data);
                },
                downloadOptions: {
                    filename: "4SP-Export-UserReports.csv"
                },
            }
        }

        listUserReports().then(reports => {
            reports.sort((a, b) => { return a.createdAt < b.createdAt })
            this.setState(
                {
                    reports: reports,
                    reportArray: reports.map(report => {
                        return [
                            report.Username,
                            report.createdAt,
                            report.RumSessionId,
                            report.Description
                        ]
                    })
                }
            )
        })
    }



    render() {


        return (

            <div style={{ display: 'table', tableLayout: 'fixed', width: '100%' }}>
                <PageLoader ready={this.state.reportArray ? true : false} />
                <MUIDataTable
                    data={this.state.reportArray}
                    columns={this.datatable.columns}
                    options={this.datatable.options}
                />

            </div>
        );
    }
}


class ManageAwsAccounts extends Component {

    constructor(props) {
        super(props);
        this.MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: 48 * 4.5 + 8,
                    width: '30%',
                },
            },
        };

        this.state = {
            awsAccounts: [],
            awsAccountsArray: [],
            groups: [],
            businessAreas: []
        }

        
        listBusinessAreas().then(ba => {
            this.setState({businessAreas: ba.sort(dynamicSort("DisplayName"))})
        })


        adminListGroups().then(groups => {
            this.setState(
                {
                    groups: groups.sort(dynamicSort("GroupName")),

                }
            )

        })

        listAwsAccounts().then(awsAccounts => {
            this.setState({
                awsAccounts: awsAccounts,
                awsAccountsArray: awsAccounts.map(o => {
                    return [o.id, o.DisplayName, o.Name, o.ManagingTeams, o.BusinessArea ? o.BusinessArea.DisplayName : "NOT SET", o.MainAccount ? o.MainAccount.id : undefined, o.Email]
                })
            })
        }).then(o => {
            this.setState({ ready: true })
        })



        this.datatable = {
            columns: [
                { name: "AccountId", options: { filter: true, sort: true } },
                { name: "Name", options: { filter: true, sort: true } },
                { name: "DisplayName", options: { filter: true, sort: true } },
                {
                    name: "ManagedBy",

                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <Grid spacing={1} container>
                                    {
                                        tableMeta.rowData[3].map(o => {
                                            return (
                                                <Grid item>
                                                    <Box>
                                                        {o}
                                                    </Box>
                                                </Grid>
                                            )


                                        })
                                    }
                                </Grid>
                            )
                        },
                    }
                },
                { name: "BusinessArea", options: { filter: true, sort: true } },
                {
                    name: "Main Account",

                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            var mainAccountDisplayName = ""
                            try {
                                mainAccountDisplayName = this.state.awsAccounts.find(o => { return o.id == tableMeta.rowData[5] }).DisplayName
                            }
                            catch {

                            }
                            return (
                                <Grid spacing={1} container>
                                    {mainAccountDisplayName} ({tableMeta.rowData[5]})
                                </Grid>
                            )
                        },
                    }
                },
                { name: "Email", options: { filter: true, sort: true, hidden: true } },
            ],

            options: {
                rowsPerPage: 100,
                rowsPerPageOptions: [50, 100, 200, 400, 500, 1000],
                filter: true,
                download: false,
                print: false,
                filterType: 'dropdown',
                responsive: 'standard',
                selectableRows: 'none',
                expandableRowsOnClick: true,
                expandableRows: true,
                download: true,
                onDownload: (buildHead, buildBody, columns, data) => {
                    return "\uFEFF" + buildHead(columns) + buildBody(data);
                },
                downloadOptions: {
                    filename: "4SP-Export-AwsAccounts.csv"
                },

                renderExpandableRow: (rowData, rowMeta) => {
                    const colSpan = rowData.length + 1;


                    return (
                        <TableRow >
                            <TableCell colSpan={colSpan}>

                                <FormControl sx={{ m: 1, minWidth: '50%' }}>
                                    <TextField label="DisplayName" defaultValue={rowData[2]}
                                        onChange={(event) => {
                                            console.debug("CHANGE")
                                            var awsAccounts = this.state.awsAccounts
                                            var ix = awsAccounts.findIndex(o => { return o.id == rowData[0] })
                                            var state = {}
                                            awsAccounts[ix].DisplayName = event.target.value

                                            state[rowData[0] + "Changed"] = true
                                            state['awsAccounts'] = awsAccounts

                                            this.setState(state)
                                            console.debug(event.target.value)
                                        }}
                                    />
                                                                <FormControl sx={{ m: 1, minWidth: '50%' }}>
                                    <Autocomplete
                                        id={"select-ba-" + rowData[0]}
                                        options={
                                            this.state.businessAreas.map(o => {
                                                return {label: o.DisplayName, id: o.id}

                                            })
                                        }
                                        value={
                                            this.state.awsAccounts.find(o => {
                                                return o.id == rowData[0]
                                            }).BusinessAreaId
                                        }
                                        onChange={(event, value) => {
                                            console.debug("CHANGE BA: ",value)
                                            var awsAccounts = this.state.awsAccounts
                                            var ix = awsAccounts.findIndex(o => { return o.id == rowData[0] })
                                            var state = {}

                                            awsAccounts[ix].BusinessAreaId = value.id
                                            state[rowData[0] + "Changed"] = true

                                            state['awsAccounts'] = awsAccounts
                                            this.setState(state)

                                        }}

                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Select business area that the account belongs to"
                                                placeholder="Select BA..."
                                            />
                                        )}

                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Box
                                                    sx={{
                                                        flexGrow: 1,
                                                        '& span': {
                                                            color: '#8b949e'

                                                        },
                                                    }}
                                                >
                                                    {option.label}
                                                </Box>
                                            </li>

                                        )}
                                    />
                                </FormControl>

                                    <Autocomplete
                                        multiple
                                        id={"select-teams-" + rowData[0]}
                                        options={
                                            this.state.groups.filter(o => { return o.GroupType == 'Team' }).map(o => {
                                                return o.GroupName

                                            })
                                        }
                                        value={
                                            this.state.awsAccounts.find(o => {
                                                return o.id == rowData[0]
                                            }).ManagingTeams
                                        }
                                        onChange={(event, value) => {
                                            console.debug("CHANGE")
                                            var awsAccounts = this.state.awsAccounts
                                            var ix = awsAccounts.findIndex(o => { return o.id == rowData[0] })
                                            var state = {}

                                            awsAccounts[ix].ManagingTeams = value
                                            state[rowData[0] + "Changed"] = true

                                            state['awsAccounts'] = awsAccounts
                                            this.setState(state)

                                        }}

                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Select managing teams"
                                                placeholder="Team..."
                                            />
                                        )}

                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Box
                                                    sx={{
                                                        flexGrow: 1,
                                                        '& span': {
                                                            color: '#8b949e'

                                                        },
                                                    }}
                                                >
                                                    {option}
                                                </Box>
                                            </li>

                                        )}
                                    />
                                </FormControl>
                                <Box width="100%"></Box>
                                <Button
                                    sx={{ marginLeft: 3 }}
                                    variant='contained'
                                    disabled={this.state['CurrentLoader'] == rowData[0] || this.state[rowData[0] + "Changed"] != true}
                                    color={
                                        this.state[rowData[0] + "Failed"] == undefined || this.state['CurrentLoader' + rowData[0]] == true ? "info" :
                                            this.state[rowData[0] + "Failed"] == true ? "error" :
                                                this.state[rowData[0] + "Failed"] == false ? "success" : null
                                    }

                                    onClick={(event) => {
                                        console.debug(event.target)
                                        var state = {}
                                        state['CurrentLoader' + rowData[0]] = true
                                        this.setState(state)
                                        var awsAccount = this.state.awsAccounts.find(o => { return o.id == rowData[0] })
                                        if (awsAccount) {
                                            toast.promise(
                                                updateAwsAccount(
                                                    {
                                                        id: awsAccount.id,
                                                        ManagingTeams: awsAccount.ManagingTeams,
                                                        BusinessAreaId: awsAccount.BusinessAreaId,
                                                        DisplayName: awsAccount.DisplayName
                                                    }
                                                ).then(o => {
                                                    state['CurrentLoader' + rowData[0]] = false
                                                    state[rowData[0] + "Failed"] = false
                                                    state['awsAccounts'] = this.state.awsAccounts
                                                    state['awsAccounts'][state['awsAccounts'].findIndex(f => {return f.id == o.id})] = o
                                                    state['awsAccountsArray'] = this.state.awsAccounts.map(o => {
                                                        return [o.id, o.DisplayName, o.Name, o.ManagingTeams, o.BusinessArea ? o.BusinessArea.DisplayName : "NOT SET", o.MainAccount.id, o.email]
                                                    })
                                                    this.setState(state)
                                                }).catch(err => {
                                                    console.error(err)
                                                    state['CurrentLoader' + rowData[0]] = false
                                                    state[rowData[0] + "Failed"] = true
                                                    this.setState(state)
                                                }),
                                                { pending: "Updating aws account", error: 'Error updating aws account', success: "AWS Account updated" }
                                            )
                                        }
                                        else { console.error("AwsAccount not found") }






                                    }}
                                >
                                    <SyncLoader loading={this.state['CurrentLoader' + rowData[0]] == true} />
                                    {
                                        this.state[rowData[0] + "Failed"] != undefined && this.state[rowData[0] + "Failed"] == false && this.state['CurrentLoader' + rowData[0]] != true ?
                                            <Done /> : null
                                    }
                                    {
                                        this.state[rowData[0] + "Failed"] != undefined && this.state[rowData[0] + "Failed"] == true ? <Error /> : null
                                    }
                                    {
                                        this.state[rowData[0] + "Failed"] == true ? "Update failed" :
                                            this.state['CurrentLoader' + rowData[0]] == true ?
                                                null : "Update"
                                    }
                                </Button>
                            </TableCell>
                        </TableRow>




                    )
                },
                onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded, rowData) =>
                    console.debug(curExpanded, allExpanded, rowsExpanded),
            }

        }

    }

    handleDialogClose(event) {
        this.setState({ dialogOpen: false })

    }
    handleDialogOk(event) {
        this.setState({ working: true })
        this.setState({ working: false })
    }


    render() {


        return (

            <div style={{ display: 'table', tableLayout: 'fixed', width: '100%' }}>
                <Dialog open={this.state.dialogOpen} onClose={this.handleDialogClose}>
                    <DialogTitle>Add a new AWS Account</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Will add a new AWS Account to the database
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label={
                                "Team name" + (this.state.validTeamName ? "" : " (May only contain A-Z, a-z, 0-9 and space)!")
                            }
                            type="text"
                            error={this.state.validTeamName != true}

                            fullWidth
                            variant="standard"
                            onChange={(event) => {

                                var teamId = event.target.value
                                var teamName = event.target.value
                                var validTeamName = event.target.value.match(/^[A-Za-z0-9 ][A-Za-z0-9 ]{2,}$/) ? true : false
                                console.debug(validTeamName)



                                teamId = teamId.replace(RegExp('[^A-Za-z0-9]*', 'g'), "")
                                teamId = "Team-" + teamId.charAt(0).toUpperCase() + teamId.slice(1);
                                teamName = teamName.charAt(0).toUpperCase() + teamName.slice(1);
                                event.target.value = teamName

                                this.setState({
                                    newTeamName: event.target.value, newTeamId: teamId, validTeamName: validTeamName
                                })

                            }}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label={this.state.newTeamName ? null : "TeamId"}
                            type="text"

                            fullWidth
                            value={this.state.newTeamId}
                            disabled={true}
                            variant="standard"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogClose}>Cancel</Button>
                        <Button
                            disabled={this.state.validTeamName != true || this.state.workingAddTeam}
                            onClick={this.handleDialogOk}
                        >
                            <RiseLoader loading={this.state.workingAddTeam ? true : false} />
                            {

                                this.state.workingAddTeam ?
                                    ""
                                    : <Typography>Create team &nbsp; <GroupAddIcon /></Typography>
                            }

                        </Button>
                    </DialogActions>
                </Dialog>
                <PageLoader ready={this.state.ready ? true : false} />
                <MUIDataTable
                    data={this.state.awsAccountsArray}
                    columns={this.datatable.columns}
                    options={this.datatable.options}
                />

            </div>
        );
    }
}

class ManageAnnouncements extends Component {

    constructor(props) {
        super(props);
        this.handleDialogOk = this.handleDialogOk.bind(this)
        this.handleDialogClose = this.handleDialogClose.bind(this)
        this.updateAnnouncements = this.updateAnnouncements.bind(this)
        this.MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: 48 * 4.5 + 8,
                    width: '30%',
                },
            },
        };

        this.state = {
            announcements: [],
            announcementsArray: [],
            groups: [],
            newAnnouncementTargetGroups: ['Everyone']
        }
        this.updateAnnouncements()
        adminListGroups().then(groups => {
            this.setState(
                {
                    groups: groups,

                }
            )

        })


        this.datatable = {
            columns: [
                { name: "Start", options: { filter: true, sort: true } },
                { name: "End", options: { filter: true, sort: true } },
                { name: "Title", options: { filter: true, sort: true } },
                {
                    name: "Target Groups",

                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <Grid spacing={1} container>
                                    {
                                        tableMeta.rowData[4].map(o => {
                                            return (
                                                <Grid item>
                                                    <Box>
                                                        {o}
                                                    </Box>
                                                </Grid>
                                            )


                                        })
                                    }
                                </Grid>
                            )
                        },
                    }
                },
                { name: "Message", options: { filter: true, sort: true } },
                { name: "Id", options: { filter: true, sort: true } }
            ],

            options: {
                rowsPerPage: 100,
                rowsPerPageOptions: [50, 100, 200, 400, 500, 1000],
                filter: true,
                download: false,
                print: false,
                filterType: 'dropdown',
                responsive: 'standard',
                selectableRows: 'none',
                expandableRowsOnClick: true,
                expandableRows: true,
                download: true,
                onDownload: (buildHead, buildBody, columns, data) => {
                    return "\uFEFF" + buildHead(columns) + buildBody(data);
                },
                downloadOptions: {
                    filename: "4SP-Export-Announcements.csv"
                },

                renderExpandableRow: (rowData, rowMeta) => {
                    const colSpan = rowData.length + 1;


                    return (
                        <TableRow >
                            <TableCell colSpan={colSpan}>


                                <Button
                                    sx={{ marginLeft: 3 }}
                                    variant='contained'


                                    onClick={(event) => {
                                        var promise = deleteAnnouncement(rowData[rowData.length - 1])
                                        promise.catch(err => console.debug(err))
                                        toast.promise(
                                            promise,
                                            {
                                                pending: "Deleting announcement",
                                                error: "Error deleting announcement",
                                                success: "Announcement deleted"
                                            }
                                        )







                                    }}
                                >
                                    Delete Announcement
                                </Button>
                            </TableCell>
                        </TableRow>




                    )
                },
                onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded, rowData) =>
                    console.debug(curExpanded, allExpanded, rowsExpanded),
            }

        }

    }
    updateAnnouncements() {
        return listAnnouncements().then(announcements => {
            this.setState({
                announcements: announcements,
                announcementsArray: announcements.map(item => {
                    console.debug(item)
                    return [
                        item.Start,
                        item.End,
                        item.Title,
                        item.Message,
                        item.AudienceGroups,
                        item.id,
                    ]
                }),
                ready: true
            })
        })
    }
    handleDialogClose(event) {
        this.setState({ dialogOpen: false })

    }
    handleDialogOk(event) {
        this.setState({ working: true })
        createAnnouncement({
            Title: this.state.newAnnouncementTitle,
            Message: this.state.newAnnouncementMessage,
            Start: this.state.newAnnouncementStart.toISOString(),
            End: this.state.newAnnouncementEnd.toISOString(),
            AudienceGroups: this.state.newAnnouncementTargetGroups,
            CreatedBy: "noone"

        }).then(o => {
            this.setState({ dialogOpen: false })
            this.setState({ working: false })
            this.updateAnnouncements()
        })

    }


    render() {


        return (

            <div style={{ display: 'table', tableLayout: 'fixed', width: '100%' }}>
                <Button
                    variant="contained"
                    color='success'
                    onClick={(event) => { this.setState({ dialogOpen: true }) }}
                >
                    <Add /> Create Announcement
                </Button>
                <Dialog open={this.state.dialogOpen} onClose={this.handleDialogClose}>
                    <DialogTitle>Add a new announcement</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Will add a new announcement visible towards selected groups. Selecting group "Everyone" will make it visible for everyone
                        </DialogContentText>





                        <Grid spacing={2} marginTop={2} container>
                            <Grid xs={2} item>
                                <FormLabel
                                    id='startDateLabel'
                                    error={this.state.newAnnouncementStart ? false : true}
                                >
                                    Start date:
                                </FormLabel>
                            </Grid>
                            <Grid item>
                                <ReactDatePicker
                                    renderInput={(props) => <TextField {...props} />}
                                    ariaLabelledBy='startDateLabel'
                                    dateFormat="yyyy-MM-dd"
                                    selected={this.state.newAnnouncementStart}
                                    onChange={(value) => {
                                        console.debug(value.toISOString())

                                        this.setState({ newAnnouncementStart: value })

                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid spacing={2} container>
                            <Grid xs={2} item>
                                <FormLabel
                                    id='endDateLabel'
                                    error={this.state.newAnnouncementEnd ? false : true}

                                >
                                    End date:
                                </FormLabel>

                            </Grid>
                            <Grid item>
                                <ReactDatePicker
                                    renderInput={(props) => <TextField {...props} />}
                                    selected={this.state.newAnnouncementEnd}
                                    dateFormat="yyyy-MM-dd"
                                    ariaLabelledBy='endDateLabel'
                                    onChange={(value) => {
                                        console.debug(value.toISOString())
                                        this.setState({ newAnnouncementEnd: value })

                                    }}
                                />
                            </Grid>
                        </Grid>







                        <TextField
                            autoFocus
                            margin="dense"
                            id="title"
                            error={this.newAnnouncementTitle ? false : true}
                            label="Title"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={this.state.newAnnouncementTitle}
                            onChange={(event) => {
                                this.setState({
                                    newAnnouncementTitle: event.target.value
                                })
                            }}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="message"
                            error={this.newAnnouncementMessage ? false : true}
                            label="Message"
                            type="text"
                            fullWidth
                            variant="standard"
                            multiline={true}
                            value={this.state.newAnnouncementMessage}
                            onChange={(event) => {
                                this.setState({
                                    newAnnouncementMessage: event.target.value
                                })
                            }}
                        />

                        <Autocomplete
                            multiple
                            id={"newAnnouncementTargetGroups"}
                            options={
                                this.state.groups.map(o => { return o.GroupName })
                            }
                            error={this.state.newAnnouncementTargetGroups.length ? false : true}
                            value={
                                this.state.newAnnouncementTargetGroups
                            }
                            onChange={(event, value) => {

                                this.setState({ newAnnouncementTargetGroups: value })

                            }}

                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Select target groups"
                                    placeholder="Groups..."
                                />
                            )}

                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Box
                                        sx={{
                                            flexGrow: 1,
                                            '& span': {
                                                color: '#8b949e'

                                            },
                                        }}
                                    >
                                        {option}
                                    </Box>
                                </li>

                            )}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogClose}>Cancel</Button>
                        <Button
                            onClick={this.handleDialogOk}
                        >
                            <RiseLoader loading={this.state.working ? true : false} />
                            {

                                this.state.workingAddAnnouncement ?
                                    ""
                                    : <Typography>Create</Typography>
                            }

                        </Button>
                    </DialogActions>
                </Dialog>
                <PageLoader ready={this.state.ready ? true : false} />
                <MUIDataTable
                    data={this.state.announcementsArray}
                    columns={this.datatable.columns}
                    options={this.datatable.options}
                />

            </div>
        );
    }
}



class ManageBusinessAreas extends Component {

    constructor(props) {
        super(props);
        this.handleDialogOk = this.handleDialogOk.bind(this)
        this.handleDialogClose = this.handleDialogClose.bind(this)
        this.update = this.update.bind(this)


        this.datatable = {
            columns: [
                { name: "Id", options: { filter: true, sort: true } },
                { name: "Business Area", options: { filter: true, sort: true } },
                {
                    name: "Teams",

                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {

                            return (
                                <Grid spacing={1} container>
                                    <Grid item>
                                        {
                                            tableMeta.rowData[2].map(t => {
                                                return (
                                                    <Box>
                                                        {t}
                                                    </Box>
                                                )
                                            })
                                        }

                                    </Grid>
                                </Grid>
                            )
                        },
                    }
                }
            ],
            options: {
                rowsPerPage: 100,
                rowsPerPageOptions: [50, 100, 200, 400, 500, 1000],
                filter: true,
                download: false,
                print: false,
                filterType: 'dropdown',
                responsive: 'standard',
                selectableRows: 'none',
                expandableRowsOnClick: false,
                expandableRows: false,
                download: true,
                onDownload: (buildHead, buildBody, columns, data) => {
                    return "\uFEFF" + buildHead(columns) + buildBody(data);
                },
                downloadOptions: {
                    filename: "4SP-Export-BusinessAreas.csv"
                }
            }

        }
        this.state = {
            baArray: [],
            bas: []
        }


    }
    componentDidMount() {
        this.update()
    }

    update() {
        listBusinessAreas().then(bas => {
            var baArr = []
            this.setState(
                { bas: [], baArray: [] }
            )
            bas.forEach(ba => {

                adminListGroups(`profile.businessArea eq "${ba.id}"`).then(o => {
                    var teamMap = o.map(x => { return x.GroupId })
                    this.setState(previousState => ({
                        baArray: [...previousState.baArray, [ba.id, ba.DisplayName, teamMap ? teamMap : ["None"]]],
                        bas: [...previousState.bas, ba],
                        ready: true
                    }));
                    console.debug(this.state)

                })

            })


        })
    }
    handleDialogClose(event) {
        this.setState({ dialogOpen: false })

    }
    handleDialogOk(event) {
        this.setState({ working: true })
        createBusinessArea(this.state.newBAId, this.state.newBAName).then(o => {
            console.debug(o)
            this.update()
        }).then(o => { this.setState({ working: false }) })

    }
    render() {

        if (!this.state.ready) {
            return (
                <PageLoader ready={this.state.ready} />
            )
        }
        return (

            <div style={{ display: 'table', tableLayout: 'fixed', width: '100%' }}>

                <Dialog open={this.state.dialogOpen} onClose={this.handleDialogClose}>
                    <DialogTitle>Create a new BA</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Will create a new business area that can be tied to teams
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="baId"
                            label={
                                "ID" + (this.state.validId ? "" : " (May only contain A-Z, a-z, 0-9)!")
                            }
                            type="text"
                            error={this.state.validId != true}

                            fullWidth
                            variant="standard"
                            onChange={(event) => {

                                var baId = event.target.value
                                var validId = event.target.value.match(/^[A-Za-z0-9]+$/) ? true : false
                                this.setState({ newBAId: baId, validId: validId })
                            }}
                        />
                        <TextField
                            margin="dense"
                            id="name"
                            label={
                                "Display Name" + (this.state.validName ? "" : " (May only contain A-Z, a-z, 0-9 and space)!")
                            }
                            type="text"
                            error={this.state.validName != true}

                            fullWidth
                            variant="standard"
                            onChange={(event) => {

                                var baDisplayName = event.target.value
                                var validName = event.target.value.match(/^[A-Za-z0-9 ][A-Za-z0-9 ]{2,}$/) ? true : false






                                this.setState({ newBAName: baDisplayName, validName: validName })
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Grid alignItems="center" container>
                            <Grid item>
                                <Button onClick={this.handleDialogClose}>Cancel</Button>
                            </Grid>
                            <Grid marginLeft={'auto'} item >
                                <Button
                                    disabled={this.state.validName && this.state.validId != true || this.state.working}
                                    onClick={this.handleDialogOk}
                                >
                                    <RiseLoader loading={this.state.working ? true : false} />
                                    {

                                        this.state.working ?
                                            ""
                                            : <Typography>&nbsp;&nbsp;&nbsp;<AddBusinessIcon /> Create Business Area</Typography>
                                    }

                                </Button>
                            </Grid>
                        </Grid>


                    </DialogActions>
                </Dialog>

                <Tooltip title="Add a new Business Area">
                    <Button
                        onClick={(event) => { this.setState({ dialogOpen: true }) }}
                        variant="contained" sx={{ minHeight: '100%', px: 2, py: 2 }} color="success"><AddBusinessIcon />&nbsp;Create Business Area</Button>
                </Tooltip>
                <MUIDataTable
                    data={this.state.baArray}
                    columns={this.datatable.columns}
                    options={this.datatable.options}
                />

            </div>
        );
    }
}

class ManageBillingEntities extends Component {

    constructor(props) {
        super(props);
        this.MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: 48 * 4.5 + 8,
                    width: '30%',
                },
            },
        };

        this.state = {
            billingEntities: [],
            billingEntitiesArray: [],
            businessAreas: [],
            teams: [],
            updatingRows: []
        }

        this.createBusinessAreaSelectDropDown = this.createBusinessAreaSelectDropDown.bind(this)
        this.createTeamSelectDropdown = this.createTeamSelectDropdown.bind(this)
        this.updateBillingEntities = this.updateBillingEntities.bind(this)
        
        this.datatable = {
            columns: [
                { name: "id", options: { filter: true, sort: true, display: false } },
                { name: "EntityType", options: { filter: true, sort: true } },
                { name: "EntityId", options: { filter: true, sort: true } },
                { name: "Description", options: { filter: true, sort: true } },
                { 
                    name: "Business Area",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            console.debug(tableMeta)
                            return (
                                this.createBusinessAreaSelectDropDown(tableMeta.rowData[0],value)
                            )
                        }
                    }
                },
                {
                    name: "Team",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            console.debug(tableMeta)
                            return (
                                this.createTeamSelectDropdown(tableMeta.rowData[0],value)
                            )
                        }
                    }
                }
                
            ],

            options: {
                rowsPerPage: 100,
                rowsPerPageOptions: [50, 100, 200, 400, 500, 1000],
                filter: true,
                download: false,
                print: false,
                filterType: 'dropdown',
                responsive: 'standard',
                selectableRows: 'none',
                expandableRowsOnClick: false,
                expandableRows: false,
                download: true,
                onDownload: (buildHead, buildBody, columns, data) => {
                    return "\uFEFF" + buildHead(columns) + buildBody(data);
                },
                downloadOptions: {
                    filename: "4SP-Export-BillingEntityMappings.csv"
                },

                renderExpandableRow: (rowData, rowMeta) => {
                    const colSpan = rowData.length + 1;


                    return (
                        <TableRow >
                            <TableCell colSpan={colSpan}>

                               
                            </TableCell>
                        </TableRow>




                    )
                },
                onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded, rowData) =>
                    console.debug(curExpanded, allExpanded, rowsExpanded),
            }

        }

    }

    componentDidMount(){
       this.updateBillingEntities()
        
    }
    
    updateBillingEntities(){
        var promises = []
        promises.push(
            adminListGroups('profile.name sw "Team-"').then(teams => 
                {
                    
                    teams = teams.filter(o => {return o.GroupId.match(/^Team-[^-]+$/)})
                    teams.sort((a, b) => {
                        return b.GroupId < a.GroupId
                    })
                    this.setState(
                        { 
                            teams: teams
                        }
                    )
                    return teams
                }
            )
        )
        promises.push(
            listBusinessAreas().then(ba => {
                ba.sort(dynamicSort("DisplayName"))
                this.setState({businessAreas: ba})
                return ba
            })
        )
        promises.push(
            listEntityToBusinessAreaMappings()
        )
        Promise.all(promises).then(
            out => {
                console.debug(out)
                var teams,bas,entityMappings

                teams = out[0]
                bas = out[1]
                entityMappings = out[2]
                this.setState({
                    teams: teams,
                    businessAreas:bas,
                    entityMappings: entityMappings
                })
                console.debug("BA:",bas)
                console.debug("Teams:",teams)
                console.debug("EntityMappings", entityMappings)
                    this.setState(
                        {
                            billingEntities: entityMappings,
                            billingEntitiesArray: entityMappings.map(o => {
                                return [
                                    o['id'],
                                    o['EntityType'],
                                    o['EntityId'],
                                    o['Description'],
                                    o['BusinessAreaId'],
                                    o['Team'],
                                    o['IsBillable'] ? "True" : "False"
                                ]
                            }),
                            ready: true
                        }
                    )
                    
                
            }
        )
        
    }
    createBusinessAreaSelectDropDown(id,current){
        var isLoading = false
        return (
            <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Business Area</InputLabel>
            <Select
                labelId={"ba-label-select-"+id}
                id={"ba-select-"+id}
                value={current}
                label="Business Area"
                onChange={(event) => {
                    var beObject = this.state.billingEntities.find(o => {return o['id'] == id})
                    toast.promise(
                        updateEntityToBusinessAreaMapping({
                            id: id,
                            EntityId: beObject['EntityId'],
                            EntityType: beObject["EntityType"],
                            Description: beObject["Description"],
                            BusinessAreaId: event.target.value,
                            Team: beObject["Team"]
                        }).then(o => this.updateBillingEntities()),
                        {
                            pending: "Updating BA for "+id,
                            error: "Failed setting BA for "+id,
                            success: "BA set to '"+event.target.value+"' for "+id
                        }
                    )
                    
                    
                }}
            >
                {
                    this.state.businessAreas.map(o => {
                        return (
                            <MenuItem value={o['id']}>{o['DisplayName']}</MenuItem>
                            
                        )
                    })
                }
                
                
            </Select>
            <BeatLoader loading={isLoading}/>
            </FormControl>
        )
    }
    createTeamSelectDropdown(id,current){
        return (
            <FormControl fullWidth>
            <InputLabel id={"team-select-label-"+id}>Team</InputLabel>
            <Select
                labelId={"team-select-label-"+id}
                id={"team-select-"+id}
                value={current}
                label="Team"
                onChange={(event) => {
                    var beObject = this.state.billingEntities.find(o => {return o['id'] == id})
                    this.setState({updatingRows: [...this.state.updatingRows, id]})
                    toast.promise(
                        updateEntityToBusinessAreaMapping({
                            id: id,
                            EntityId: beObject["EntityId"],
                            EntityType: beObject["EntityType"],
                            Description: beObject["Description"],
                            BusinessAreaId: beObject["BusinessAreaId"],
                            Team: event.target.value
                        }).then(o => this.updateBillingEntities()),
                        {
                            pending: "Updating Team for "+id,
                            error: "Failed setting Team for "+id,
                            success: "Team set to '"+event.target.value+"' for "+id
                        }
                    )
                    
                    
                    
                }}
            >
                {
                    this.state.teams.map(o => {
                        
                        return (
                            <MenuItem value={o['GroupId']}>{o['GroupId']}</MenuItem>
                        )
                    })
                }
                
                
            </Select>
            </FormControl>
        )
    }

    handleDialogClose(event) {
        this.setState({ dialogOpen: false })

    }
    handleDialogOk(event) {
        this.setState({ working: true })
        this.setState({ working: false })
    }


    render() {


        return (

            <div style={{ display: 'table', tableLayout: 'fixed', width: '100%' }}>
                
                <PageLoader ready={this.state.ready ? true : false} />
                <MUIDataTable
                    data={this.state.billingEntitiesArray}
                    columns={this.datatable.columns}
                    options={this.datatable.options}
                />

            </div>
        );
    }
}

class ManagePriceList extends Component {

    constructor(props) {
        super(props);
        this.MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: 48 * 4.5 + 8,
                    width: '30%',
                },
            },
        };

        this.state = {
            priceListEntries: [],
            priceListEntriesArray: [],
            businessAreas: [],
            appGroups: [],
            updatingRows: [],
            newPriceListEntry: {
                'ProductCode': "",
                'Description': "",
                'ApplicationGroup': undefined,
                'Unit': "User",
                'Cost': 0,
            }
        }

        this.createAppGroupSelectDropdown = this.createAppGroupSelectDropdown.bind(this)
        this.updatePriceListEntries = this.updatePriceListEntries.bind(this)
        this.handleDialogClose = this.handleDialogClose.bind(this)

        this.datatable = {
            columns: [
                { 
                    name: "",
                    options:{
                        filter: false,
                        sort: false,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            console.debug(tableMeta)
                            return (
                                <Button
                                    onClick={ (event) => {
                                        var entry = this.state.priceListEntries.find(o => { return o.id == value})
                                        this.setState(
                                            { 
                                                dialogOpen: true,
                                                dialogType: "edit",
                                                newPriceListEntry: {
                                                    id: entry.id,
                                                    ProductCode: entry.ProductCode,
                                                    Description: entry.Description,
                                                    ApplicationGroup: entry.ApplicationGroup,
                                                    Unit: entry.Unit,
                                                    Cost: entry.Cost
                                                }
                                            }
                                        )
                                    }}
                                >
                                    <Edit/>
                                </Button>
                            )
                        }
                    }
                },
                { name: "ProductCode", options: { filter: true, sort: true } },
                { name: "Description", options: { filter: true, sort: true } },
                { name: "ApplicationGroup", options: { filter: true, sort: true } },
                { name: "Unit", options: { filter: true, sort: true } },
                { 
                    name: "Cost",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            console.debug(tableMeta)
                            return (
                                `$ ${value}`
                            )
                        }
                    }
                }
            ],

            options: {
                rowsPerPage: 100,
                rowsPerPageOptions: [50, 100, 200, 400, 500, 1000],
                filter: true,
                download: false,
                print: false,
                filterType: 'dropdown',
                responsive: 'standard',
                selectableRows: 'none',
                expandableRowsOnClick: false,
                expandableRows: false,
                download: true,
                onDownload: (buildHead, buildBody, columns, data) => {
                    return "\uFEFF" + buildHead(columns) + buildBody(data);
                },
                downloadOptions: {
                    filename: "4SP-Export-PriceList.csv"
                },

                renderExpandableRow: (rowData, rowMeta) => {
                    const colSpan = rowData.length + 1;


                    return (
                        <TableRow >
                            <TableCell colSpan={colSpan}>

                               
                            </TableCell>
                        </TableRow>




                    )
                },
                onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded, rowData) =>
                    console.debug(curExpanded, allExpanded, rowsExpanded),
            }

        }
    }
    
    createAppGroupSelectDropdown(){
        return (
            <Select
                labelId={"input-select-app-label"}
                id={"app-group-select-"}
                value={this.state['newPriceListEntry']['ApplicationGroup']}
                label="Application Group"
                variant='standard'
                fullWidth
                onChange={(event) => {
                    
                    var entry = this.state.newPriceListEntry
                    entry['ApplicationGroup'] = event.target.value
                                
                    this.setState({ newPriceListEntry: entry })
                    
                }}
            >
                <MenuItem value={undefined}>None</MenuItem>
                {
                    this.state.appGroups.map(o => {
                        
                        return (
                            <MenuItem value={o['GroupId']}>{o['GroupId']}</MenuItem>
                        )
                    })
                }
                
                
            </Select>
        )
    }

    updatePriceListEntries(){
        Promise.all(
            [
                adminListGroups('profile.name sw "App-"').then(appGroups => 
                    {
                        
                        appGroups = appGroups.filter(o => {return o.GroupId.match(/^App-[^-]+$/)})
                        appGroups.sort((a, b) => {
                            return b.GroupId < a.GroupId
                        })
                        this.setState(
                            { 
                                appGroups: appGroups
                            }
                        )
                        return appGroups
                    }
                ),
                listPricingLists().then(pl => {
                    this.setState(
                        {
                            priceListEntries: pl,
                            priceListEntriesArray: pl.map(o => {
                                return [
                                    o['id'],
                                    o['ProductCode'],
                                    o['Description'],
                                    o['ApplicationGroup'],
                                    o['Unit'],
                                    o['Cost'],
                                ]
                            })
                        }
                    )
                })
            ]
        ).then(this.setState({ready: true}))
    }

    componentDidMount(){
       
        this.updatePriceListEntries()
    }

    

    handleDialogClose(event) {
        this.setState({ dialogOpen: false })

    }


    render() {


        return (

            <div style={{ display: 'table', tableLayout: 'fixed', width: '100%' }}>
                <Dialog open={this.state.dialogOpen} onClose={this.handleDialogClose}>
                    <DialogTitle>
                        {
                            this.state.dialogType == "edit" ? 
                            "Edit" : "Create"
                        } price list entry
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {
                                this.state.dialogType == "edit" ? 
                                    "Edit entry" : "Create entry"
                            }
                        </DialogContentText>
                        
                        <TextField
                            autoFocus
                            margin="dense"
                            id="input-product-code"
                            label={
                                "Product Code"
                            }
                            type="text"

                            fullWidth
                            value={this.state.newPriceListEntry.ProductCode}
                            variant="standard"
                            onChange={(event) => {
                                var entry = this.state.newPriceListEntry
                                entry['ProductCode'] = event.target.value
                                
                                this.setState({ newPriceListEntry: entry })
                            }}
                        />
                        
                        <TextField
                            margin="dense"
                            id="input-description"
                            label={
                                "Description"
                            }
                            type="text"

                            fullWidth
                            variant="standard"
                            value={this.state.newPriceListEntry.Description}
                            onChange={(event) => {
                                var entry = this.state.newPriceListEntry
                                entry['Description'] = event.target.value
                                
                                this.setState({ newPriceListEntry: entry })
                            }}
                        />
                        <InputLabel sx={{mt: 1}} id={"input-select-app-label"}>Application Group</InputLabel>
                        {
                            this.createAppGroupSelectDropdown()
                        }
                        
                        <InputLabel sx={{mt: 1}} id={"input-select-unit-label"}>Unit</InputLabel>
                        <Select
                            labelId={"app-group-select-unit-label"}
                            id={"unit-select"}
                            fullWidth
                            value={this.state['newPriceListEntry']['Unit']}
                            label="Unit"
                            variant='standard'
                            onChange={(event) => {
                                
                                var entry = this.state.newPriceListEntry
                                entry['Unit'] = event.target.value
                                            
                                this.setState({ newPriceListEntry: entry })
                                
                            }}
                        >
                            <MenuItem value={"Users"}>User</MenuItem>
                            <MenuItem value={"BusinessAreas"}>BA</MenuItem>
                            <MenuItem value={"Teams"}>Team</MenuItem>
                            <MenuItem value={"Lines"}>Line</MenuItem>
                            
                        </Select>
                        
                        
                        <InputLabel sx={{mt: 1}} id={"input-cost-label"}>Price</InputLabel>
                        <OutlinedInput
                            id="input-cost"
                            fullWidth
                            labelId={"input-cost-label"}
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            error={this.state.inputCostError ? true : false}
                            label={"Cost"+this.state.inputCostError ? "- "+this.state.inputCostError : ""}
                            value={this.state.newPriceListEntry.Cost}
                            onChange={ (event) => {
                                var entry = this.state.newPriceListEntry
                                var value = event.target.value.replace(",",".")
                                if(!value.match(/^\d+(\.\d+)?$/)){
                                    this.setState({inputCostError: "Value must be a number"})
                                    entry['Cost'] = undefined
                                    
                                }
                                else{
                                    this.setState({inputCostError: false})
                                    entry['Cost'] = Number(value)
                                }
                                this.setState({ newPriceListEntry: entry })
                            }}
                        />
                        

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogClose}>Cancel</Button>
                        {
                            this.state.dialogType == 'edit' ?
                                <Button disabled={this.state.working} onClick={
                                    (event) => {
                                        this.setState({ working: true })
                                        deletePricingList(this.state.newPriceListEntry.id).then( deletedEntry => {
                                            var pl = this.state.priceListEntries
                                                pl = pl.filter(o => { return o.id != deletedEntry.id})
                                                this.setState(
                                                    {
                                                        priceListEntries: pl,
                                                        priceListEntriesArray: pl.map(o => {
                                                            return [
                                                                o['id'],
                                                                o['ProductCode'],
                                                                o['Description'],
                                                                o['ApplicationGroup'],
                                                                o['Unit'],
                                                                o['Cost'],
                                                            ]
                                                        }),
                                                        working: false,
                                                        dialogOpen: false
                                                    }
                                                )
                                            
                                        })
                                    }
                                }>
                                    <Delete/>
                                </Button> : null
                        }
                        <Button
                            disabled={
                                this.state.working ||
                                this.state.newPriceListEntry.ProductCode == undefined ||
                                this.state.newPriceListEntry.Description == undefined ||
                                this.state.newPriceListEntry.Unit == undefined ||
                                this.state.newPriceListEntry.Cost == undefined
                            }
                            onClick={(event) => {
                                this.setState({ working: true })
                                if(this.state.dialogType == 'create'){
                                    createPricingList(this.state.newPriceListEntry).then(
                                        newEntry => {
                                            this.setState(
                                                {
                                                    priceListEntries: [...this.state.priceListEntries, newEntry],
                                                    priceListEntriesArray: [...this.state.priceListEntriesArray, [
                                                        newEntry['id'],
                                                        newEntry['ProductCode'],
                                                        newEntry['Description'],
                                                        newEntry['ApplicationGroup'],
                                                        newEntry['Unit'],
                                                        newEntry['Cost'],
                                                    ]]
                                                }
                                            )
                                        }   
                                    ).then(this.setState({ working: false, dialogOpen: false }))
                                }
                                if(this.state.dialogType == 'edit'){
                                    updatePricingList(this.state.newPriceListEntry)
                                    .then(
                                        updatedEntry => {
                                            var pl = this.state.priceListEntries
                                            pl = pl.filter(o => { return o.id != updatedEntry.id})
                                            pl.push(updatedEntry)
                                            this.setState(
                                                {
                                                    priceListEntries: pl,
                                                    priceListEntriesArray: pl.map(o => {
                                                        return [
                                                            o['id'],
                                                            o['ProductCode'],
                                                            o['Description'],
                                                            o['ApplicationGroup'],
                                                            o['Unit'],
                                                            o['Cost'],
                                                        ]
                                                    })
                                                }
                                            )
                                        }
                                    )
                                    .then(this.setState({ working: false, dialogOpen: false }))
                                }
                                
                            }}
                        >
                            <RiseLoader loading={this.state.working ? true : false} />
                            {

                                this.state.working ?
                                    ""
                                    : this.state.dialogType == "edit" ? <Edit/> : <Add/>
                            }

                        </Button>
                       
                    </DialogActions>
                </Dialog>

                <PageLoader ready={this.state.ready ? true : false} />
                <Tooltip title="Add a new entry">
                    <Button
                        onClick={(event) => { 
                            this.setState(
                                { 
                                    dialogOpen: true,
                                    dialogType: "create",
                                    newPriceListEntry: {
                                        'ProductCode': "",
                                        'Description': "",
                                        'ApplicationGroup': undefined,
                                        'Unit': "User",
                                        'Cost': undefined,
                                    }
                                }
                            ) 
                        }}
                        variant="contained" sx={{ minHeight: '100%', px: 2, py: 2 }} color="success">
                            <Add />&nbsp;Add
                        </Button>
                </Tooltip>
                <MUIDataTable
                    data={this.state.priceListEntriesArray}
                    columns={this.datatable.columns}
                    options={this.datatable.options}
                />

            </div>
        );
    }
}

export { ManagePriceList, ManageBillingEntities, ManageAnnouncements, ManageAppsAndRoles, ManageAwsAccounts, ManageBusinessAreas, ManageTeams, ManageUsers, UserReports };
